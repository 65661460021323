import React, { FC, PropsWithChildren } from 'react';
import {
  CoinXbe, DefaultBlock, H5, Option, DefaultBlockProps, CoinSet, CoinSetProps, BlockWrapperProps,
} from '@workstream/shared';
import cx from 'classnames';
import styles from './styles.module.scss';

export type BlockWithListProps = {
  className?: string,
  classNameHeader?: string,
  classNameList?: string,
  classNameOption?: string,
  classNameLabel?: string,
  classNameValue?: string,
  title?: string,
  tag?: BlockWrapperProps['tag'],
  theme?: DefaultBlockProps['theme'],
  icons?: CoinSetProps['icons'],
  list: {
    label: string,
    value: string,
  }[],
};

const BlockWithList: FC<PropsWithChildren<BlockWithListProps>> = ({
  className,
  classNameHeader,
  classNameList,
  theme = 'lightBlue',
  title,
  tag = 'section',
  children,
  list,
  icons = [{ name: 'coin xbe', img: CoinXbe }],
  classNameLabel,
  classNameValue,
  classNameOption,
}) => (
  <DefaultBlock
    withContainer={false}
    theme={theme}
    className={cx(styles.wrapper, className)}
    isBorder={false}
    tag={tag}
  >
    {(icons?.length && title) && (
      <header className={cx(styles.header, classNameHeader)}>
        {icons?.length && (
          <CoinSet
            icons={icons}
            className={styles.icons}
            size={24}
          />
        )}
        <H5 className={styles.title}>{title}</H5>
      </header>
    )}

    <div className={classNameList}>
      {list.map((item) => (
        <Option
          labelClassName={cx(classNameLabel, styles.optionLabel)}
          key={item.label + item.value}
          label={item.label}
          value={item.value}
          className={cx(styles.option, classNameOption)}
          labelColor="secondary"
          valueClassName={cx(styles.optionValue, classNameValue)}
        />
      ))}
    </div>

    {children}
  </DefaultBlock>
);

export default BlockWithList;

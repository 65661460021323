import {
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  minterSetStatusAction,
  minterGetStatsAction,
  minterSetStateAction,
} from 'store/minter/actions';
import { MinterActionTypes } from 'store/minter/actionTypes';
import {
  getContract,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import { votingStakingRewardsAbi } from 'assets/abi';
import { VOTING_STAKING_REWARDS_CONTRACT_ADDRESS } from 'appConstants';
import { Contract } from 'web3-eth-contract';
import { selectAddress } from 'store/utils';
import BigNumber from 'bignumber.js';

function* getEarned(contract: Contract, userAddress: string) {
  const earned: string = yield contract
    .methods
    .earned(userAddress)
    .call();

  return web3.utils.fromWei(earned);
}

type BondedRewardsLockRes = {
  amount: string,
};

function* getBondedRewardsLock(contract: Contract, userAddress: string) {
  const bondedRewardsLock: BondedRewardsLockRes = yield contract
    .methods
    .bondedRewardLocks(userAddress)
    .call();

  return web3.utils.fromWei(bondedRewardsLock.amount);
}

function* minterGetStatsSaga({ type }: ReturnType<typeof minterGetStatsAction>) {
  try {
    yield put(minterSetStatusAction({
      type,
      status: RequestStatus.REQUEST,
    }));
    const address: string = yield selectAddress();
    const votingStakingRewardsContract = getContract(
      votingStakingRewardsAbi,
      VOTING_STAKING_REWARDS_CONTRACT_ADDRESS,
    );

    const earned: string = yield getEarned(
      votingStakingRewardsContract,
      address,
    );
    const bondedRewardsLock: string = yield getBondedRewardsLock(
      votingStakingRewardsContract,
      address,
    );
    const totalXBEMintedBN = new BigNumber(earned).plus(bondedRewardsLock);

    yield put(minterSetStateAction({
      totalXBEMinted: totalXBEMintedBN.toFixed(),
      allClaimableXBE: earned,
      isAccessToClaim: totalXBEMintedBN.isGreaterThan(0),
    }));
    yield put(minterSetStatusAction({
      type,
      status: RequestStatus.SUCCESS,
    }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(minterSetStatusAction({
      type,
      status: RequestStatus.ERROR,
    }));
  }
}

export default function* listener() {
  yield takeEvery(
    MinterActionTypes.GET_STATS,
    minterGetStatsSaga,
  );
}

import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
const Preloader = ({ isLoading, className, children, }) => (isLoading
    ? (React.createElement("div", { className: cx(styles.preloaderWrap, className) },
        React.createElement("div", { className: styles.preloader },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null))))
    : React.createElement(React.Fragment, null, children));
export default Preloader;

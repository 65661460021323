import { put, takeLeading } from 'redux-saga/effects';
import { CvxCrvStakingActionTypes } from 'store/cvxCrvStaking/actionTypes';
import {
  getContract, setNotification,
} from '@workstream/shared';
import { cvxCrvAbi } from 'assets/abi';
import {
  CVX_CRV_VAULT_CONTRACT_ADDRESS,
  CVX_CRV_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { cvxCrvStakingSetStateAction } from 'store/cvxCrvStaking/actions';
import { selectAddress } from 'store/utils';

function* cvxCrvStakingGetAllowanceSaga() {
  try {
    const address: string = yield selectAddress();
    const contract = getContract(cvxCrvAbi, CVX_CRV_CONTRACT_ADDRESS);
    const allowance: string = yield contract
      .methods
      .allowance(address, CVX_CRV_VAULT_CONTRACT_ADDRESS)
      .call();

    yield put(cvxCrvStakingSetStateAction({ allowance }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
  }
}

export function* getAllowanceSaga() {
  yield takeLeading(
    CvxCrvStakingActionTypes.GET_ALLOWANCE,
    cvxCrvStakingGetAllowanceSaga,
  );
}

import { RequestStatus } from '@workstream/shared';
import { CrvConvertingActionTypes } from 'store/crvConverting/actionTypes';
import { CrvConvertingState } from 'store/crvConverting/types';

export const crvConvertingSetStateAction = (payload: Partial<CrvConvertingState>) => ({
  type: CrvConvertingActionTypes.SET_STATE,
  payload,
});

export const crvConvertingSetStatusAction = (
  payload: { type: CrvConvertingActionTypes, status: RequestStatus },
) => ({
  type: CrvConvertingActionTypes.SET_STATUS,
  payload,
});

export const crvConvertingGetBlockInfoAction = () => ({
  type: CrvConvertingActionTypes.GET_BLOCK_INFO,
});

export const crvConvertingGetAllowanceAction = () => ({
  type: CrvConvertingActionTypes.GET_ALLOWANCE,
});

export const crvConvertingApproveAction = (payload: string) => ({
  type: CrvConvertingActionTypes.APPROVE,
  payload,
});

export const crvConvertingConvertAndStakeAction = (payload: string) => ({
  type: CrvConvertingActionTypes.CONVERT_AND_STAKE,
  payload,
});

export const crvConvertingConvertAction = (payload: string) => ({
  type: CrvConvertingActionTypes.CONVERT,
  payload,
});

export const crvConvertingWithdrawAction = (payload: string) => ({
  type: CrvConvertingActionTypes.WITHDRAW,
  payload,
});

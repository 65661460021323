import { MetamaskStatus } from '@workstream/shared';
import { select, take } from 'redux-saga/effects';
import { web3Selector } from 'store/selectors';
import { Web3ActionTypes } from 'store/web3/actionTypes';

export default function* () {
  let metamaskStatus: MetamaskStatus = yield select(web3Selector.getProp('metamaskStatus'));
  while (metamaskStatus !== MetamaskStatus.ADDRESS_SELECTED) {
    yield take(Web3ActionTypes.SET_STATE);
    metamaskStatus = yield select(web3Selector.getProp('metamaskStatus'));
  }

  const address: string = yield select(web3Selector.getProp('address'));
  return address;
}

import { SetStatusPayload } from 'store/types';
import { MinterState } from './types';
import { MinterActionTypes } from './actionTypes';

export const minterSetStateAction = (payload: Partial<MinterState>) => ({
  type: MinterActionTypes.SET_STATE,
  payload,
});

export const minterSetStatusAction = (
  payload: SetStatusPayload<MinterActionTypes>,
) => ({
  type: MinterActionTypes.SET_STATUS,
  payload,
});

export const minterGetStatsAction = () => ({
  type: MinterActionTypes.GET_STATS,
});

export const minterClaimAction = () => ({
  type: MinterActionTypes.CLAIM,
});

import { cvxStakingApproveAction, cvxStakingGetAllowanceAction, cvxStakingSetStateAction } from 'store/cvxStaking/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { CvxStakingActionTypes } from 'store/cvxStaking/actionTypes';
import {
  getContract, MAX_UINT_256, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { cvxAbi } from 'assets/abi';
import {
  CVX_VAULT_CONTRACT_ADDRESS,
  CVX_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* cvxStakingApproveSaga(
  { payload: value }: ReturnType<typeof cvxStakingApproveAction>,
) {
  try {
    yield put(cvxStakingSetStateAction({ stakingStatus: WriteContractStatus.APPROVING }));
    const address: string = yield selectAddress();
    const contract = getContract(cvxAbi, CVX_CONTRACT_ADDRESS);
    const approveCount = Number(value) > 0 ? MAX_UINT_256.toFixed() : web3.utils.toWei('0');

    yield contract
      .methods
      .approve(CVX_VAULT_CONTRACT_ADDRESS, approveCount)
      .send({
        from: address,
      });

    yield put(cvxStakingGetAllowanceAction());
    setNotification({ type: 'success', message: 'Approving!' });
    yield put(cvxStakingSetStateAction({ stakingStatus: WriteContractStatus.APPROVED }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(cvxStakingSetStateAction({ stakingStatus: WriteContractStatus.APPROVED_ERROR }));
  }
}

export function* approveSaga() {
  yield takeLeading(
    CvxStakingActionTypes.APPROVE,
    cvxStakingApproveSaga,
  );
}

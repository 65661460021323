/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  FC, useState, useEffect, useCallback,
} from 'react';
import cx from 'classnames';
import {
  DefaultBlock, IconArrowDropBlack, SvgIcon, Text,
} from '@workstream/shared';
import { useDispatch } from 'react-redux';
import { statisticsGetInfoAction } from 'store/statistics/actions';
import { StatisticsItem, StatisticsTokenItem } from 'components';
import { useShallowSelector, statisticsSelector } from 'store/selectors';
import { IconConvexLogo, IconCurveLogo } from 'assets/img';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const Statistic: FC<Props> = ({
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(statisticsGetInfoAction());
  }, [dispatch]);

  const {
    totalClaimable,
    totalDeposits,
    totalCombinedApy, // Average Hive APR
    totalCrvEarned,
    totalCvxEarned,
    // delete temp
    // totalCvxCrvEarned,
    totalXbeEarned,
    stakedAssetsYield,
    dailyYield,
    isExistStatistics,
  } = useShallowSelector(statisticsSelector.getState);

  const handlerOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  if (!isExistStatistics) {
    return null;
  }

  return (
    <div
      className={className}
    >
      <DefaultBlock
        tag="div"
        theme="grey"
        className={cx(
          styles.topBlock, isOpen && styles.open,
        )}
      >
        <div className={styles.statisticsItems}>
          <StatisticsItem
            className={styles.statisticsItem}
            type="claimable"
            value={`$${totalClaimable}`}
          />
          <StatisticsItem
            className={styles.statisticsItem}
            type="deposits"
            value={`$${totalDeposits}`}
          />
          <StatisticsItem
            className={styles.statisticsItem}
            type="apy"
            value={`${totalCombinedApy}%`}
          />
        </div>

        <div className={styles.buttons}>
          <a
            // href="https://fiat.xbe.finance/"
            // href=" "
            // target="_blank"
            rel="noopener noreferrer"
            className={cx(styles.button, styles.disabled)}
          >
            Fund with FIAT
          </a>
          <a
            href="https://docs.xbe.finance/"
            target="_blank"
            rel="noopener noreferrer"
            className={cx(styles.button, styles.buttonDeposit)}
          >
            How does this work?
          </a>
        </div>

        <div className={styles.poweredBy}>
          <Text
            className={styles.poweredByText}
            color="secondary"
          >
            Powered by: Decentralized Finance
          </Text>
          <SvgIcon icon={IconConvexLogo} className={styles.logoConvex} />
          <i className={styles.slash} />
          <SvgIcon icon={IconCurveLogo} className={styles.logoCurve} />
        </div>

        <button
          type="button"
          className={cx(styles.buttonDrop, isOpen && styles.open)}
          onClick={handlerOpen}
        >
          <SvgIcon icon={IconArrowDropBlack} />
        </button>
      </DefaultBlock>

      {isOpen && (
        <DefaultBlock
          className={styles.bottomBlock}
          tag="div"
        >
          <StatisticsTokenItem
            type="crv"
            value={`$${totalCrvEarned}`}
            className={styles.statisticsTokenItem}
          />
          <StatisticsTokenItem
            type="cvx"
            value={`$${totalCvxEarned}`}
            className={styles.statisticsTokenItem}
          />
          {/* delete temp */}
          {/* <StatisticsTokenItem
            type="cvxCRV"
            value={`$${totalCvxCrvEarned}`}
            className={styles.statisticsTokenItem}
          /> */}
          <StatisticsTokenItem
            type="xbe"
            value={`$${totalXbeEarned}`}
            className={styles.statisticsTokenItem}
          />
          <StatisticsTokenItem
            type="stakedAssets"
            value={`$${stakedAssetsYield}`}
            className={styles.statisticsTokenItem}
          />
          <StatisticsTokenItem
            type="dailyYield"
            value={`$${dailyYield}`}
            className={styles.statisticsTokenItem}
          />
        </DefaultBlock>
      )}
    </div>
  );
};

export default Statistic;

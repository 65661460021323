import React from 'react';
import { SvgIcon } from "./..";
import { CoinXbe, PlatformCurve, PlatformSushiswap, PlatformUniswap, } from "../../assets/img";
import cx from 'classnames';
import styles from './styles.module.scss';
const icons = {
    uniswap: PlatformUniswap,
    xbe: CoinXbe,
    curve: PlatformCurve,
    sushiswap: PlatformSushiswap,
};
const PlatformHeaderBlock = ({ backgroundColor, icon, leftText, rightText, sizeText = 'big', centerText, classLeftText, classRightText, }) => (React.createElement("div", { className: cx(styles.wrapper, styles[backgroundColor], centerText && styles.center) }, icon ? (React.createElement(React.Fragment, null,
    React.createElement("p", { className: cx(styles.leftText, styles[sizeText], classLeftText) }, leftText),
    React.createElement(SvgIcon, { icon: icons[icon], className: cx(styles.icon, styles[icon]) }),
    React.createElement("p", { className: cx(styles.rightText, styles[sizeText], classRightText) }, rightText))) : (React.createElement("p", { className: cx(styles.centerText, styles[sizeText]) }, centerText))));
export default PlatformHeaderBlock;

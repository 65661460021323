import { put, takeLeading } from 'redux-saga/effects';
import {
  getContract, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';
import { StakeCurveLpActionTypes } from 'store/stakeCurveLp/actionTypes';
import {
  stakeCurveLpDepositAction,
  stakeCurveLpGetAllowanceAction,
  stakeCurveLpGetBalanceAction, stakeCurveLpGetBalanceVaultAction,
  stakeCurveLpSetSubmitStatusAction,
} from 'store/stakeCurveLp/actions';
import {
  stakeCurveLpVaultTokensAbi,
  stakeCurveLpVaultTokensAddress,
} from 'store/stakeCurveLp/constants';

function* stakeCurveLpDepositSaga(
  { payload: { token, value } }: ReturnType<typeof stakeCurveLpDepositAction>,
) {
  try {
    yield put(stakeCurveLpSetSubmitStatusAction({ [token]: WriteContractStatus.WRITING }));
    const address: string = yield selectAddress();

    const contract = getContract(
      stakeCurveLpVaultTokensAbi[token],
      stakeCurveLpVaultTokensAddress[token],
    );

    yield contract
      .methods
      .deposit(web3.utils.toWei(value))
      .send({
        from: address,
      });

    yield put(stakeCurveLpGetAllowanceAction(token));
    yield put(stakeCurveLpGetBalanceAction(token));
    yield put(stakeCurveLpGetBalanceVaultAction(token));
    yield put(stakeCurveLpSetSubmitStatusAction({ [token]: WriteContractStatus.SUCCESS }));

    setNotification({ type: 'success', message: 'Deposit success!' });
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(stakeCurveLpSetSubmitStatusAction({ [token]: WriteContractStatus.ERROR }));
  }
}

export function* depositSaga() {
  yield takeLeading(
    StakeCurveLpActionTypes.DEPOSIT,
    stakeCurveLpDepositSaga,
  );
}

import { fork } from 'redux-saga/effects';
import { getStakeSaga } from './getStake';
import { claimSaga } from './claim';
import { claimSushiSaga } from './claimSushi';
import { claimCvxCrvSaga } from './claimCvxCrv';
import { claimCvxSaga } from './claimCvx';
import { claimReferralSaga } from './claimReferral';

export default [
  fork(getStakeSaga),
  fork(claimSaga),
  fork(claimSushiSaga),
  fork(claimCvxCrvSaga),
  fork(claimCvxSaga),
  fork(claimReferralSaga),
];

export enum BoostActionTypes {
  SET_STATE = 'BOOST.SET_STATE',

  SET_STATUS = 'BOOST.SET_STATUS',

  GET_BLOCK_LOCKUP_INFO = 'BOOST.GET_BLOCK_LOCKUP_INFO',

  GET_BLOCK_DEPOSIT_INFO = 'BOOST.GET_BLOCK_DEPOSIT_INFO',

  GET_STARTING_VOTING_POWER = 'BOOST.GET_STARTING_VOTING_POWER',

  APPROVE = 'BOOST.APPROVE',

  DEPOSIT = 'BOOST.DEPOSIT',

  SUBMIT_LOCK_TIME = 'BOOST.SUBMIT_LOCK_TIME',
}

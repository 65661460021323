import React, {
  FC, PropsWithChildren, useMemo, useState,
} from 'react';
import cx from 'classnames';
import {
  CoinCvx,
  CoinEurt, CoinIronbank, CoinStEth, CoinSushi, CoinFrax, CoinXbe,
  IconArrowDropBlack, IconEURXB, SvgIcon, Text,
} from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  isComingSoon?: boolean,
  isCurv?: boolean,
  type: 'ironbank' | 'FRAX' | 'stETH' | 'EURT' | 'XBE/ETH' | 'EURxb' | 'XBE' | 'CVX' | 'cvxCRV',
  earned: string,
  apr: string,
  proj?: string,
  boost?: string,
  tvl: string,
  myDeposit: string,
  isHeaders?: boolean,
  disabled?: boolean,
  href?: string,
};

const TYPES: Record<Props['type'], { icon: string, label: string }> = {
  ironbank: {
    icon: CoinIronbank,
    label: 'Ironbank',
  },
  FRAX: {
    icon: CoinFrax,
    label: 'FRAX',
  },
  stETH: {
    icon: CoinStEth,
    label: 'stETH',
  },
  EURT: {
    icon: CoinEurt,
    label: 'EURT',
  },
  'XBE/ETH': {
    icon: CoinSushi,
    label: 'XBE/ETH SUSHISWAP POOL',
  },
  EURxb: {
    icon: IconEURXB,
    label: 'EURxb (coming soon!)',
  },
  XBE: {
    icon: CoinXbe,
    label: 'XBE',
  },
  CVX: {
    icon: CoinCvx,
    label: 'CVX',
  },
  cvxCRV: {
    icon: CoinCvx,
    label: 'cvxCRV',
  },
};

const StakeCurveLPRow: FC<PropsWithChildren<Props>> = ({
  className,
  isComingSoon,
  isCurv = false,
  type,
  children,
  earned,
  apr,
  proj,
  boost,
  tvl,
  myDeposit,
  isHeaders,
  disabled = false,
  href,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const tag = useMemo(() => {
    const child = (
      <>
        <div className={styles.poolName}>
          <SvgIcon
            className={styles.icon}
            icon={TYPES[type].icon}
          />
          <Text
            className={cx(
              styles.label,
              styles.text,
              { [styles.colorTextComingSoon]: isCurv && isComingSoon },
            )}
            size="tinier"
            color="extra"
            bold
          >{TYPES[type].label}
          </Text>
        </div>

        <Text
          className={cx(styles.earned,
            styles.text,
            {
              [styles.comingSoonText]: isComingSoon,
              [styles.comingSoonCurvText]: isCurv && isComingSoon,
            })}
          size="tinier"
          color="extra"
          bold
        >
          {isComingSoon ? 'Coming soon' : earned}
        </Text>

        {!isComingSoon && (
          <>
            <div className={styles.apr}>
              <Text
                className={cx(styles.text, !(proj && boost) && styles.aprBig)}
                size="tinier"
                color="extra"
                bold
              >
                {apr}
              </Text>
              {(proj && boost) && (
              <Text
                className={styles.projBoost}
                color="extra"
                bold
              >
                {`(proj ${proj}) CRV boost: ${boost}x`}
              </Text>
              )}
            </div>

            <Text
              className={cx(styles.tvl, styles.text)}
              size="tinier"
              color="extra"
              bold
            >
              {tvl}
            </Text>

            <Text
              className={cx(styles.myDeposit, styles.text)}
              size="tinier"
              color="extra"
              bold
            >
              {myDeposit}
            </Text>
          </>
        )}

        {isCurv && isComingSoon && (
          <>
            <div className={styles.apr} />

            <Text
              className={cx(
                styles.tvl,
                styles.text,
                { [styles.colorTextComingSoon]: isCurv && isComingSoon },
              )}
              size="tinier"
              color="extra"
              bold
            >
              {tvl}
            </Text>

            <Text
              className={cx(
                styles.myDeposit,
                styles.text,
                { [styles.colorTextComingSoon]: isCurv && isComingSoon },
              )}
              size="tinier"
              color="extra"
              bold
            >
              {myDeposit}
            </Text>
          </>
        )}

        {!href && (
          <div
            className={styles.openIconWrapper}
          >
            <SvgIcon
              className={styles.openIcon}
              icon={IconArrowDropBlack}
            />
          </div>
        )}
      </>
    );

    if (href) {
      return (
        <a
          className={styles.row}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {child}
        </a>
      );
    }

    return (
      <button
        type="button"
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className={styles.row}
        disabled={disabled}
      >
        {child}
      </button>
    );
  }, [
    type, isComingSoon, earned, proj, boost, apr, tvl, myDeposit, isCurv,
    href, disabled, isOpen,
  ]);

  return (
    <div className={cx(styles.container, isOpen && styles.open, className)}>
      { isHeaders && (
        <div className={styles.rowHeader}>
          <Text
            className={cx(styles.headerCell, styles.headerPoolName)}
          >
            POOL NAME
          </Text>
          <Text
            className={cx(styles.headerCell, styles.headerEarned)}
          >
            EARNED
          </Text>
          <Text
            className={cx(styles.headerCell, styles.headerApr)}
          >
            vAPR
          </Text>
          <Text
            className={cx(styles.headerCell, styles.headerTvl)}
          >
            TVL
          </Text>
          <Text
            className={cx(styles.headerCell, styles.headerMyDeposits)}
          >
            MY DEPOSITS
          </Text>
        </div>
      )}
      {tag}
      {isOpen && (
        <div className={styles.children}>
          {children}
        </div>
      )}
    </div>
  );
};

export default StakeCurveLPRow;

import {
  put, takeEvery, all,
} from 'redux-saga/effects';
import { CrvConvertingActionTypes } from 'store/crvConverting/actionTypes';
import {
  crvConvertingGetAllowanceAction,
  crvConvertingGetBlockInfoAction,
  crvConvertingSetStateAction,
  crvConvertingSetStatusAction,
} from 'store/crvConverting/actions';
import {
  RequestStatus, setNotification, getContract,
} from '@workstream/shared';
import { cvxCrvVaultAbi, wethAbi } from 'assets/abi';
import { CVX_CRV_VAULT_CONTRACT_ADDRESS } from 'appConstants/contracts';

function* crvConvertingGetBlockInfoSaga(
  { type }: ReturnType<typeof crvConvertingGetBlockInfoAction>,
) {
  try {
    yield put(crvConvertingSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const contract = getContract(cvxCrvVaultAbi, CVX_CRV_VAULT_CONTRACT_ADDRESS);

    const tokensCount: string = yield contract
      .methods
      .getRewardTokensCount()
      .call();

    const listRewardsContract: string[] = yield all(
      Array(Number(tokensCount)).fill(0).map((_, index) => contract
        .methods
        .getRewardToken(index)
        .call()),
    );

    const listRewardsContractSymbol: string[] = yield all(
      listRewardsContract.map((addressReward) => {
        const contractReward = getContract(wethAbi, addressReward);
        return contractReward
          .methods
          .symbol()
          .call();
      }),
    );

    yield put(crvConvertingSetStateAction({
      listRewardsContract: listRewardsContract.map((addressReward, index) => ({
        address: addressReward,
        symbol: listRewardsContractSymbol[index],
      })),
    }));
    yield put(crvConvertingGetAllowanceAction());
    yield put(crvConvertingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(crvConvertingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    CrvConvertingActionTypes.GET_BLOCK_INFO,
    crvConvertingGetBlockInfoSaga,
  );
}

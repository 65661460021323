import React, { FC, useState } from 'react';
import cx from 'classnames';
import {
  CoinCrv, SvgIcon, Text, Button, IconArrowDropBlack,
} from '@workstream/shared';
import { ClaimBlock } from 'components';
import styles from './styles.module.scss';
import { RequirementWalletProvider } from '../index';

export type ClaimChildrenRow = {
  pool: {
    name: string,
    icon: string,
  },
  onClaim: () => void,
  buttonClaimDisabled: boolean,
};

export type ClaimRow = {
  pool: {
    name: string,
    icon: string,
  },
  earned: string,
  apr: {
    value: string,
    proj?: string,
    boost?: string,
  },
  deposit: string,
  onClaim?: () => void,
  buttonClaimDisabled?: boolean,
  childrenRow?: ClaimChildrenRow[],
};

type Props = {
  className?: string,
  data: ClaimRow[],
  earned?: string,
  averageApr?: string,
};

const ClaimAll: FC<Props> = ({
  className,
  data,
  earned,
  averageApr,
}) => {
  const [openRow, setOpenRow] = useState('');

  return (
    <RequirementWalletProvider>
      <ClaimBlock
        icon={CoinCrv}
        title="Claim all (CRV, CVX, cvxCRV and XBE)"
        earned={`$${earned}`}
        averageAPR={`${averageApr}%`}
        className={cx(styles.container, className)}
      >
        <Text
          className={styles.xbeRewardText}
          color="secondary"
          size="small"
        >
          XBE rewards are automatically bonded and when claimed go through an unbonding process
          of 5 days. CVX, cvxCRV and CRV can be withdrawn without an unbonding process on
          xbe.finance. During the unbonding process, XBE is automatically staked to continue earning
          staking and protocol rewards.
        </Text>

        <div className={styles.rows}>
          <div className={styles.row}>
            <div className={styles.poolName}>
              <Text
                className={styles.headerTitle}
                color="secondary"
              >
                POOL NAME
              </Text>
            </div>

            <div className={styles.earnedWrapper}>
              <Text
                className={styles.headerTitle}
                color="secondary"
              >
                EARNED
              </Text>
            </div>

            <div className={styles.aprWrapper}>
              <Text
                className={styles.headerTitle}
                color="secondary"
              >
                APR
              </Text>
            </div>

            <div className={styles.depositWrapper}>
              <Text
                className={styles.headerTitle}
                color="secondary"
              >
                MY DEPOSIT
              </Text>
            </div>
          </div>
          {data.map((row) => (
            <div className={styles.rowWrapper} key={row.pool.name}>
              <div
                className={cx(styles.row, styles.rowBody)}
                key={row.pool.name}
              >
                <div className={styles.coinWrapper}>
                  <SvgIcon icon={row.pool.icon} className={styles.icon} />
                  <Text
                    className={styles.coin}
                    color="extra"
                    size="tiny"
                    bold
                  >
                    {row.pool.name}
                  </Text>
                </div>

                <div className={styles.earnedWrapper}>
                  <Text
                    color="extra"
                    size="tiny"
                    bold
                  >
                    {row.earned}
                  </Text>
                </div>

                <div className={styles.aprWrapper}>
                  <Text
                    color="extra"
                    size="tiny"
                    bold
                  >
                    {row.apr.value}
                  </Text>
                  {(row.apr.proj && row.apr.boost) && (
                    <Text
                      className={styles.aprProj}
                      color="secondary"
                      size="tinier"
                    >
                      {`(proj ${row.apr.proj}) CRV boost: ${row.apr.boost}x`}
                    </Text>
                  )}
                </div>

                <div className={styles.depositWrapper}>
                  <Text
                    color="extra"
                    size="tiny"
                    bold
                  >
                    {row.deposit}
                  </Text>
                </div>

                <div className={styles.claimWrapper}>
                  { row.childrenRow ? (
                    <button
                      className={styles.buttonDrop}
                      type="button"
                      onClick={() => setOpenRow((row.pool.name === openRow ? '' : row.pool.name))}
                    >
                      <SvgIcon
                        className={cx(
                          styles.buttonDropIcon,
                          (row.pool.name === openRow) && styles.buttonDropIconOpen,
                        )}
                        icon={IconArrowDropBlack}
                      />
                    </button>
                  ) : (
                    <Button
                      size="sm"
                      className={cx(styles.buttonClaim)}
                      onClick={row.onClaim}
                      disabled={row.buttonClaimDisabled}
                    >
                      Claim
                    </Button>
                  )}
                </div>
              </div>

              {(openRow === row.pool.name && row.childrenRow) && (
                row.childrenRow.map((childrenRow) => (
                  <div className={styles.childrenRow} key={childrenRow.pool.name}>
                    <div className={styles.childrenCoinWrapper}>
                      <SvgIcon icon={childrenRow.pool.icon} />
                      <Text
                        className={styles.coin}
                        color="extra"
                        size="tiny"
                        bold
                      >
                        {childrenRow.pool.name}
                      </Text>
                    </div>
                    <Button
                      size="sm"
                      className={cx(styles.childrenButtonClaim)}
                      onClick={childrenRow.onClaim}
                      disabled={childrenRow.buttonClaimDisabled}
                    >
                      Claim
                    </Button>
                  </div>
                ))
              )}
            </div>
          ))}
        </div>
      </ClaimBlock>
    </RequirementWalletProvider>
  );
};

export default ClaimAll;

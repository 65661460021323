import { RequestStatus } from '@workstream/shared';
import { CvxStakingActionTypes } from 'store/cvxStaking/actionTypes';
import { CvxStakingState } from 'store/cvxStaking/types';

export const cvxStakingSetStateAction = (payload: Partial<CvxStakingState>) => ({
  type: CvxStakingActionTypes.SET_STATE,
  payload,
});

export const cvxStakingSetStatusAction = (
  payload: { type: CvxStakingActionTypes, status: RequestStatus },
) => ({
  type: CvxStakingActionTypes.SET_STATUS,
  payload,
});

export const cvxStakingGetBlockInfoAction = () => ({
  type: CvxStakingActionTypes.GET_BLOCK_INFO,
});

export const cvxStakingGetAllowanceAction = () => ({
  type: CvxStakingActionTypes.GET_ALLOWANCE,
});

export const cvxStakingApproveAction = (payload: string) => ({
  type: CvxStakingActionTypes.APPROVE,
  payload,
});

export const cvxStakingDepositAction = (payload: string) => ({
  type: CvxStakingActionTypes.DEPOSIT,
  payload,
});

export const cvxStakingWithdrawAction = (payload: string) => ({
  type: CvxStakingActionTypes.WITHDRAW,
  payload,
});

import {
  EURT_CONTRACT_ADDRESS,
  IRON_BANK_CONTRACT_ADDRESS,
  ST_ETH_CONTRACT_ADDRESS,
  FRAX_CONTRACT_ADDRESS,
  VAULT_EURT_CONTRACT_ADDRESS,
  VAULT_IRON_BANK_CONTRACT_ADDRESS,
  VAULT_ST_ETH_CONTRACT_ADDRESS,
  VAULT_FRAX_CONTRACT_ADDRESS,
  VAULT_FRAX_OLD_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import {
  eurtAbi, ironBankAbi, stEthAbi, fraxAbi, vaultEurtAbi,
  vaultIronBankAbi, vaultStEthAbi, vaultFraxAbi,
} from 'assets/abi';
import { Unit } from 'web3-utils';

export enum CurveLpToken {
  IRON_BANK = 'ironBank',
  FRAX = 'frax',
  ST_ETH = 'stEth',
  EURT = 'eurt',
}

export const stakeCurveLpTokensAddress: Record<CurveLpToken, string> = {
  [CurveLpToken.IRON_BANK]: IRON_BANK_CONTRACT_ADDRESS,
  [CurveLpToken.FRAX]: FRAX_CONTRACT_ADDRESS,
  [CurveLpToken.ST_ETH]: ST_ETH_CONTRACT_ADDRESS,
  [CurveLpToken.EURT]: EURT_CONTRACT_ADDRESS,
};

export const stakeCurveLpTokensAbi: Record<CurveLpToken, any> = {
  [CurveLpToken.IRON_BANK]: ironBankAbi,
  [CurveLpToken.FRAX]: fraxAbi,
  [CurveLpToken.ST_ETH]: stEthAbi,
  [CurveLpToken.EURT]: eurtAbi,
};

export const stakeCurveLpTokensDecimals: Record<CurveLpToken, Unit> = {
  [CurveLpToken.IRON_BANK]: 'ether',
  [CurveLpToken.FRAX]: 'ether',
  [CurveLpToken.ST_ETH]: 'ether',
  [CurveLpToken.EURT]: 'ether',
};

export const stakeCurveLpVaultTokensAddress: Record<CurveLpToken, string> = {
  [CurveLpToken.IRON_BANK]: VAULT_IRON_BANK_CONTRACT_ADDRESS,
  [CurveLpToken.FRAX]: VAULT_FRAX_CONTRACT_ADDRESS,
  [CurveLpToken.ST_ETH]: VAULT_ST_ETH_CONTRACT_ADDRESS,
  [CurveLpToken.EURT]: VAULT_EURT_CONTRACT_ADDRESS,
};

export const stakeCurveLpVaultOldTokensAddress: Record<CurveLpToken, string> = {
  [CurveLpToken.IRON_BANK]: VAULT_IRON_BANK_CONTRACT_ADDRESS,
  [CurveLpToken.FRAX]: VAULT_FRAX_OLD_CONTRACT_ADDRESS,
  [CurveLpToken.ST_ETH]: VAULT_ST_ETH_CONTRACT_ADDRESS,
  [CurveLpToken.EURT]: VAULT_EURT_CONTRACT_ADDRESS,
};

export const stakeCurveLpVaultTokensAbi: Record<CurveLpToken, any> = {
  [CurveLpToken.IRON_BANK]: vaultIronBankAbi,
  [CurveLpToken.FRAX]: vaultFraxAbi,
  [CurveLpToken.ST_ETH]: vaultStEthAbi,
  [CurveLpToken.EURT]: vaultEurtAbi,
};

export const stakeCurveLpVaultTokensDecimals: Record<CurveLpToken, Unit> = {
  [CurveLpToken.IRON_BANK]: 'ether',
  [CurveLpToken.FRAX]: 'ether',
  [CurveLpToken.ST_ETH]: 'ether',
  [CurveLpToken.EURT]: 'ether',
};

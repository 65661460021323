import { put, takeEvery } from 'redux-saga/effects';
import { RequestStatus, setNotification } from '@workstream/shared';
import {
  claimClaimAction, claimClaimCvxAction,
  claimGetStakeAction, claimSetStatusAction,
} from 'store/claim/actions';
import { ClaimActionTypes } from 'store/claim/actionTypes';
import { ClaimTokens } from 'store/claim/constants';

function* claimClaimCvxSaga(
  { type }: ReturnType<typeof claimClaimCvxAction>,
) {
  try {
    yield put(claimSetStatusAction({ type, status: RequestStatus.REQUEST }));
    yield put(claimClaimAction({ token: ClaimTokens.CVX, claimUnderlying: true }));
    yield put(claimGetStakeAction(ClaimTokens.CVX));
    yield put(claimSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(claimSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* claimCvxSaga() {
  yield takeEvery(
    ClaimActionTypes.CLAIM_CVX,
    claimClaimCvxSaga,
  );
}

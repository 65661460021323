import { put, takeEvery } from 'redux-saga/effects';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import {
  provideLiquiditySetStatusAction, provideLiquidityUnstakeAction,
} from 'store/provideLiquidity/actions';
import {
  getContract,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';
import { sushiVaultAbi } from 'assets/abi';
import { SUSHI_VAULT_CONTRACT_ADDRESS } from 'appConstants';
import { meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';

function* provideLiquidityUnstakeSaga(
  { type, payload: value }: ReturnType<typeof provideLiquidityUnstakeAction>,
) {
  try {
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contract = getContract(sushiVaultAbi, SUSHI_VAULT_CONTRACT_ADDRESS);

    yield contract
      .methods
      .withdraw(web3.utils.toWei(value))
      .send({
        from: address,
      });

    yield put(meGetBalancesAction(BalancesToken.SUSHI_VAULT));
    yield put(meGetBalancesAction(BalancesToken.ETH_XBE));
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (err) {
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.ERROR }));
    setNotification({ type: 'error', message: err.message });
  }
}

export default function* listener() {
  yield takeEvery(
    ProvideLiquidityActionTypes.UNSTAKE,
    provideLiquidityUnstakeSaga,
  );
}

export enum ReferralsInfoActionTypes {
  SET_STATE = 'REFERRALS_INFO.SET_STATE',

  SET_STATUS = 'REFERRALS_INFO.SET_STATUS',

  GET_STATISTICS = 'REFERRALS_INFO.GET_GET_STATISTICS',

  GET_CLAIMABLE_REWARDS = 'REFERRALS_INFO.GET_CLAIMABLE_REWARDS',

  GET_NETWORK_DEPOSIT = 'REFERRALS_INFO.GET_NETWORK_DEPOSIT',

  GET_MEMBERS_REFFERALS = 'REFERRALS_INFO.GET_MEMBERS_REFFERALS',
}

import { formattersDate } from '@workstream/shared';
import BigNumber from 'bignumber.js';

const MILLISECONDS_IN_WEEK = 604800000;

const calcWeeks = (numberWeeks: number, date: Date): string => {
  const numberCurWeeks = parseInt((date.getTime() / (MILLISECONDS_IN_WEEK)).toString(), 10);
  return `${(numberCurWeeks + numberWeeks + 1) * MILLISECONDS_IN_WEEK}`;
};

const calcWeekToEnd = (date: number | string) => {
  const one = new BigNumber(date).div(MILLISECONDS_IN_WEEK);
  const two = new BigNumber(Date.now()).div(MILLISECONDS_IN_WEEK);
  return new BigNumber(one).minus(two).toNumber();
};

const generateArrMonthsWithDates = (dateStart: Date, meLockedDateStart?: Date) => {
  const dateEnd = formattersDate.addWeeks(dateStart, 100);

  if (dateStart.getTime() >= dateEnd.getTime()) {
    return [];
  }

  const interval = formattersDate.intervalToDuration(
    { start: dateStart, end: dateEnd },
  );

  let monthsCount = 0;

  if (interval.months) monthsCount = interval.months;
  if (interval.years) monthsCount += interval.years * 12;
  if (interval.days && interval.days > 1) monthsCount += 1;

  const months: { label: string, value: string }[] = [];

  if (monthsCount > 0) {
    months.push({
      label: '1 %',
      value: calcWeeks(1, dateStart),
    });
    months.push({
      label: '4 %',
      value: calcWeeks(4, dateStart),
    });
  }

  for (let i = 2; i <= monthsCount + 1; i += 1) {
    months.push({
      label: `${(i) * 4} %`,
      value: calcWeeks(4 * i, dateStart),
    });
  }

  const valueMax = calcWeeks(99, dateStart);
  if (meLockedDateStart) {
    if (Number(valueMax) !== meLockedDateStart.getTime()) {
      months.push({
        label: 'Max',
        value: valueMax,
      });
    }
  } else {
    months.push({
      label: 'Max',
      value: valueMax,
    });
  }

  if (meLockedDateStart && meLockedDateStart.getTime() !== 0) {
    const intervalStart = formattersDate.intervalToDuration(
      { start: dateStart, end: meLockedDateStart },
    );

    if (typeof intervalStart.months !== 'undefined') {
      const weekToEnd = calcWeekToEnd(meLockedDateStart.getTime());

      if (months.length) {
        return months.slice(weekToEnd / 4 + 1, months.length);
      }

      return [];
    }
  }

  return months;
};

export default generateArrMonthsWithDates;

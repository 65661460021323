import BigNumber from 'bignumber.js';
const toAbbreviation = (value, digits = 2) => {
    const num = +value;
    const si = [
        { value: 1, symbol: '' },
        { value: 1E3, symbol: 'K' },
        { value: 1E6, symbol: 'M' },
        { value: 1E9, symbol: 'G' },
        { value: 1E12, symbol: 'T' },
        { value: 1E15, symbol: 'P' },
        { value: 1E18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i = 0;
    // eslint-disable-next-line no-plusplus
    for (i = si.length - 1; i > 0; i--) {
        if (Math.abs(num) >= si[i].value) {
            break;
        }
    }
    return `${(num / si[i].value).toFixed(digits).replace(rx, '$1')} ${si[i].symbol}`;
};
const defaultBNFormatConfig = {
    prefix: '',
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
};
const toFormatViaBN = (value, digits = 4, config = defaultBNFormatConfig) => {
    const bn = new BigNumber(value);
    return bn.dp(digits).toFormat(config);
};
const toFormatViaBNWithoutDP = (value, config = defaultBNFormatConfig) => {
    const bn = new BigNumber(value);
    return bn.toFormat(config);
};
const toFixed = (value, fractionDigits = 2) => {
    const bn = new BigNumber(value);
    return bn.isGreaterThan(0) ? bn.toFixed(fractionDigits) : '0';
};
export default {
    toAbbreviation,
    toFormatViaBN,
    toFormatViaBNWithoutDP,
    toFixed,
};

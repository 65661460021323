import { routes } from './index';

export const NAV_LINKS_HEADER = [
  {
    link: routes.stakeCurve.root,
    title: routes.stake.title,
  },
  {
    link: routes.claim.root,
    title: routes.claim.title,
  },
  {
    link: routes.lockup.root,
    title: routes.lockup.title,
  },
  {
    link: routes.referrals.root,
    title: routes.referrals.title,
  },
];

export const NAV_LINKS_STAKE = [
  {
    link: routes.stakeCurve.root,
    title: routes.stakeCurve.title,
  },
];

import { HandlerFn } from 'store/types';
import { Web3ActionTypes } from './actionTypes';
import { Web3State } from './types';
import { web3SetStateAction } from './actions';

type Web3ActionFn<F extends (...args: any) => any> = HandlerFn<Web3State, ReturnType<F>>;

const setState: Web3ActionFn<typeof web3SetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

export const WEB3_HANDLERS = {
  [Web3ActionTypes.SET_STATE]: setState,
};

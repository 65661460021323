import BigNumber from 'bignumber.js';
import { DECIMALS } from 'appConstants';

export const fixDecimals = (value: BigNumber) => {
  if (value.decimalPlaces() > DECIMALS) {
    return value.toFixed(DECIMALS, 1);
  }

  return value.toString();
};

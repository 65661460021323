export enum CrvConvertingActionTypes {
  SET_STATE = 'CRV_CONVERTING.SET_STATE',

  SET_STATUS = 'CRV_CONVERTING.SET_STATUS',

  GET_BLOCK_INFO = 'CRV_CONVERTING.GET_BLOCK_INFO',

  GET_ALLOWANCE = 'CRV_CONVERTING.GET_ALLOWANCE',

  APPROVE = 'CRV_CONVERTING.APPROVE',

  CONVERT_AND_STAKE = 'CRV_CONVERTING.CONVERT_AND_STAKE',
  CONVERT = 'CRV_CONVERTING.CONVERT',

  WITHDRAW = 'CRV_CONVERTING.WITHDRAW',
}

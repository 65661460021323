import { initializeApp } from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyD3WUsqo6goBXpSsSamUYyYvN9a4q5xBM8',
  authDomain: 'metamask-verification.firebaseapp.com',
  databaseURL:
    'https://metamask-verification-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'metamask-verification',
  storageBucket: 'metamask-verification.appspot.com',
  messagingSenderId: '202470280232',
  appId: '1:202470280232:web:a1f6fc1101bd25c542791d',
  measurementId: 'G-LL5XQLGEC4',
};

const firebase = initializeApp(firebaseConfig);

export default firebase;

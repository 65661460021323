import React, { ChangeEvent, FC, useState } from 'react';
import cx from 'classnames';
import { Props as InfoListProps } from 'components/InfoList';
import { RadioGroup } from '@workstream/shared';
import styles from './styles.module.scss';
import Stake from '../Stake';
import Unstake from '../Unstake';
import Info from '../Info';

type Props = {
  className?: string,
  iconInput: string,
  description?: string,
  depositCurveLink?: string,
  note?: string,
  token?: string,
  stakeAvailable?: string,
  stakeOnMaxClick?: () => void,
  stakeOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  stakeValue?: string,
  stakeApproveButtonText?: string,
  stakeApproveButtonDisabled?: boolean,
  stakeApproveButtonOnClick?: () => void,
  stakeDepositButtonText?: string,
  stakeDepositButtonDisabled?: boolean,
  stakeDepositButtonOnClick?: () => void,
  unstakeIconInput?: string,
  unstakeDescription?: string,
  unstakeToken?: string,
  unstakeTokenAvailable?: string,
  unstakeInputOnMaxClick?: () => void,
  unstakeInputValue?: string,
  unstakeInputOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  unstakeButtonText?: string,
  unstakeButtonDisabled?: boolean,
  unstakeButtonOnClick?: () => void,
  infoList?: InfoListProps['list'],
};

const StakeWrapper: FC<Props> = ({
  className,
  iconInput,
  description,
  depositCurveLink,
  note,
  token,
  stakeAvailable,
  stakeOnMaxClick,
  stakeOnChange,
  stakeValue,
  stakeApproveButtonText,
  stakeApproveButtonDisabled,
  stakeApproveButtonOnClick,
  stakeDepositButtonText,
  stakeDepositButtonDisabled,
  stakeDepositButtonOnClick,
  unstakeIconInput,
  unstakeDescription,
  unstakeToken,
  unstakeTokenAvailable,
  unstakeInputOnMaxClick,
  unstakeInputValue,
  unstakeInputOnChange,
  unstakeButtonText,
  unstakeButtonDisabled,
  unstakeButtonOnClick,
  infoList,
}) => {
  const pages: { [ index: string]: JSX.Element } = {
    Stake: <Stake
      icon={iconInput}
      name={`stakeCurve${token}`}
      token={token}
      description={description}
      depositCurveLink={depositCurveLink}
      note={note}
      available={stakeAvailable}
      onMaxClick={stakeOnMaxClick}
      onChange={stakeOnChange}
      value={stakeValue}
      approveButtonText={stakeApproveButtonText}
      approveButtonDisabled={stakeApproveButtonDisabled}
      approveButtonOnClick={stakeApproveButtonOnClick}
      depositButtonText={stakeDepositButtonText}
      depositButtonDisabled={stakeDepositButtonDisabled}
      depositButtonOnClick={stakeDepositButtonOnClick}
    />,
    Unstake: <Unstake
      icon={unstakeIconInput || ''}
      name={`unstakeCurve${token}`}
      description={unstakeDescription}
      token={unstakeToken}
      tokenAvailable={unstakeTokenAvailable}
      inputOnMaxClick={unstakeInputOnMaxClick}
      inputValue={unstakeInputValue}
      inputOnChange={unstakeInputOnChange}
      buttonText={unstakeButtonText}
      buttonDisabled={unstakeButtonDisabled}
      buttonOnClick={unstakeButtonOnClick}
    />,
    Info: <Info
      list={infoList}
    />,
  };

  const [option, setOption] = useState(Object.keys(pages)[0]);

  return (
    <div className={cx(styles.container, className)}>
      <RadioGroup
        className={styles.radioGroup}
        nameGroup={`stakeCurve${token}`}
        options={Object.keys(pages)}
        callback={setOption}
        theme="lightBlueMini"
      />

      {pages[option]}
    </div>
  );
};

export default StakeWrapper;

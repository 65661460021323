import { put, takeLeading } from 'redux-saga/effects';
import { CrvConvertingActionTypes } from 'store/crvConverting/actionTypes';
import {
  getContract, setNotification,
} from '@workstream/shared';
import { crvAbi } from 'assets/abi';
import {
  CVX_CRV_STRATEGY_CONTRACT_ADDRESS,
  CRV_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { crvConvertingSetStateAction } from 'store/crvConverting/actions';
import { selectAddress } from 'store/utils';

function* crvConvertingGetAllowanceSaga() {
  try {
    const address: string = yield selectAddress();
    const contract = getContract(crvAbi, CRV_CONTRACT_ADDRESS);
    const allowance: string = yield contract
      .methods
      .allowance(address, CVX_CRV_STRATEGY_CONTRACT_ADDRESS)
      .call();

    yield put(crvConvertingSetStateAction({ allowance }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
  }
}

export function* getAllowanceSaga() {
  yield takeLeading(
    CrvConvertingActionTypes.GET_ALLOWANCE,
    crvConvertingGetAllowanceSaga,
  );
}

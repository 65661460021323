import { put, takeEvery } from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import {
  getContract,
  RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { meSetStatusAction, meGetIsLockedXbeAction, meSetStateAction } from 'store/me/actions';
import { sushiVaultAbi } from 'assets/abi';
import { selectAddress } from 'store/utils';

function* meGetWithdrawAllBalance(
  { type }: ReturnType<typeof meGetIsLockedXbeAction>,
) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    // do not change the address without the permission of the blockchain department
    const contract = getContract(sushiVaultAbi, '0x5220871eB8Fab14400787aA27FC26774CBC46F99');

    const result: string = yield contract
      .methods
      .balanceOf(address)
      .call();
    yield put(meSetStateAction({
      withdrawAllBalance: web3.utils.fromWei(result),
    }));
    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* meGetWithdrawAllBalanceSaga() {
  yield takeEvery(
    MeActionTypes.GET_WITHDRAW_ALL_BALANCE,
    meGetWithdrawAllBalance,
  );
}

import { RequestStatus } from '@workstream/shared';
import { ClaimActionTypes } from 'store/claim/actionTypes';
import { ClaimTokens } from 'store/claim/constants';
import { ClaimState } from 'store/claim/types';

export const claimSetStateAction = (payload: Partial<ClaimState>) => ({
  type: ClaimActionTypes.SET_STATE,
  payload,
});

export const claimSetStatusAction = (
  payload: { type: ClaimActionTypes, status: RequestStatus },
) => ({
  type: ClaimActionTypes.SET_STATUS,
  payload,
});

export const claimGetStakeAction = (payload: ClaimTokens) => ({
  type: ClaimActionTypes.GET_STAKE,
  payload,
});

export const claimSetClaimStatusAction = (
  payload: Partial<ClaimState['uiClaim']>,
) => ({
  type: ClaimActionTypes.SET_CLAIM_STATUS,
  payload,
});

export const claimClaimAction = (payload: { token: ClaimTokens, claimUnderlying: boolean }) => ({
  type: ClaimActionTypes.CLAIM,
  payload,
});

export const claimClaimSushiAction = () => ({
  type: ClaimActionTypes.CLAIM_SUSHI,
});

export const claimClaimCvxCrvAction = () => ({
  type: ClaimActionTypes.CLAIM_CVX_CRV,
});

export const claimClaimCvxAction = () => ({
  type: ClaimActionTypes.CLAIM_CVX,
});

export const claimClaimReferralAction = () => ({
  type: ClaimActionTypes.CLAIM_REFERRAL,
});

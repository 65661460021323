import { HandlerFn } from 'store/types';
import { XbeClaimActionTypes } from 'store/xbeClaim/actionTypes';
import { XbeClaimState } from 'store/xbeClaim/types';
import { xbeClaimSetStateAction, xbeClaimSetStatusAction } from 'store/xbeClaim/actions';

type XbeClaimActionFn<F extends (...args: any) => any>
  = HandlerFn<XbeClaimState, ReturnType<F>>;

const setState: XbeClaimActionFn<typeof xbeClaimSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: XbeClaimActionFn<typeof xbeClaimSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const XBE_CLAIM_HANDLERS = {
  [XbeClaimActionTypes.SET_STATE]: setState,
  [XbeClaimActionTypes.SET_STATUS]: setStatus,
};

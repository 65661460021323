import { HandlerFn } from 'store/types';
import { IronBankStakingActionTypes } from 'store/ironBankStaking/actionTypes';
import { IronBankStakingState } from 'store/ironBankStaking/types';
import { ironBankStakingSetStateAction, ironBankStakingSetStatusAction } from 'store/ironBankStaking/actions';

type IronBankStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<IronBankStakingState, ReturnType<F>>;

const setState: IronBankStakingActionFn<typeof ironBankStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: IronBankStakingActionFn<typeof ironBankStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const IRON_BANK_STAKING_HANDLERS = {
  [IronBankStakingActionTypes.SET_STATE]: setState,
  [IronBankStakingActionTypes.SET_STATUS]: setStatus,
};

import { HandlerFn } from 'store/types';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import { XbeStakingState } from 'store/xbeStaking/types';
import { xbeStakingSetStateAction, xbeStakingSetStatusAction } from 'store/xbeStaking/actions';

type XbeStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<XbeStakingState, ReturnType<F>>;

const setState: XbeStakingActionFn<typeof xbeStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: XbeStakingActionFn<typeof xbeStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const XBE_STAKING_HANDLERS = {
  [XbeStakingActionTypes.SET_STATE]: setState,
  [XbeStakingActionTypes.SET_STATUS]: setStatus,
};

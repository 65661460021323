/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cx from 'classnames';
import { useTable, usePagination, useExpanded, useSortBy, } from 'react-table';
import { TableRow } from "./..";
import styles from './styles.module.scss';
const hooks = [
    useSortBy,
    useExpanded,
    usePagination,
];
const Table = ({ columns, data, sticky = false, fixed = false, pageSize = 20, initialSortBy = [], className = '', classNameTH = '', classNameRow = '', getClassNameRow = () => '', }) => {
    const { getTableProps, headerGroups, getTableBodyProps, prepareRow, page, } = useTable({
        columns,
        data,
        initialState: { sortBy: initialSortBy, pageSize },
    }, ...hooks);
    return (React.createElement("div", { className: cx(styles.wrap, className) },
        React.createElement("table", Object.assign({}, getTableProps(), { className: cx(styles.table, {
                [styles.sticky]: sticky,
                [styles.fixed]: fixed,
            }) }),
            React.createElement("thead", null, headerGroups.map((headerGroup) => (React.createElement("tr", Object.assign({}, headerGroup.getHeaderGroupProps()), headerGroup.headers.map((column) => (React.createElement("th", Object.assign({}, column.getHeaderProps({
                className: cx(styles.th, classNameTH),
            })), column.render('Header')))))))),
            React.createElement("tbody", Object.assign({}, getTableBodyProps()),
                React.createElement("tr", { className: styles.divider }),
                page.map((row) => {
                    prepareRow(row);
                    return (React.createElement(TableRow, { key: row.id, row: row, className: cx(classNameRow, getClassNameRow(row.original)) }));
                })))));
};
export default Table;

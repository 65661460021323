import React from 'react';
import { BlockWrapper } from '@workstream/shared';
import { SushiswapLPStakingForm, ProvideLiquiditySushi } from 'containers';
import styles from './styles.module.scss';

const Liquidity = () => (
  <BlockWrapper
    tag="section"
    className={styles.wrapper}
    title="PROVIDE LIQUIDITY"
    description={(
      <>
        You are free to add liquidity via the app or interact directly with
        the platform (Sushiswap).
        This will provide Liquidity to the Sushiswap pools and earn you XBE rewards.
        <b> Please be sure to stake your LP tokens in order to start earning.
          Unstaked LP tokens will NOT earn any rewards.
        </b>
      </>
    )}
    descriptionClassName={styles.description}
  >
    <div className={styles.blocks}>
      <ProvideLiquiditySushi className={styles.liquidityFormSushiswap} />
      <SushiswapLPStakingForm className={styles.sushiswapLPStakingForm} />
    </div>
  </BlockWrapper>
);

export default Liquidity;

import { HandlerFn } from 'store/types';
import { ReferralsInfoActionTypes } from 'store/referrals/info/actionTypes';
import { ReferralsInfoState } from 'store/referrals/info/types';
import {
  referralsInfoSetStatusAction,
  referralsInfoSetStateAction,
} from 'store/referrals/info/actions';

type ReferralsInfoActionFn<F extends (...args: any) => any> =
  HandlerFn<ReferralsInfoState, ReturnType<F>>;

const setState: ReferralsInfoActionFn<typeof referralsInfoSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: ReferralsInfoActionFn<typeof referralsInfoSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const REFERRALS_INFO_HANDLERS = {
  [ReferralsInfoActionTypes.SET_STATE]: setState,
  [ReferralsInfoActionTypes.SET_STATUS]: setStatus,
};

import createReducer from 'store/createReducer';
import { RequestStatus } from '@workstream/shared';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import { ProvideLiquidityState } from 'store/provideLiquidity/types';
import { PROVIDE_LIQUIDITY_HANDLERS } from 'store/provideLiquidity/handlers';

const initialState: ProvideLiquidityState = {
  totalLiquidity: '0',
  allowanceToken: '0',
  price: '0',
  priceReverse: '0',
  ui: {
    [ProvideLiquidityActionTypes.GET_TOTAL_LIQUIDITY]: RequestStatus.INIT,
    [ProvideLiquidityActionTypes.APPROVE_TOKEN]: RequestStatus.INIT,
    [ProvideLiquidityActionTypes.ADD_LIQUIDITY]: RequestStatus.INIT,
    [ProvideLiquidityActionTypes.ADD_LIQUIDITY_ONLY_ETH]: RequestStatus.INIT,
    [ProvideLiquidityActionTypes.GET_ALLOWANCE_TOKEN]: RequestStatus.INIT,
    [ProvideLiquidityActionTypes.GET_PRICE]: RequestStatus.INIT,
    [ProvideLiquidityActionTypes.UNSTAKE]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, PROVIDE_LIQUIDITY_HANDLERS);

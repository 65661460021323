import {
  xbeStakingDepositAction,
  xbeStakingGetBlockInfoAction,
  xbeStakingSetStateAction,
} from 'store/xbeStaking/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import {
  getContract, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { votingStakingRewardsAbi } from 'assets/abi';
import { VOTING_STAKING_REWARDS_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';
import { meGetBalancesAction, meGetBoostLevelAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import { xbeLockupGetBlockInfoAction } from 'store/xbeLockup/actions';
import {
  boostGetBlockDepositInfoAction,
  boostGetBlockLockupInfoAction,
  boostGetStartingVotingPowerAction,
} from 'store/boost/actions';

function* xbeStakingDepositSaga(
  { payload }: ReturnType<typeof xbeStakingDepositAction>,
) {
  try {
    yield put(xbeStakingSetStateAction({ stakingStatus: WriteContractStatus.WRITING }));
    const address: string = yield selectAddress();
    const contract = getContract(votingStakingRewardsAbi, VOTING_STAKING_REWARDS_CONTRACT_ADDRESS);

    yield contract
      .methods
      .stake(web3.utils.toWei(payload))
      .send({
        from: address,
      });

    yield put(xbeStakingGetBlockInfoAction());
    yield put(meGetBoostLevelAction());
    yield put(xbeLockupGetBlockInfoAction());
    yield put(boostGetBlockLockupInfoAction());
    yield put(boostGetStartingVotingPowerAction());
    yield put(boostGetBlockDepositInfoAction());
    yield put(meGetBalancesAction(BalancesToken.XBE));
    yield put(meGetBalancesAction(BalancesToken.VOTING_STAKING_REWARDS));
    setNotification({ type: 'success', message: 'Deposit success!' });
    yield put(xbeStakingSetStateAction({ stakingStatus: WriteContractStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(xbeStakingSetStateAction({ stakingStatus: WriteContractStatus.ERROR }));
  }
}

export function* depositSaga() {
  yield takeLeading(
    XbeStakingActionTypes.DEPOSIT,
    xbeStakingDepositSaga,
  );
}

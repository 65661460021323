import React, { ChangeEvent, FC } from 'react';
import { Button, Input } from '@workstream/shared';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  placeholder?: string,
  className?: string,
  classNameInput?: string,
  value: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onClick: (event: React.MouseEvent) => void,
  disabled?: boolean,
};

const InputWithMaxButton: FC<Props> = ({
  className,
  classNameInput,
  value,
  onChange,
  onClick,
  disabled = false,
  placeholder = '0.00',
}) => (
  <div className={cx(styles.wrapper, className)}>
    <Input
      name="input"
      className={cx(styles.input, classNameInput)}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type="number"
      disabled={disabled}
      onKeyPress={(event) => {
        if (['e', 'E', '-', '+'].includes(event.key)) {
          event.preventDefault();
        }
      }}
    />
    <Button
      size="tiny"
      className={styles.button}
      onClick={onClick}
      disabled={disabled}
    >
      Max
    </Button>
  </div>
);

export default InputWithMaxButton;

/* eslint-disable @typescript-eslint/no-unused-vars,no-console,no-underscore-dangle */
import { put, takeEvery } from 'redux-saga/effects';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import {
  provideLiquidityGetPriceAction,
  provideLiquiditySetStatusAction,
  provideLiquiditySetStateAction,
} from 'store/provideLiquidity/actions';
import {
  getContract,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import {
  ETH_XBE_PAIR_CONTRACT_ADDRESS,
  XBE_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { pairEthXbeAbi } from 'assets/abi';
import { Contract } from 'web3-eth-contract';
import BigNumber from 'bignumber.js';

type GetReservesRes = {
  0: string,
  1: string,
  2: string,
  _blockTimestampLast: string,
  _reserve0: string,
  _reserve1: string,
};

function* provideLiquidityGetPriceSaga(
  { type }: ReturnType<typeof provideLiquidityGetPriceAction>,
) {
  try {
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.REQUEST }));
    const contract: Contract = yield getContract(pairEthXbeAbi, ETH_XBE_PAIR_CONTRACT_ADDRESS);
    const token0: string = yield contract.methods.token0().call();
    const reserves: GetReservesRes = yield contract
      .methods
      .getReserves()
      .call();

    const reserve0 = new BigNumber(web3.utils.fromWei(reserves._reserve0));
    const reserve1 = new BigNumber(web3.utils.fromWei(reserves._reserve1));
    let price: BigNumber;

    if (token0 === XBE_CONTRACT_ADDRESS) {
      price = reserve1.div(reserve0);
    } else {
      price = reserve0.div(reserve1);
    }

    yield put(provideLiquiditySetStateAction({
      price: price.toFixed(),
      priceReverse: new BigNumber(1).div(price).toFixed(),
    }));
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (err) {
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.ERROR }));
    setNotification({ type: 'error', message: err.message });
  }
}

export default function* listener() {
  yield takeEvery(
    ProvideLiquidityActionTypes.GET_PRICE,
    provideLiquidityGetPriceSaga,
  );
}

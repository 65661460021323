import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
const CoinSet = ({ icons, className, classNameIcon, size = 40, }) => (React.createElement("div", { className: cx(className) }, icons.map(({ name, img }, index) => (React.createElement("img", { key: name, src: img, alt: name, style: {
        width: size,
        height: size,
        zIndex: icons.length - index,
        marginLeft: index > 0 ? `-${size / 4}px` : '0px',
    }, className: cx(styles.icon, classNameIcon) })))));
export default CoinSet;

import { RequestStatus } from '@workstream/shared';
import { IronBankStakingActionTypes } from 'store/ironBankStaking/actionTypes';
import { IronBankStakingState } from 'store/ironBankStaking/types';

export const ironBankStakingSetStateAction = (payload: Partial<IronBankStakingState>) => ({
  type: IronBankStakingActionTypes.SET_STATE,
  payload,
});

export const ironBankStakingSetStatusAction = (
  payload: { type: IronBankStakingActionTypes, status: RequestStatus },
) => ({
  type: IronBankStakingActionTypes.SET_STATUS,
  payload,
});

export const ironBankStakingGetBlockInfoAction = () => ({
  type: IronBankStakingActionTypes.GET_BLOCK_INFO,
});

import React, { FC } from 'react';
import { InfoList } from 'components';
import { Props as InfoListProps } from 'components/InfoList';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  list?: InfoListProps['list'],
};

const Info: FC<Props> = ({
  className,
  list = [],
}) => (
  <div className={className}>
    <InfoList list={list} className={styles.list} />
  </div>
);

export default Info;

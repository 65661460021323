import { put, takeLeading } from 'redux-saga/effects';
import { BoostActionTypes } from 'store/boost/actionTypes';
import {
  boostSetStatusAction,
  boostSubmitLockTimeAction,
  boostGetBlockDepositInfoAction,
  boostGetStartingVotingPowerAction,
} from 'store/boost/actions';
import { getContract, RequestStatus, setNotification } from '@workstream/shared';
import { VE_XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { meGetBoostLevelAction } from 'store/me/actions';
import { xbeStakingGetBlockInfoAction } from 'store/xbeStaking/actions';
import { xbeLockupGetBlockInfoAction } from 'store/xbeLockup/actions';
import { veXbeAbi } from 'assets/abi';
import BigNumber from 'bignumber.js';
import { selectAddress } from 'store/utils';

function* boostSubmitLockTimeSaga(
  { type, payload: value }: ReturnType<typeof boostSubmitLockTimeAction>,
) {
  try {
    yield put(boostSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contract = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);
    const lockTime = new BigNumber(value).div(1000).toFixed(0);

    yield contract
      .methods
      .increaseUnlockTime(Number(lockTime))
      .send({
        from: address,
      });

    setNotification({ type: 'success', message: 'Increase lock time success!' });
    yield put(boostGetBlockDepositInfoAction());
    yield put(xbeStakingGetBlockInfoAction());
    yield put(xbeLockupGetBlockInfoAction());
    yield put(meGetBoostLevelAction());
    yield put(boostGetStartingVotingPowerAction());
    yield put(boostSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(boostSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* submitLockTimeSaga() {
  yield takeLeading(
    BoostActionTypes.SUBMIT_LOCK_TIME,
    boostSubmitLockTimeSaga,
  );
}

import { RequestStatus } from '@workstream/shared';
import { SushiSwapLPStakingActionTypes } from 'store/sushiSwapLPStaking/actionTypes';
import { SushiSwapLPStakingState } from 'store/sushiSwapLPStaking/types';

export const sushiSwapLPStakingSetStateAction = (payload: Partial<SushiSwapLPStakingState>) => ({
  type: SushiSwapLPStakingActionTypes.SET_STATE,
  payload,
});

export const sushiSwapLPStakingSetStatusAction = (
  payload: { type: SushiSwapLPStakingActionTypes, status: RequestStatus },
) => ({
  type: SushiSwapLPStakingActionTypes.SET_STATUS,
  payload,
});

export const sushiSwapLPStakingGetBlockInfoAction = () => ({
  type: SushiSwapLPStakingActionTypes.GET_BLOCK_INFO,
});

export const sushiSwapLPStakingApproveAction = (payload: string) => ({
  type: SushiSwapLPStakingActionTypes.APPROVE,
  payload,
});

export const sushiSwapLPStakingGetAllowanceAction = () => ({
  type: SushiSwapLPStakingActionTypes.GET_ALLOWANCE,
});

export const sushiSwapLPStakingDepositAction = (payload: string) => ({
  type: SushiSwapLPStakingActionTypes.DEPOSIT,
  payload,
});

export const sushiSwapLPStakingWithdrawClaimAction = (payload: string) => ({
  type: SushiSwapLPStakingActionTypes.WITHDRAW_CLAIM,
  payload,
});

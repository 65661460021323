import { fork } from 'redux-saga/effects';
import { getBlockInfoSaga } from './getBlockInfo';
import { claimSaga } from './claim';
import { unbondSaga } from './unbond';
import { unstakeSaga } from './unstake';

export default [
  fork(getBlockInfoSaga),
  fork(claimSaga),
  fork(unbondSaga),
  fork(unstakeSaga),
];

import { RequestStatus } from '@workstream/shared';
import createReducer from 'store/createReducer';
import { MinterState } from './types';
import { MINTER_HANDLERS } from './handlers';
import { MinterActionTypes } from './actionTypes';

const initialState: MinterState = {
  totalXBEMinted: '0',
  allClaimableXBE: '0',
  isAccessToClaim: false,
  ui: {
    [MinterActionTypes.GET_STATS]: RequestStatus.INIT,
    [MinterActionTypes.CLAIM]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, MINTER_HANDLERS);

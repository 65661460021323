import React, {
  ChangeEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import {
  CoinEthBlue, CoinSushi, CoinXbe, RequestStatus,
} from '@workstream/shared';
import {
  ETH_XBE_PAIR_CONTRACT_ADDRESS,
  SUSHI_VAULT_CONTRACT_ADDRESS,
  XBE_CONTRACT_ADDRESS,
} from 'appConstants';
import { Props as InfoListProps } from 'components/InfoList';
import { meSelector, provideLiquiditySelector, useShallowSelector } from 'store/selectors';
import {
  provideLiquidityAddLiquidityAction,
  provideLiquidityApproveTokenAction,
  provideLiquidityGetPriceAction,
  provideLiquidityGetTokenAllowanceAction,
  provideLiquidityUnstakeAction,
  provideLiquidityAddLiquidityOnlyEthAction,
} from 'store/provideLiquidity/actions';
import { fixDecimals } from 'utils';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import PlStakeWrapper from '../PlStakeWrapper';

const STAKE_DESCRIPTION = 'Provide liquidity to the XBE/ETH pool on SushiSwap and stake your LP tokens on the <a href="https://alpha.app.xbe.finance/lockup" target="_blank">XBE & veXBE</a> page to earn your proportional share of XBE LP rewards.';
const STAKE_NOTE = 'You are free to add liquidity via the app or interact directly with the platform (<a href="https://app.sushi.com/add/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/0x5DE7Cc4BcBCa31c473F6D2F27825Cfb09cc0Bb16" target="_blank" rel="noopener noreferrer">Sushiswap</a>). This will provide liquidity to the Sushiswap pool only. You will need to stake your LP tokens under the "Provide Liquidity" section on the <a href="https://alpha.app.xbe.finance/lockup" target="_blank">XBE & veXBE</a> page in order to earn XBE rewards. Unstaked LP tokens will NOT earn any rewards.';

const UNSTAKE_DESCRIPTION = 'This section allows users to unstake their LP tokens from XBE.finance, be sure to remove your liquidity directly from Sushiswap should you want to withdraw.';

const list: InfoListProps['list'] = [
  {
    label: 'LP token Address',
    value: ETH_XBE_PAIR_CONTRACT_ADDRESS,
  },
  {
    label: 'Deposit contract address',
    value: SUSHI_VAULT_CONTRACT_ADDRESS,
  },
  {
    label: 'Rewards contract address',
    value: XBE_CONTRACT_ADDRESS,
  },
];
const XbeEthPool = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(provideLiquidityGetPriceAction());
    dispatch(provideLiquidityGetTokenAllowanceAction());
  }, [dispatch]);

  // Statuses
  const pairApproveStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(ProvideLiquidityActionTypes.APPROVE_TOKEN),
  );

  const pairDepositStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(ProvideLiquidityActionTypes.ADD_LIQUIDITY),
  );

  const unstakeStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(ProvideLiquidityActionTypes.UNSTAKE),
  );

  const ethOnlyDepositStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(ProvideLiquidityActionTypes.ADD_LIQUIDITY_ONLY_ETH),
  );

  // Balances
  const ethBalance = useShallowSelector(meSelector.getProp('eth'));
  const { xbe: xbeBalance, sushiVault: sushiBalance } = useShallowSelector(meSelector.getProp('balances'));
  const ethBalanceFixed = useMemo(() => new BigNumber(ethBalance).toFixed(4), [ethBalance]);
  const xbeBalanceFixed = useMemo(() => new BigNumber(xbeBalance).toFixed(4), [xbeBalance]);
  const sushiBalanceFixed = useMemo(() => new BigNumber(sushiBalance).toFixed(4), [sushiBalance]);

  // Variables
  const {
    priceReverse: priceRatio, allowanceToken: xbeAllowance,
  } = useShallowSelector(provideLiquiditySelector.getState);

  // Inputs Values
  const [pairEthValue, setPairEthValue] = useState('');
  const [pairXbeValue, setPairXbeValue] = useState('');
  const [sushiValue, setSushiValue] = useState('');
  const [ethValue, setEthValue] = useState('');
  const [pairEthError, setPairEthError] = useState(false);
  const [pairXbeError, setPairXbeError] = useState(false);

  useEffect(() => {
    setPairEthError(new BigNumber(pairEthValue).isGreaterThan(ethBalance));
  }, [pairEthValue, ethBalance]);

  useEffect(() => {
    setPairXbeError(new BigNumber(pairXbeValue).isGreaterThan(xbeBalance));
  }, [pairXbeValue, xbeBalance]);

  // Inputs Max Clicks
  const pairEthOnMaxClick = useCallback(() => {
    setPairEthValue(ethBalance);

    const valueBN = new BigNumber(ethBalance);
    setPairXbeValue(valueBN.isNaN() ? '' : fixDecimals(valueBN.multipliedBy(priceRatio)));
  }, [ethBalance, priceRatio]);

  const pairXbeOnMaxClick = useCallback(() => {
    setPairXbeValue(xbeBalance);

    const valueBN = new BigNumber(xbeBalance);
    setPairEthValue(valueBN.isNaN() ? '' : fixDecimals(valueBN.div(priceRatio)));
  }, [xbeBalance, priceRatio]);

  const unstakeInputOnMaxClick = useCallback(() => {
    setSushiValue(sushiBalance);
  }, [sushiBalance]);

  const ethOnMaxClick = useCallback(() => {
    setEthValue(ethBalance);
  }, [ethBalance]);

  // Inputs Handlers
  const pairEthInputHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPairEthValue(event.target.value);

    const valueBN = new BigNumber(event.target.value);
    setPairXbeValue(valueBN.isNaN() ? '' : fixDecimals(valueBN.multipliedBy(priceRatio)));
  }, [priceRatio]);

  const pairXbeInputHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPairXbeValue(event.target.value);

    const valueBN = new BigNumber(event.target.value);
    setPairEthValue(valueBN.isNaN() ? '' : fixDecimals(valueBN.div(priceRatio)));
  }, [priceRatio]);

  const unstakeInputOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSushiValue(event.target.value);
  }, []);

  const ethInputHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEthValue(event.target.value);
  }, []);

  // Buttons Clicks
  const stakeApprovePairButtonOnClick = useCallback(() => {
    dispatch(provideLiquidityApproveTokenAction(pairXbeValue));
  }, [dispatch, pairXbeValue]);

  const stakeDepositPairButtonOnClick = useCallback(() => {
    dispatch(provideLiquidityAddLiquidityAction({
      amountToken: pairXbeValue,
      amountEth: pairEthValue,
    }));
  }, [dispatch, pairEthValue, pairXbeValue]);

  const unstakeButtonOnClick = useCallback(() => {
    dispatch(provideLiquidityUnstakeAction(sushiValue));
  }, [dispatch, sushiValue]);

  const stakeDepositEthOnlyButtonOnClick = useCallback(() => {
    dispatch(provideLiquidityAddLiquidityOnlyEthAction(ethValue));
  }, [dispatch, ethValue]);

  // Disabled Buttons
  const stakeApprovePairButtonDisabled = useMemo(() => {
    const pairXbeValueBN = new BigNumber(pairXbeValue);
    if (pairXbeValueBN.isEqualTo(0)) return false;
    return [
      pairXbeValue.length === 0,
      pairXbeValueBN.isLessThanOrEqualTo(xbeAllowance),
      pairApproveStatus === RequestStatus.REQUEST,
      pairDepositStatus === RequestStatus.REQUEST,
      ethOnlyDepositStatus === RequestStatus.REQUEST,
    ].includes(true);
  }, [ethOnlyDepositStatus, pairApproveStatus, pairDepositStatus, pairXbeValue, xbeAllowance]);

  const stakeDepositPairButtonDisabled = useMemo(() => {
    const pairXbeValueBN = new BigNumber(pairXbeValue);

    return [
      pairEthValue === '' || pairEthValue === '0',
      pairEthValue === '' || pairEthValue === '0',
      new BigNumber(pairEthValue).isGreaterThan(ethBalance),
      pairXbeValueBN.isGreaterThan(xbeBalance),
      pairXbeValueBN.isGreaterThan(xbeAllowance),
      pairXbeValueBN.isEqualTo(0),
      pairDepositStatus === RequestStatus.REQUEST,
      ethOnlyDepositStatus === RequestStatus.REQUEST,
    ].includes(true);
  },
  [
    pairXbeValue,
    pairEthValue,
    ethBalance,
    xbeBalance,
    xbeAllowance,
    pairDepositStatus,
    ethOnlyDepositStatus,
  ]);

  const unstakeButtonDisabled = useMemo(() => [
    sushiValue === '',
    sushiValue === '0',
    new BigNumber(sushiValue).isGreaterThan(sushiBalance),
    unstakeStatus === RequestStatus.REQUEST,
  ].includes(true), [unstakeStatus, sushiValue, sushiBalance]);

  const ethDepositButtonDisabled = useMemo(() => [
    ethValue === '',
    ethValue === '0',
    new BigNumber(ethValue).isGreaterThan(ethBalance),
    ethOnlyDepositStatus === RequestStatus.REQUEST,
    pairApproveStatus === RequestStatus.REQUEST,
    pairDepositStatus === RequestStatus.REQUEST,
  ].includes(true),
  [ethValue, ethBalance, ethOnlyDepositStatus, pairApproveStatus, pairDepositStatus]);

  return (
    <PlStakeWrapper
      token="xbeethpool"
      iconInput={CoinSushi}
      stakeTokenOneIcon={CoinEthBlue}
      stakeTokenTwoIcon={CoinXbe}
      stakeDescription={STAKE_DESCRIPTION}
      stakeNote={STAKE_NOTE}
      stakeTokenOne="ETH"
      stakeTokenTwo="XBE"
      stakeTokenOneAvailable={ethBalanceFixed}
      stakeTokenTwoAvailable={xbeBalanceFixed}
      stakeInputPairOneOnMaxClick={pairEthOnMaxClick}
      stakeInputPairOneValue={pairEthValue}
      stakeInputPairOneError={pairEthError}
      stakeInputPairOneOnChange={pairEthInputHandler}
      stakeInputPairTwoOnMaxClick={pairXbeOnMaxClick}
      stakeInputPairTwoValue={pairXbeValue}
      stakeInputPairTwoError={pairXbeError}
      stakeInputPairTwoOnChange={pairXbeInputHandler}
      stakeApprovePairButtonDisabled={stakeApprovePairButtonDisabled}
      stakeApprovePairButtonOnClick={stakeApprovePairButtonOnClick}
      stakeApprovePairButtonText={pairApproveStatus === RequestStatus.REQUEST ? 'Approving...' : undefined}
      stakeDepositPairButtonDisabled={stakeDepositPairButtonDisabled}
      stakeDepositPairButtonOnClick={stakeDepositPairButtonOnClick}
      stakeDepositPairButtonText={pairDepositStatus === RequestStatus.REQUEST ? 'Processing...' : undefined}
      stakeInputTokenOnMaxClick={ethOnMaxClick}
      stakeInputTokenValue={ethValue}
      stakeInputTokenOnChange={ethInputHandler}
      stakeDepositTokenButtonDisabled={ethDepositButtonDisabled}
      stakeDepositTokenButtonOnClick={stakeDepositEthOnlyButtonOnClick}
      stakeDepositTokenButtonText={ethOnlyDepositStatus === RequestStatus.REQUEST ? 'Processing...' : undefined}
      unstakeDescription={UNSTAKE_DESCRIPTION}
      unstakeToken="SLP"
      unstakeTokenAvailable={sushiBalanceFixed}
      unstakeInputOnMaxClick={unstakeInputOnMaxClick}
      unstakeInputValue={sushiValue}
      unstakeInputOnChange={unstakeInputOnChange}
      unstakeButtonDisabled={unstakeButtonDisabled}
      unstakeButtonOnClick={unstakeButtonOnClick}
      unstakeButtonText={unstakeStatus === RequestStatus.REQUEST ? 'Processing...' : undefined}
      infoList={list}
    />
  );
};

export default XbeEthPool;

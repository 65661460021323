import { HandlerFn } from 'store/types';
import { SushiSwapLPStakingActionTypes } from 'store/sushiSwapLPStaking/actionTypes';
import { SushiSwapLPStakingState } from 'store/sushiSwapLPStaking/types';
import {
  sushiSwapLPStakingSetStateAction, sushiSwapLPStakingSetStatusAction,
} from 'store/sushiSwapLPStaking/actions';

type SushiSwapLPStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<SushiSwapLPStakingState, ReturnType<F>>;

const setState: SushiSwapLPStakingActionFn<typeof sushiSwapLPStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: SushiSwapLPStakingActionFn<typeof sushiSwapLPStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const SUSHISWAP_LP_STAKING_HANDLERS = {
  [SushiSwapLPStakingActionTypes.SET_STATE]: setState,
  [SushiSwapLPStakingActionTypes.SET_STATUS]: setStatus,
};

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import detectEthereumProvider from '@metamask/detect-provider';
import {
  BlockWrapper, Button, Copyable, handleMetamaskConnect, Icon, Logo, MetamaskStatus, Text,
  setNotification,
} from '@workstream/shared';
import { useDispatch, useSelector } from 'react-redux';
import { web3SetStateAction } from 'store/web3/actions';
import { web3Selector, useShallowSelector, meSelector } from 'store/selectors';
import { meGetIsSignedAction, meSetIsSignedAction } from 'store/me/actions';
import { NAV_LINKS_HEADER, CHAIN_ID } from 'appConstants';

import styles from './styles.module.scss';
import tcsStyles from './styles.tcs.module.scss';

function Terms() {
  const dispatch = useDispatch();
  const dialog = React.useRef(null);
  const dialogContent = React.useRef(null);

  // get isSigned from the store to check if user has signed.
  const isSigned = useShallowSelector(meSelector.getProp('isSigned'));
  // eslint-disable-next-line no-console
  console.log(`issigned: ${isSigned}`);
  const metamaskAddress = useSelector(web3Selector.getProp('address'));

  // If user has connected their wallet, dispatch an action to the store to determine if user
  // has signed.
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('getting mm');
    if (metamaskAddress) {
      // eslint-disable-next-line no-console
      console.log('dispatching');
      dispatch(meGetIsSignedAction());
    }
  }, [dispatch, metamaskAddress, isSigned]);

  // Function to open the terms and conditions dialog.
  const handleOpenDialog = () => {
    if (dialog.current) {
      // @ts-ignore
      dialog.current.style.display = 'block';
    }
  };

  // if user has not signed, open the terms and condition dialog.
  React.useEffect(() => {
    if (isSigned === false) {
      handleOpenDialog();
    }
  }, [isSigned]);

  // This is the message that is displayed inside the metamask dialog
  const msgParams = ` Use of the xbe.finance website, services, dapp, or application
  is subject to the associated terms and conditions, and I hereby confirm
  that I am aware of these and accept them in full. Over and above
  the referred to terms and conditions, I specifically confirm and
    // eslint-disable-next-line no-console
  accept the following:
  1) xbe.finance is a smart contract protocol in alpha stage of launch,
    and even though multiple security audits have been completed on the smart
    contracts, I understand the risks associated with using the xbe.finance
    protocol and associated functions.
  2) Any interactions that I have with the associated XBE protocol apps,
    smart contracts or any related functions MAY place my funds at risk, and
    I hereby release the XBE protocol and its contributors, team members,
    service providers from any and all liability associated with my use of the
    functions.
  3) I am lawfully permitted to access this site and use the xbe.finance
    application functions, and I am not in contravention of any laws
    governing my jurisdiction of residence or citizenship.`;

  // Function to close the terms and conditions dialog.
  const handleCloseDialog = () => {
    if (dialog.current) {
      // @ts-ignore
      dialog.current.style.display = 'none';
    }
  };

  // Function to open the metamask dialog and sign.
  const handleClick = async () => {
    try {
      const provider: any = await detectEthereumProvider({
        timeout: 10,
      });

      if (provider) {
        handleCloseDialog();
        const sign = await provider.request({
          method: 'personal_sign',
          params: [msgParams, metamaskAddress],
        });

        if (sign) {
          // dispatch an action to store, to save the signature in firebase
          dispatch(meSetIsSignedAction({ userSignedData: sign }));

          setTimeout(() => {
            dispatch(meGetIsSignedAction());
          }, 1000);
        }
      }
    } catch (err) {
      handleOpenDialog();
    }
  };

  return (

  // This is the terms and conditions dialog that is displayed when user has not signed.
    <div id="myModal" className={tcsStyles.modal} ref={dialog}>
      <div className={tcsStyles.modalContent} ref={dialogContent}>
        <Text size="big" className={tcsStyles.dialogTitle}>
          Acknowledgement of Terms & Conditions of access
        </Text>
        <Text size="big" align="center" className={tcsStyles.dialogContent}>
          {`Please sign the below message (this is a once off acknowledgement
                required to use any of the XBE protocol functions) before proceeding.
                  By signing this message you are acknowledging the following: `}
        </Text>
        <Text size="big" align="center" className={tcsStyles.dialogContent}>
          {`Use of the xbe.finance website, services, dapp, or application is
                subject to the associated`}
          <a
            href="https://xbe.finance/terms-and-conditions/"
            target="_blank"
            className={styles.guideLink}
            rel="noopener noreferrer"
          >
            {' '}
            terms and conditions
          </a>{' '}
          {`and I hereby confirm
                that I am aware of these and accept them in full.
                Over and above the referred to terms and conditions, I specifically
                confirm and accept the following:  `}
        </Text>
        <ul className={tcsStyles.modalList}>
          <li>
            xbe.finance is a smart contract protocol in alpha stage of launch, and
            even though multiple security{' '}
            <a
              href="https://xbe.finance/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.guideLink}
            >
              {' '}
              audits
            </a>{' '}
            have been completed on the smart contracts, I understand the risks
            associated with using the xbe.finance protocol and associated functions.
          </li>
          <li>
            Any interactions that I have with the associated XBE protocol apps,
            smart contracts or any related functions MAY place my funds at risk, and
            I hereby release the XBE protocol and its contributors, team members,
            and service providers from any and all liability associated with my use
            of the abovementioned functions.
          </li>
          <li>
            I am lawfully permitted to access this site and use the xbe.finance
            application functions, and I am not in contravention of any laws
            governing my jurisdiction of residence or citizenship.
          </li>
        </ul>
        <Button onClick={handleClick} className={tcsStyles.gotItBtn}>
          Sign and Proceed
        </Button>
      </div>
    </div>
  );
}

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const metamaskAddress = useSelector(web3Selector.getProp('address'));
  const dispatch = useDispatch();

  const resizeListener = () => {
    if (window.screen.width > 979) {
      setIsOpenMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  useEffect(() => {
    const root = document.querySelector('#root');
    if (isOpenMenu) {
      root!.classList.add('openMenu');
    } else {
      root!.classList.remove('openMenu');
    }
  }, [isOpenMenu]);

  const onConnectClick = () => {
    handleMetamaskConnect((address, chainID) => {
      if (chainID !== CHAIN_ID) {
        setNotification({
          type: 'error',
          message: 'Please select ETH mainnet as your network',
        });
      }
      dispatch(web3SetStateAction({
        address: chainID === CHAIN_ID
          ? address
          : '',
        metamaskStatus: chainID === CHAIN_ID
          ? MetamaskStatus.ADDRESS_SELECTED
          : MetamaskStatus.NOT_AVAILABLE,
      }));
    });
  };

  return (
    <BlockWrapper tag="header" className={styles.header}>
      <a className={styles.logoLink} href="https://xbe.finance/" target="_blank" rel="noopener noreferrer">
        <Logo type="finance" />
      </a>
      <Terms />
      <div className={cx(styles.menu, isOpenMenu && styles.open)}>
        <ul className={styles.menuList}>
          <li>
            <a
              href="https://easy.xbe.finance/"
              rel="noopener noreferrer"
              target="_blank"
              className={styles.link}
            >
              Easy
            </a>
          </li>
          {NAV_LINKS_HEADER.map((link) => (
            <li key={link.title} className={styles.menuItem}>
              <NavLink
                to={link.link}
                className={styles.link}
                onClick={() => setIsOpenMenu(false)}
                activeClassName={styles.linkActive}
                isActive={(match, location) => {
                  if (match && match.isExact) {
                    return true;
                  }

                  return link.link.split('/').includes('stake') &&
                    location.pathname.split('/').includes('stake');
                }}
              >
                {link.title}
              </NavLink>
            </li>
          ))}
        </ul>
        {metamaskAddress ? (
          <Copyable valueToCopy={metamaskAddress}>
            <Button
              isOutline={false}
              color="white"
              className={styles.buttonAddress}
            >
              <Text size="large" tag="span" bold className={styles.address}>{metamaskAddress}</Text>
              <Icon icon="share" />
            </Button>
          </Copyable>
        ) : (
          <Button
            color="tealDark"
            onClick={onConnectClick}
            className={styles.walletButton}
          >
            Connect wallet
          </Button>
        )}
      </div>
      <button
        type="button"
        className={styles.buttonOpenMenu}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
      >
        <i className={cx(styles.line, styles.line_1)} />
        <i className={cx(styles.line, styles.line_2)} />
        <i className={cx(styles.line, styles.line_3)} />
      </button>
    </BlockWrapper>
  );
};

export default Header;

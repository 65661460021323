import {
  xbeLockupGetBlockInfoAction,
  xbeLockupSetStatusAction,
  xbeLockupUnlockAction,
} from 'store/xbeLockup/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { XbeLockupActionTypes } from 'store/xbeLockup/actionTypes';
import { getContract, RequestStatus, setNotification } from '@workstream/shared';
import { veXbeAbi } from 'assets/abi';
import { VE_XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';
import {
  boostGetBlockDepositInfoAction,
  boostGetBlockLockupInfoAction,
  boostGetStartingVotingPowerAction,
} from 'store/boost/actions';
import { meGetBoostLevelAction, meGetIsLockedXbeAction } from 'store/me/actions';

function* xbeLockupUnlockSaga(
  { type }: ReturnType<typeof xbeLockupUnlockAction>,
) {
  try {
    yield put(xbeLockupSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contract = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);

    yield contract
      .methods
      .withdraw()
      .send({
        from: address,
      });

    yield put(xbeLockupGetBlockInfoAction());
    yield put(boostGetBlockDepositInfoAction());
    yield put(boostGetBlockLockupInfoAction());
    yield put(boostGetStartingVotingPowerAction());
    yield put(meGetIsLockedXbeAction());
    yield put(meGetBoostLevelAction());
    setNotification({ type: 'success', message: 'Unlock success!' });
    yield put(xbeLockupSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(xbeLockupSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* unlockSaga() {
  yield takeLeading(
    XbeLockupActionTypes.UNLOCK,
    xbeLockupUnlockSaga,
  );
}

import React, { FC } from 'react';
import cx from 'classnames';
import { DefaultBlock, Text } from '@workstream/shared';
import styles from './styles.module.scss';

export type Props = {
  className?: string,
  list: {
    label: string,
    value: string
  }[],
};

const InfoList: FC<Props> = ({
  className,
  list,
}) => (
  <DefaultBlock
    className={cx(styles.container, className)}
    theme="lightBlue"
    tag="div"
  >
    <ul className={styles.list}>
      {list.map(({ label, value }) => (
        <li className={styles.item} key={`${label}${value}`}>
          <Text
            className={styles.label}
            color="secondary"
            size="tiny"
          >
            {label}
          </Text>
          <Text
            className={styles.value}
            color="primary"
            size="tiny"
          >
            {value}
          </Text>
        </li>
      ))}
    </ul>
  </DefaultBlock>
);

export default InfoList;

import { PartialRecord, RequestStatus } from '@workstream/shared';
import { RateActionTypes } from 'store/rate/actionTypes';

export enum RatesToken {
  XBE = 'xbe',
  ETH = 'eth',
}

export enum RatesCurrencyToken {
  USDT = 'usdt',
  USD = 'usd',
}

export type RatesTokenBody = string;

export type RatesTokensBody = PartialRecord<RatesCurrencyToken, RatesTokenBody>;

export type RatesTokensRoot = Record<RatesToken, RatesTokensBody>;

export type RateState = RatesTokensRoot & {
  ui: PartialRecord<RateActionTypes, RequestStatus>,
};

import createReducer from 'store/createReducer';
import { SushiSwapLPStakingState } from 'store/sushiSwapLPStaking/types';
import { SUSHISWAP_LP_STAKING_HANDLERS } from 'store/sushiSwapLPStaking/handlers';
import { RequestStatus, WriteContractStatus } from '@workstream/shared';
import { SushiSwapLPStakingActionTypes } from 'store/sushiSwapLPStaking/actionTypes';

const initialState: SushiSwapLPStakingState = {
  submitStatus: WriteContractStatus.INIT,
  percentageOfPool: '0',
  potentialXBEReturns: '0',
  allowance: '0',
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  ui: {
    [SushiSwapLPStakingActionTypes.APPROVE]: RequestStatus.INIT,
    [SushiSwapLPStakingActionTypes.GET_ALLOWANCE]: RequestStatus.INIT,
    [SushiSwapLPStakingActionTypes.DEPOSIT]: RequestStatus.INIT,
    [SushiSwapLPStakingActionTypes.WITHDRAW_CLAIM]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, SUSHISWAP_LP_STAKING_HANDLERS);

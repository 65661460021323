import React, {
  FC, useEffect, useCallback, useMemo,
} from 'react';
import { ClaimBlock } from 'components';
import {
  CoinSushi, handleMetamaskConnect, MetamaskStatus, RequestStatus,
} from '@workstream/shared';
import { useDispatch } from 'react-redux';
import { claimClaimSushiAction, claimGetStakeAction } from 'store/claim/actions';
import { ClaimTokens } from 'store/claim/constants';
import { claimSelector, useShallowSelector, web3Selector } from 'store/selectors';
import { web3SetStateAction } from 'store/web3/actions';

type Props = {
  className?: string,
  withContainer?: boolean,
  buttonClaimClass?: string,
  claimButtonText?: string,
};

const SushiswapClaim: FC<Props> = ({
  className,
  withContainer,
  buttonClaimClass,
  claimButtonText,
}) => {
  const metamaskStatus = useShallowSelector(web3Selector.getProp('metamaskStatus'));

  const data = useShallowSelector(claimSelector.getProp(ClaimTokens.SUSHI));
  const dispatch = useDispatch();
  const { lp: claimStatus } = useShallowSelector(claimSelector.getProp('uiClaim'));
  const isMetamaskConnect = metamaskStatus === MetamaskStatus.ADDRESS_SELECTED;

  useEffect(() => {
    dispatch(claimGetStakeAction(ClaimTokens.SUSHI));
  }, [dispatch]);

  const onClaimClick = useCallback(() => {
    dispatch(claimClaimSushiAction());
  }, [dispatch]);

  const onConnectMetamask = () => handleMetamaskConnect((address) => {
    dispatch(web3SetStateAction({
      address,
      metamaskStatus: address ? MetamaskStatus.ADDRESS_SELECTED : MetamaskStatus.AVAILABLE,
    }));
  });

  const buttonClaimText = useMemo(() => {
    if (!isMetamaskConnect) {
      return 'Connect Metamask';
    }

    return claimStatus === RequestStatus.REQUEST ? 'Claiming...' : claimButtonText;
  }, [isMetamaskConnect, claimStatus, claimButtonText]);

  return (
    <ClaimBlock
      className={className}
      withContainer={withContainer}
      icon={CoinSushi}
      title="Staked Sushi LP"
      earned={`$${Number(data.earned).toFixed(2)}`}
      averageAPR={`${Number(data.apr).toFixed(2)}%`}
      onClaim={isMetamaskConnect ? onClaimClick : onConnectMetamask}
      buttonClaimClass={buttonClaimClass}
      buttonClaimText={buttonClaimText}
      buttonClaimDisabled={claimStatus === RequestStatus.REQUEST}
    />
  );
};

export default SushiswapClaim;

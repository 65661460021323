import { RequestStatus } from '@workstream/shared';
import { StatisticsActionTypes } from 'store/statistics/actionTypes';
import { StatisticsState } from 'store/statistics/types';

export const statisticsSetStateAction = (payload: Partial<StatisticsState>) => ({
  type: StatisticsActionTypes.SET_STATE,
  payload,
});

export const statisticsSetStatusAction = (
  payload: { type: StatisticsActionTypes, status: RequestStatus },
) => ({
  type: StatisticsActionTypes.SET_STATUS,
  payload,
});

export const statisticsGetInfoAction = () => ({
  type: StatisticsActionTypes.GET_INFO,
});

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'containers';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';

import '@workstream/shared/build/index.scss';
import './index.scss';

const config = configureStore();
const { store } = config;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

import React, { useEffect } from 'react';
import {
  Switch, Route,
} from 'react-router-dom';
import { routes } from 'appConstants';
import {
  Claim,
  Lockup,
  Referrals, Stake,
} from 'pages';
import { Header, Statistics } from 'containers';
import { useHistory, useLocation } from 'react-router';

const Routes = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === '/') {
      history.push(routes.stakeCurve.root);
    }
  }, [history, location]);

  return (
    <>
      <Header />
      { location.pathname !== routes.lockup.root && (
      <div className="container">
        <Statistics />
      </div>
      )}

      <Switch>
        <Route path={routes.stake.root} component={Stake} />
        <Route path={routes.claim.root} component={Claim} />
        <Route path={routes.lockup.root} component={Lockup} />
        <Route path={routes.referrals.root} component={Referrals} />
      </Switch>
    </>
  );
};

export default Routes;

import createReducer from 'store/createReducer';
import { ReferralsInfoState } from 'store/referrals/info/types';
import { REFERRALS_INFO_HANDLERS } from 'store/referrals/info/handlers';
import { ReferralsInfoActionTypes } from 'store/referrals/info/actionTypes';
import { RequestStatus } from '@workstream/shared';

const initialState: ReferralsInfoState = {
  members: {
    tier1: '0',
    tier2: '0',
    tier3: '0',
    totalAmount: '0',
  },
  networkDeposit: {
    tier1: '0',
    tier2: '0',
    tier3: '0',
    totalAmount: '0',
  },
  membersReferrals: [],
  claimableRewards: '0.00',
  referralHits: '0',
  ui: {
    [ReferralsInfoActionTypes.GET_STATISTICS]: RequestStatus.INIT,
    [ReferralsInfoActionTypes.GET_CLAIMABLE_REWARDS]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, REFERRALS_INFO_HANDLERS);

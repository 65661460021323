import React from 'react';
import { Button, Text } from "./..";
import cn from 'classnames';
import styles from './styles.module.scss';
const RequirementWallet = ({ className, isConnected, children, onConnect = () => { }, }) => (isConnected ? React.createElement(React.Fragment, null, children) : (React.createElement("div", { className: cn(styles.connectWallet, className) },
    React.createElement("div", { className: styles.modal },
        React.createElement(Text, { size: "big", className: styles.text, align: "center" }, "Connect your wallet to get started"),
        React.createElement(Button, { className: styles.btn, color: "petrol", onClick: onConnect }, "Connect wallet")),
    React.createElement("div", { className: styles.opacity }, children))));
export default RequirementWallet;

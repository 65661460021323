import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  meGetBalanceEthAction, meGetBalancesAction, meGetBoostLevelAction, meGetCurveApysAction,
  meGetIsLockedXbeAction,
} from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import { rateGetRate } from 'store/rate/actions';
import { RatesCurrencyToken, RatesToken } from 'store/rate/types';

const InitializeActionsProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(rateGetRate({
      token: RatesToken.XBE,
      currency: RatesCurrencyToken.USD,
    }));
    dispatch(rateGetRate({
      token: RatesToken.ETH,
      currency: RatesCurrencyToken.USD,
    }));
    dispatch(meGetBalanceEthAction());
    dispatch(meGetIsLockedXbeAction());
    dispatch(meGetBoostLevelAction());
    dispatch(meGetCurveApysAction());
    dispatch(meGetBalancesAction(BalancesToken.XBE));
    dispatch(meGetBalancesAction(BalancesToken.VOTING_STAKING_REWARDS));
    dispatch(meGetBalancesAction(BalancesToken.HIVE_VAULT));
    dispatch(meGetBalancesAction(BalancesToken.SUSHI_VAULT));
    dispatch(meGetBalancesAction(BalancesToken.ETH_XBE));
  }, [dispatch]);

  return <>{children}</>;
};

export default InitializeActionsProvider;

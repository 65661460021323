import React, { FC } from 'react';
import cx from 'classnames';
import { IconMountain, IconWalletIn, IconWalletOut } from 'assets/img';
import { SvgIcon, Text } from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  type: 'claimable' | 'deposits' | 'apy',
  value: string,
};

const TYPES: Record<Props['type'], { icon: string, label: string }> = {
  claimable: {
    icon: IconWalletOut,
    label: 'Total Claimable',
  },
  deposits: {
    icon: IconWalletIn,
    label: 'Total Deposits',
  },
  apy: {
    icon: IconMountain,
    label: 'Average Hive APR', // 'Total Combined APY',
  },
};

const StatisticsItem: FC<Props> = ({
  className,
  type,
  value,
}) => (
  <div className={cx(styles.container, className)}>
    <SvgIcon
      className={styles.icon}
      icon={TYPES[type].icon}
    />
    <div className={styles.inner}>
      <Text
        className={styles.value}
      >
        {value}
      </Text>
      <div className={styles.labelWrapper}>
        <Text
          className={styles.label}
          color="secondary"
        >
          {TYPES[type].label}
        </Text>
      </div>
    </div>
  </div>
);

export default StatisticsItem;

import React, { useCallback, useState, } from 'react';
import cx from 'classnames';
import { Popover, } from 'react-tiny-popover';
import { Icon } from "./..";
import styles from './styles.module.scss';
const ROOT = document.getElementById('root');
const Tooltip = ({ isOutdoorState, isOpenOutdoorState, onToggleOutdoorState, className, classNameChildrenWrap, isShowBtnClose, sizePadding = 'normal', isTextContent, isOpenHover, content, children, align = 'start', padding = 8, ref, containerParent, contentLocation, reposition = true, boundaryInset, }) => {
    const [isOpen, setOpen] = useState(false);
    const handleTooltipToggle = useCallback(() => {
        setOpen((state) => !state);
        if (onToggleOutdoorState)
            onToggleOutdoorState((state) => !state);
    }, []);
    const handleTooltipToggleClick = useCallback(() => {
        if (!isOpenHover)
            handleTooltipToggle();
    }, []);
    const handleTooltipToggleHover = useCallback(() => {
        if (isOpenHover)
            handleTooltipToggle();
    }, []);
    const handleTooltipClose = useCallback(() => {
        setOpen(false);
        if (onToggleOutdoorState)
            onToggleOutdoorState(false);
    }, []);
    const getContent = () => {
        if (isTextContent) {
            return (React.createElement("div", { className: styles.tooltip }, content));
        }
        return (React.createElement("div", { className: cx(styles.tooltipWhite, contentLocation && styles.vHeight, styles[sizePadding]) },
            isShowBtnClose && (React.createElement("button", { type: "button", onClick: handleTooltipClose, className: styles.btnClose, color: "white" },
                React.createElement(Icon, { icon: "close", className: styles.iconClose }))),
            content));
    };
    return (React.createElement(Popover, { isOpen: isOutdoorState ? isOpenOutdoorState : isOpen, positions: ['bottom', 'right', 'top', 'left'], content: getContent(), containerClassName: className, onClickOutside: handleTooltipClose, containerParent: containerParent || ROOT, contentLocation: contentLocation, boundaryInset: boundaryInset, align: align, padding: padding, reposition: reposition, ref: ref },
        React.createElement("div", { onClick: handleTooltipToggleClick, onMouseOver: handleTooltipToggleHover, onMouseOut: handleTooltipToggleHover, onFocus: handleTooltipToggleHover, onBlur: handleTooltipToggleHover, className: classNameChildrenWrap }, children)));
};
export default Tooltip;

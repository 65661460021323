import { put, takeEvery } from 'redux-saga/effects';
import { RequestStatus, setNotification, getContract } from '@workstream/shared';
import { claimClaimReferralAction, claimSetStatusAction } from 'store/claim/actions';
import { referralsInfoGetStatisticsAction } from 'store/referrals/info/actions';
import { ClaimActionTypes } from 'store/claim/actionTypes';
import { referralProgramAbi } from 'assets/abi';
import { REFERRAL_PROGRAM_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* claimClaimReferralSaga(
  { type }: ReturnType<typeof claimClaimReferralAction>,
) {
  try {
    yield put(claimSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contract = getContract(referralProgramAbi, REFERRAL_PROGRAM_CONTRACT_ADDRESS);
    yield contract
      .methods
      .claimRewards()
      .send({
        from: address,
      });

    yield put(referralsInfoGetStatisticsAction());
    yield put(claimSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(claimSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* claimReferralSaga() {
  yield takeEvery(
    ClaimActionTypes.CLAIM_REFERRAL,
    claimClaimReferralSaga,
  );
}

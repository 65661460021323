export enum XbeLockupActionTypes {
  SET_STATE = 'XBE_LOCKUP.SET_STATE',

  SET_STATUS = 'XBE_LOCKUP.SET_STATUS',

  GET_BLOCK_INFO = 'XBE_LOCKUP.GET_BLOCK_INFO',

  DEPOSIT = 'XBE_LOCKUP.DEPOSIT',

  UNLOCK = 'XBE_LOCKUP.UNLOCK',
}

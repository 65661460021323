import React from 'react';
import {
  Switch, Route, BrowserRouter, NavLink,
} from 'react-router-dom';
import { NAV_LINKS_STAKE, routes } from 'appConstants';
import { StakeCurveLPTokens } from 'containers';
import styles from './styles.module.scss';

const RoutesStake = () => (
  <BrowserRouter>
    <nav className={styles.nav}>
      {NAV_LINKS_STAKE.map((link) => (
        <NavLink
          key={link.title}
          to={link.link}
          activeClassName={styles.linkActive}
          className={styles.link}
        >
          {link.title}
        </NavLink>
      ))}
    </nav>
    <Switch>
      <Route path={routes.stakeCurve.root} exact component={StakeCurveLPTokens} />
    </Switch>
  </BrowserRouter>
);

export default RoutesStake;

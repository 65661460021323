import { put, takeLeading } from 'redux-saga/effects';
import { SushiSwapLPStakingActionTypes } from 'store/sushiSwapLPStaking/actionTypes';
import {
  getContract, setNotification,
} from '@workstream/shared';
import { pairEthXbeAbi } from 'assets/abi';
import {
  SUSHI_VAULT_CONTRACT_ADDRESS, ETH_XBE_PAIR_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { sushiSwapLPStakingSetStateAction } from 'store/sushiSwapLPStaking/actions';
import { selectAddress } from 'store/utils';

function* sushiSwapLPStakingGetAllowanceSaga() {
  try {
    const address: string = yield selectAddress();
    const contract = getContract(pairEthXbeAbi, ETH_XBE_PAIR_CONTRACT_ADDRESS);
    const allowance: string = yield contract
      .methods
      .allowance(address, SUSHI_VAULT_CONTRACT_ADDRESS)
      .call();

    yield put(sushiSwapLPStakingSetStateAction({ allowance }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
  }
}

export function* getAllowanceSaga() {
  yield takeLeading(
    SushiSwapLPStakingActionTypes.GET_ALLOWANCE,
    sushiSwapLPStakingGetAllowanceSaga,
  );
}

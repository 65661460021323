import {
  HIVE_VAULT_CONTRACT_ADDRESS,
  XBE_CONTRACT_ADDRESS,
  VOTING_STAKING_REWARDS_CONTRACT_ADDRESS,
  SUSHI_VAULT_CONTRACT_ADDRESS, ETH_XBE_PAIR_CONTRACT_ADDRESS,
  CVX_CONTRACT_ADDRESS, CVX_CRV_CONTRACT_ADDRESS, CRV_CONTRACT_ADDRESS,
  CVX_VAULT_CONTRACT_ADDRESS, CVX_CRV_VAULT_CONTRACT_ADDRESS, CVX_CRV_VAULT_OLD_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import {
  hiveVaultAbi, pairEthXbeAbi, sushiVaultAbi, votingStakingRewardsAbi, xbeAbi,
  cvxAbi, cvxCrvAbi, crvAbi, cvxVaultAbi, cvxCrvVaultAbi,
} from 'assets/abi';
import { Unit } from 'web3-utils';

export enum BalancesToken {
  XBE = 'xbe',
  HIVE_VAULT = 'hiveVault',
  VOTING_STAKING_REWARDS = 'votingStakingRewards',
  SUSHI_VAULT = 'sushiVault',
  ETH_XBE = 'ethXbe',
  CVX = 'cvx',
  CRV = 'crv',
  CVX_CRV = 'cvxCrv',
  CVX_VAULT = 'cvxVault',
  CVX_CRV_VAULT = 'cvxCrvVault',
  CVX_CRV_VAULT_OLD = 'cvxCrvVaultOld',
}

export const meBalancesTokenAddress: Record<BalancesToken, string> = {
  [BalancesToken.XBE]: XBE_CONTRACT_ADDRESS,
  [BalancesToken.HIVE_VAULT]: HIVE_VAULT_CONTRACT_ADDRESS,
  [BalancesToken.VOTING_STAKING_REWARDS]: VOTING_STAKING_REWARDS_CONTRACT_ADDRESS,
  [BalancesToken.SUSHI_VAULT]: SUSHI_VAULT_CONTRACT_ADDRESS,
  [BalancesToken.ETH_XBE]: ETH_XBE_PAIR_CONTRACT_ADDRESS,
  [BalancesToken.CVX]: CVX_CONTRACT_ADDRESS,
  [BalancesToken.CRV]: CRV_CONTRACT_ADDRESS,
  [BalancesToken.CVX_CRV]: CVX_CRV_CONTRACT_ADDRESS,
  [BalancesToken.CVX_VAULT]: CVX_VAULT_CONTRACT_ADDRESS,
  [BalancesToken.CVX_CRV_VAULT]: CVX_CRV_VAULT_CONTRACT_ADDRESS,
  [BalancesToken.CVX_CRV_VAULT_OLD]: CVX_CRV_VAULT_OLD_CONTRACT_ADDRESS,
};

export const meBalancesTokenAbi: Record<BalancesToken, any> = {
  [BalancesToken.XBE]: xbeAbi,
  [BalancesToken.HIVE_VAULT]: hiveVaultAbi,
  [BalancesToken.VOTING_STAKING_REWARDS]: votingStakingRewardsAbi,
  [BalancesToken.SUSHI_VAULT]: sushiVaultAbi,
  [BalancesToken.ETH_XBE]: pairEthXbeAbi,
  [BalancesToken.CVX]: cvxAbi,
  [BalancesToken.CVX_CRV]: cvxCrvAbi,
  [BalancesToken.CRV]: crvAbi,
  [BalancesToken.CVX_VAULT]: cvxVaultAbi,
  [BalancesToken.CVX_CRV_VAULT]: cvxCrvVaultAbi,
  [BalancesToken.CVX_CRV_VAULT_OLD]: cvxCrvVaultAbi,
};

export const meBalancesTokenDecimals: Record<BalancesToken, Unit> = {
  [BalancesToken.XBE]: 'ether',
  [BalancesToken.HIVE_VAULT]: 'ether',
  [BalancesToken.VOTING_STAKING_REWARDS]: 'ether',
  [BalancesToken.SUSHI_VAULT]: 'ether',
  [BalancesToken.ETH_XBE]: 'ether',
  [BalancesToken.CVX]: 'ether',
  [BalancesToken.CVX_CRV]: 'ether',
  [BalancesToken.CRV]: 'ether',
  [BalancesToken.CVX_VAULT]: 'ether',
  [BalancesToken.CVX_CRV_VAULT]: 'ether',
  [BalancesToken.CVX_CRV_VAULT_OLD]: 'ether',
};

export enum Curve {
  IRONBANK = 'ib',
  FRAX = 'frax',
  ST_ETH = 'steth',
  EURT = 'eurt',
  CVX_CRV = 'cvxcrv',
}

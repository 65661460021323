import createReducer from 'store/createReducer';
import { StEthStakingState } from 'store/stEthStaking/types';
import { ST_ETH_STAKING_HANDLERS } from 'store/stEthStaking/handlers';
import { RequestStatus } from '@workstream/shared';
import { StEthStakingActionTypes } from 'store/stEthStaking/actionTypes';

const initialState: StEthStakingState = {
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  ui: {
    [StEthStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, ST_ETH_STAKING_HANDLERS);

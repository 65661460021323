import { crvConvertingApproveAction, crvConvertingGetAllowanceAction, crvConvertingSetStateAction } from 'store/crvConverting/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { CrvConvertingActionTypes } from 'store/crvConverting/actionTypes';
import {
  getContract, MAX_UINT_256, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { crvAbi } from 'assets/abi';
import {
  CVX_CRV_STRATEGY_CONTRACT_ADDRESS,
  CRV_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* crvConvertingApproveSaga(
  { payload: value }: ReturnType<typeof crvConvertingApproveAction>,
) {
  try {
    yield put(crvConvertingSetStateAction({ stakingStatus: WriteContractStatus.APPROVING }));
    const address: string = yield selectAddress();
    const contract = getContract(crvAbi, CRV_CONTRACT_ADDRESS);
    const approveCount = Number(value) > 0 ? MAX_UINT_256.toFixed() : web3.utils.toWei('0');

    yield contract
      .methods
      .approve(CVX_CRV_STRATEGY_CONTRACT_ADDRESS, approveCount)
      .send({
        from: address,
      });

    yield put(crvConvertingGetAllowanceAction());
    setNotification({ type: 'success', message: 'Approving!' });
    yield put(crvConvertingSetStateAction({ stakingStatus: WriteContractStatus.APPROVED }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(crvConvertingSetStateAction({ stakingStatus: WriteContractStatus.APPROVED_ERROR }));
  }
}

export function* approveSaga() {
  yield takeLeading(
    CrvConvertingActionTypes.APPROVE,
    crvConvertingApproveSaga,
  );
}

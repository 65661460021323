import { put, takeEvery } from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import { RequestStatus, setNotification, web3 } from '@workstream/shared';
import {
  meSetStateAction,
  meGetBalanceEthAction,
  meSetStatusAction,
} from 'store/me/actions';
import { selectAddress } from 'store/utils';

function* meGetBalanceEthSaga({ type }: ReturnType<typeof meGetBalanceEthAction>) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const result: string = yield web3.eth.getBalance(address);
    yield put(meSetStateAction({
      eth: web3.utils.fromWei(result),
    }));
    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (err) {
    setNotification({ type: 'error', message: err.message });
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export default function* listener() {
  yield takeEvery(
    MeActionTypes.GET_BALANCE_ETH,
    meGetBalanceEthSaga,
  );
}

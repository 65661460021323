import {
  combineReducers, createStore, compose, applyMiddleware,
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './rootReducer';
import rootSaga from './rootSaga';
import { socketMiddleware } from './middleware';

const sagaMiddleware = createSagaMiddleware();

export default (initialState: Record<string, never> = {}) => {
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
    // eslint-disable-next-line no-underscore-dangle
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ ||
    compose;

  const store = createStore(
    combineReducers(reducer),
    initialState,
    composeEnhancers(
      applyMiddleware(socketMiddleware, sagaMiddleware),
    ),
  );

  sagaMiddleware.run(rootSaga);

  return { store };
};

import { xbeClaimUnstakeAction, xbeClaimGetBlockInfoAction, xbeClaimSetStatusAction } from 'store/xbeClaim/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { XbeClaimActionTypes } from 'store/xbeClaim/actionTypes';
import { getContract, RequestStatus, setNotification } from '@workstream/shared';
import { votingStakingRewardsAbi } from 'assets/abi';
import { VOTING_STAKING_REWARDS_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* xbeClaimUnstakeSaga(
  { type }: ReturnType<typeof xbeClaimUnstakeAction>,
) {
  try {
    yield put(xbeClaimSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const contract = getContract(votingStakingRewardsAbi, VOTING_STAKING_REWARDS_CONTRACT_ADDRESS);
    const address: string = yield selectAddress();

    yield contract
      .methods
      .withdrawBondedOrWithPenalty()
      .send({
        from: address,
      });

    yield put(xbeClaimGetBlockInfoAction());
    yield put(xbeClaimSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(xbeClaimSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* unstakeSaga() {
  yield takeLeading(
    XbeClaimActionTypes.UNSTAKE,
    xbeClaimUnstakeSaga,
  );
}

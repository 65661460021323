import React, { ChangeEvent, FC } from 'react';
import cx from 'classnames';
import {
  Button, Input, SvgIcon, Text,
} from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  classNameInput?: string,
  classNameInputWrapper?: string,
  placeholder?: string,
  icon?: string,
  name: string,
  value?: string,
  error?: boolean,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  onMaxClick?: () => void,
  available?: string | JSX.Element,
  isShowAvailableLegend?: boolean,
};

const InputWithAvailable: FC<Props> = ({
  className,
  classNameInputWrapper,
  classNameInput,
  placeholder,
  icon,
  name,
  value,
  error = false,
  onChange,
  onMaxClick,
  available,
  isShowAvailableLegend = true,
}) => (
  <div className={cx(
    styles.container,
    icon && styles.isIcon,
    className,
  )}
  >
    { icon && (
      <SvgIcon
        className={styles.icon}
        icon={icon}
      />
    )}
    <div className={styles.inputAvailable}>
      <Input
        name={name}
        value={value}
        onChange={onChange}
        classNameWrapper={cx(styles.inputWrapper, classNameInputWrapper)}
        className={cx(styles.input, classNameInput)}
        placeholder={placeholder}
        type="number"
        error={error}
        onKeyPress={(event) => {
          if (['e', 'E', '-', '+'].includes(event.key)) {
            event.preventDefault();
          }
        }}
      />
      <Text
        className={styles.available}
        size="tinier"
        color="secondary"
      >
        {isShowAvailableLegend && 'Avail: '}{available}
      </Text>
    </div>
    <Button
      size="tiny"
      className={styles.buttonMax}
      onClick={onMaxClick}
    >
      Max
    </Button>
  </div>
);

export default InputWithAvailable;

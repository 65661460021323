import React, { FC, useEffect, useCallback } from 'react';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
import { StakeCurveLPRow, Collapse } from 'components';
import { useDispatch } from 'react-redux';
import { RequirementWalletProvider } from 'containers';
import {
  DefaultBlock, CoinXbe, Text, CoinCvx,
} from '@workstream/shared';
import {
  meSelector,
  sushiSwapLPStakingSelector,
  fraxStakingSelector,
  useShallowSelector,
  web3Selector,
  cvxCrvStakingSelector,
  xbeStakingSelector,
  xbeClaimSelector,
} from 'store/selectors';
import { formatValue } from 'utils';
import { CurveLpToken } from 'store/stakeCurveLp/constants';
import { meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import { fraxStakingGetBlockInfoAction } from 'store/fraxStaking/actions';
import { sushiSwapLPStakingGetBlockInfoAction } from 'store/sushiSwapLPStaking/actions';
import {
  cvxCrvStakingGetBlockInfoAction,
  cvxCrvStakingApproveAction,
  cvxCrvStakingDepositAction,
  cvxCrvStakingWithdrawAction,
} from 'store/cvxCrvStaking/actions';
import {
  xbeStakingGetBlockInfoAction,
  xbeStakingApproveAction,
  xbeStakingDepositAction,
  xbeStakingWithdrawAction,
} from 'store/xbeStaking/actions';
import { cvxStakingGetBlockInfoAction } from 'store/cvxStaking/actions';
import { CvxCrvStakingActionTypes } from 'store/cvxCrvStaking/actionTypes';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import { ImageStakeCurveLpTokens, IconQuestionMark } from 'assets/img';
import XbeEthPool from './XbeEthPool';
import styles from './styles.module.scss';
import { CurveStakeWrapper } from './CurveStakeWrapper';
import StakeWrapper from '../StakeOther/StakeWrapper';

type Props = {
  className?: string,
};

const DESCRIPTION = 'Please note that in order to use this page you will need to provide liquidity to the supported pools on Curve.fi.';

const NOTE = ': Please do not stake your Curve.fi LP tokens in the gauge, you will be required to stake them below on xbe.finance in order to earn rewards. ';

const CVX_CRV_DESCRIPTION = 'Deposit liquidity into the <a href="https://curve.fi/factory/22/deposit/" target="_blank" rel="noopener noreferrer">Curve cvxCRV pool</a> (without staking in the Curve gauge), and then stake your curve LP tokens tokens here to earn XBE, CVX and CRV.';

const CVX_CRV_NOTE = 'A total of 21% fees are deducted from rewards earned by your liquidity boosted by XBE and Convex. For a full breakdown on fees please visit <a href="https://docs.xbe.finance/" target="_blank" rel="noopener noreferrer">https://docs.xbe.finance/</a>.';

const CVX_CRV_UNSTAKE_DESCRIPTION = 'Please NOTE: This section will only unstake your curve.fi LP tokens from XBE.finance. Please\n' +
  'go directly to the curve.fi pools to withdraw your liquidity should you so desire.';

const XBE_DESCRIPTION = 'Stake your XBE here to earn protocol revenue. Once staked, you can lockup your XBE ' +
  'for voting power across the XBE DAO, and earn boosted rewards on protocol revenue';

const XBE_BEFORE_DESCRIPTION = '<strong>Locking XBE</strong> — If you wish to lockup your XBE, please visit ' +
  '<a href="https://alpha.app.xbe.finance/lockup" target="_blank" rel="noopener noreferrer">https://alpha.app.xbe.finance/lockup</a> for greater control';

const XBE_UNSTAKE_DESCRIPTION = 'Unstake your XBE here. NB: Locked and unbonding XBE cannot be unstaked here, please go\n' +
  'to <a href="https://app.xbe.finance/" target="_blank" rel="noopener noreferrer">https://app.xbe.finance/</a> for greater control.';

const StakeCurveLPTokens: FC<Props> = ({
  className,
}) => {
  const curveApys = useShallowSelector(meSelector.getProp('curveApys'));
  const {
    earned: fraxEarned, apr: fraxApr, tvl: fraxTvl, deposits: fraxDeposits,
  } = useShallowSelector(fraxStakingSelector.getState);
  const {
    earned: sushiEarned, apr: sushiApr, tvl: sushiTvl, deposits: sushiDeposits,
  } = useShallowSelector(sushiSwapLPStakingSelector.getState);

  const { cvxcrv: cvxcrvApy } = useShallowSelector(meSelector.getProp('curveApys'));
  const {
    earned: xbeEarned, /* apr: xbeApr, */ tvl: xbeTvl, deposits: xbeDeposits,
  } = useShallowSelector(xbeStakingSelector.getState);
  const {
    earned: cvxCrvEarned, apr: cvxCrvApr, tvl: cvxCrvTvl, deposits: cvxCrvDeposits,
  } = useShallowSelector(cvxCrvStakingSelector.getState);

  const {
    xbe: xbeBalance, cvxCrv: cvxCrvBalance, cvxCrvVault: cvxCrvVaultBalance,
  } = useShallowSelector(meSelector.getProp('balances'));

  const {
    apr: xbevApr,
    aprStart: xbeAprStart,
    // aprCur: xbeAprCur,
  } = useShallowSelector(xbeClaimSelector.getState);

  const {
    allowance: xbeAllowance,
    stakingStatus: xbeStakingStatus,
  } = useShallowSelector(xbeStakingSelector.getState);

  const {
    allowance: cvxCrvAllowance,
    stakingStatus: cvxCrvStakingStatus,
    listRewardsContract: cvxCrvListRewardsContract,
  } = useShallowSelector(cvxCrvStakingSelector.getState);

  const withdrawStatusXbe = useShallowSelector(
    xbeStakingSelector.getStatus(XbeStakingActionTypes.WITHDRAW),
  );

  const withdrawStatusCvxCrv = useShallowSelector(
    cvxCrvStakingSelector.getStatus(CvxCrvStakingActionTypes.WITHDRAW),
  );

  const bondedAndLockXbe = useShallowSelector(xbeStakingSelector.getProp('bondedAndLockedXbe'));
  const { votingStakingRewards } = useShallowSelector(meSelector.getProp('balances'));

  const maxValueXbe = new BigNumber(votingStakingRewards).minus(bondedAndLockXbe).toString();

  const metamaskAddress = useShallowSelector(web3Selector.getProp('address'));

  const dispatch = useDispatch();

  useEffect(() => {
    if (metamaskAddress) {
      dispatch(xbeStakingGetBlockInfoAction());
      dispatch(cvxStakingGetBlockInfoAction());
      dispatch(cvxCrvStakingGetBlockInfoAction());
      dispatch(meGetBalancesAction(BalancesToken.XBE));
      dispatch(meGetBalancesAction(BalancesToken.CVX));
      dispatch(meGetBalancesAction(BalancesToken.CVX_CRV));
      dispatch(meGetBalancesAction(BalancesToken.CVX_VAULT));
      dispatch(meGetBalancesAction(BalancesToken.CVX_CRV_VAULT));
    }
    dispatch(fraxStakingGetBlockInfoAction());
    dispatch(sushiSwapLPStakingGetBlockInfoAction());
  }, [dispatch, metamaskAddress]);

  const onApproveCvxCrv = useCallback((value: string) => {
    dispatch(cvxCrvStakingApproveAction(value));
  }, [dispatch]);

  const onStakeCvxCrv = useCallback((value: string) => {
    dispatch(cvxCrvStakingDepositAction(value));
  }, [dispatch]);

  const onUnStakeCvxCrv = useCallback((value: string) => {
    dispatch(cvxCrvStakingWithdrawAction(value));
  }, [dispatch]);

  const onApproveXbe = useCallback((value: string) => {
    dispatch(xbeStakingApproveAction(value));
  }, [dispatch]);

  const onStakeXbe = useCallback((value: string) => {
    dispatch(xbeStakingDepositAction(value));
  }, [dispatch]);

  const onUnStakeXbe = useCallback((value: string) => {
    dispatch(xbeStakingWithdrawAction(value));
  }, [dispatch]);

  return (
    <div className={cx(styles.container, className)}>

      <div className={styles.descriptionNote}>
        <Text
          className={styles.description}
          size="small"
        >
          {DESCRIPTION}
        </Text>
        <DefaultBlock
          theme="lightBlue"
          tag="div"
          className={styles.noteWrapper}
        >
          <Text
            className={styles.note}
            size="small"
          >
            <b>Note: </b>{NOTE}
          </Text>
        </DefaultBlock>
        <Collapse
          className={styles.collapse}
          icon={IconQuestionMark}
          title="How does it work?"
        >
          <img src={ImageStakeCurveLpTokens} alt="info" className={styles.imageInfo} />
        </Collapse>
      </div>

      <Text
        className={styles.stakeTitle}
        color="extra"
        bold
      >
        Stake CURVE LP Tokens
      </Text>
      <RequirementWalletProvider>

        <div className={styles.stakeCurveRows}>
          <StakeCurveLPRow
            className={styles.stakeRow}
            type="FRAX"
            earned={`$${formatValue(fraxEarned)}`}
            apr={`${fraxApr}%`}
            proj={curveApys.frax.proj}
            boost={curveApys.frax.crvBoost}
            tvl={`$${formatValue(fraxTvl)}`}
            myDeposit={`$${formatValue(fraxDeposits)}`}
            isHeaders
          >
            <CurveStakeWrapper token={CurveLpToken.FRAX} />
          </StakeCurveLPRow>

          <StakeCurveLPRow
            className={styles.stakeRow}
            type="cvxCRV"
            earned={`$${formatValue(cvxCrvEarned)}`}
            apr={`${cvxCrvApr}%`}
            proj={cvxcrvApy.proj}
            boost={cvxcrvApy.crvBoost}
            tvl={`$${formatValue(cvxCrvTvl)}`}
            myDeposit={`$${formatValue(cvxCrvDeposits)}`}
          >
            <StakeWrapper
              token="cvxCrv"
              stakeDescription={CVX_CRV_DESCRIPTION}
              unstakeDescription={CVX_CRV_UNSTAKE_DESCRIPTION}
              stakeNote={CVX_CRV_NOTE}
              iconInput={CoinCvx}
              balance={cvxCrvBalance}
              allowance={cvxCrvAllowance}
              onApprove={onApproveCvxCrv}
              onStake={onStakeCvxCrv}
              stakingStatus={cvxCrvStakingStatus}
              onUnStake={onUnStakeCvxCrv}
              maxValueUnStaked={cvxCrvVaultBalance}
              withdrawStatus={withdrawStatusCvxCrv}
              listRewardsContract={cvxCrvListRewardsContract}
              isShowStakeDepositCurveButton={false}
            />
          </StakeCurveLPRow>
        </div>
      </RequirementWalletProvider>

      <Text
        className={styles.stakeXBETitle}
        color="extra"
        bold
      >
        Stake XBE
      </Text>

      <RequirementWalletProvider>
        <div>
          <StakeCurveLPRow
            type="XBE"
            earned={`$${formatValue(xbeEarned)}`}
            // apr={`${xbeApr}%`}
            apr={`${xbeAprStart}-${xbevApr}%`}
            tvl={`$${formatValue(xbeTvl)}`}
            myDeposit={`$${formatValue(xbeDeposits)}`}
            isHeaders
          >
            <StakeWrapper
              token="xbe"
              iconInput={CoinXbe}
              stakeDescription={XBE_DESCRIPTION}
              stakeBeforeDescription={XBE_BEFORE_DESCRIPTION}
              unstakeDescription={XBE_UNSTAKE_DESCRIPTION}
              balance={xbeBalance}
              allowance={xbeAllowance}
              onApprove={onApproveXbe}
              onStake={onStakeXbe}
              stakingStatus={xbeStakingStatus}
              onUnStake={onUnStakeXbe}
              withdrawStatus={withdrawStatusXbe}
              maxValueUnStaked={maxValueXbe}
              isShowStakeTitle={false}
              isShowStakeDepositCurveButton={false}
            />
          </StakeCurveLPRow>
        </div>
      </RequirementWalletProvider>

      <Text
        className={styles.provideLiquidityTitle}
        color="extra"
        bold
      >
        Provide Liquidity
      </Text>

      <div className={styles.provideLiquidityRows}>
        <StakeCurveLPRow
          type="XBE/ETH"
          earned={`$${formatValue(sushiEarned)}`}
          apr={`${sushiApr}%`}
          tvl={`$${formatValue(sushiTvl)}`}
          myDeposit={`$${formatValue(sushiDeposits)}`}
          isHeaders
        >
          <XbeEthPool />
        </StakeCurveLPRow>
      </div>
    </div>
  );
};

export default StakeCurveLPTokens;

import React, { ChangeEvent, FC, useState } from 'react';
import cx from 'classnames';
import { RadioGroup } from '@workstream/shared';
import { Props as InfoListProps } from 'components/InfoList';
import styles from './styles.module.scss';
import PlStake from '../PlStake';
import Unstake from '../Unstake';
import Info from '../Info';

type Props = {
  className?: string,
  token: string,
  iconInput: string,
  stakeTokenOneIcon?: string,
  stakeTokenTwoIcon?: string,
  stakeTokenOne?: string,
  stakeTokenTwo?: string,
  stakeTokenOneAvailable?: string,
  stakeTokenTwoAvailable?: string,
  stakeDescription?: string,
  stakeNote?: string,
  stakeInputPairOneOnMaxClick?: () => void,
  stakeInputPairOneValue?: string,
  stakeInputPairOneError?: boolean,
  stakeInputPairOneOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  stakeInputPairTwoOnMaxClick?: () => void,
  stakeInputPairTwoValue?: string,
  stakeInputPairTwoError?: boolean,
  stakeInputPairTwoOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  stakeInputTokenOnMaxClick?: () => void,
  stakeInputTokenValue?: string,
  stakeInputTokenOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  stakeApprovePairButtonText?: string,
  stakeApprovePairButtonDisabled?: boolean,
  stakeApprovePairButtonOnClick?: () => void,
  stakeDepositPairButtonText?: string,
  stakeDepositPairButtonDisabled?: boolean,
  stakeDepositPairButtonOnClick?: () => void,
  stakeApproveTokenButtonText?: string,
  stakeApproveTokenButtonDisabled?: boolean,
  stakeApproveTokenButtonOnClick?: () => void,
  stakeDepositTokenButtonText?: string,
  stakeDepositTokenButtonDisabled?: boolean,
  stakeDepositTokenButtonOnClick?: () => void,
  unstakeDescription?: string,
  unstakeToken?: string,
  unstakeTokenAvailable?: string,
  unstakeInputOnMaxClick?: () => void,
  unstakeInputValue?: string,
  unstakeInputOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  unstakeButtonText?: string,
  unstakeButtonDisabled?: boolean,
  unstakeButtonOnClick?: () => void,
  infoList?: InfoListProps['list'],
};

const PlStakeWrapper: FC<Props> = ({
  className,
  iconInput,
  token,
  stakeTokenOneIcon,
  stakeTokenTwoIcon,
  stakeTokenOne,
  stakeTokenTwo,
  stakeTokenOneAvailable,
  stakeTokenTwoAvailable,
  stakeDescription,
  stakeNote,
  stakeInputPairOneOnMaxClick,
  stakeInputPairOneValue,
  stakeInputPairOneError,
  stakeInputPairOneOnChange,
  stakeInputPairTwoOnMaxClick,
  stakeInputPairTwoValue,
  stakeInputPairTwoError,
  stakeInputPairTwoOnChange,
  stakeInputTokenOnMaxClick,
  stakeInputTokenValue,
  stakeInputTokenOnChange,
  stakeApprovePairButtonText,
  stakeApprovePairButtonDisabled,
  stakeApprovePairButtonOnClick,
  stakeDepositPairButtonText,
  stakeDepositPairButtonDisabled,
  stakeDepositPairButtonOnClick,
  stakeApproveTokenButtonText,
  stakeApproveTokenButtonDisabled,
  stakeApproveTokenButtonOnClick,
  stakeDepositTokenButtonText,
  stakeDepositTokenButtonDisabled,
  stakeDepositTokenButtonOnClick,
  unstakeDescription,
  unstakeToken,
  unstakeTokenAvailable,
  unstakeInputOnMaxClick,
  unstakeInputValue,
  unstakeInputOnChange,
  unstakeButtonText,
  unstakeButtonDisabled,
  unstakeButtonOnClick,
  infoList,
}) => {
  const pages: { [ index: string]: JSX.Element } = {
    Stake: <PlStake
      name={`stakeCurve${token}`}
      tokenOneIcon={stakeTokenOneIcon}
      tokenTwoIcon={stakeTokenTwoIcon}
      tokenOne={stakeTokenOne}
      tokenTwo={stakeTokenTwo}
      tokenOneAvailable={stakeTokenOneAvailable}
      tokenTwoAvailable={stakeTokenTwoAvailable}
      description={stakeDescription}
      note={stakeNote}
      inputPairOneOnMaxClick={stakeInputPairOneOnMaxClick}
      inputPairOneValue={stakeInputPairOneValue}
      inputPairOneError={stakeInputPairOneError}
      inputPairOneOnChange={stakeInputPairOneOnChange}
      inputPairTwoOnMaxClick={stakeInputPairTwoOnMaxClick}
      inputPairTwoValue={stakeInputPairTwoValue}
      inputPairTwoError={stakeInputPairTwoError}
      inputPairTwoOnChange={stakeInputPairTwoOnChange}
      inputTokenOnMaxClick={stakeInputTokenOnMaxClick}
      inputTokenValue={stakeInputTokenValue}
      inputTokenOnChange={stakeInputTokenOnChange}
      approvePairButtonText={stakeApprovePairButtonText}
      approvePairButtonDisabled={stakeApprovePairButtonDisabled}
      approvePairButtonOnClick={stakeApprovePairButtonOnClick}
      depositPairButtonText={stakeDepositPairButtonText}
      depositPairButtonDisabled={stakeDepositPairButtonDisabled}
      depositPairButtonOnClick={stakeDepositPairButtonOnClick}
      approveTokenButtonText={stakeApproveTokenButtonText}
      approveTokenButtonDisabled={stakeApproveTokenButtonDisabled}
      approveTokenButtonOnClick={stakeApproveTokenButtonOnClick}
      depositTokenButtonText={stakeDepositTokenButtonText}
      depositTokenButtonDisabled={stakeDepositTokenButtonDisabled}
      depositTokenButtonOnClick={stakeDepositTokenButtonOnClick}
    />,
    Unstake: <Unstake
      icon={iconInput}
      name={`unstakeCurve${token}`}
      description={unstakeDescription}
      token={unstakeToken}
      tokenAvailable={unstakeTokenAvailable}
      inputOnMaxClick={unstakeInputOnMaxClick}
      inputValue={unstakeInputValue}
      inputOnChange={unstakeInputOnChange}
      buttonText={unstakeButtonText}
      buttonDisabled={unstakeButtonDisabled}
      buttonOnClick={unstakeButtonOnClick}
    />,
    Info: <Info list={infoList} />,
  };

  const [option, setOption] = useState(Object.keys(pages)[0]);

  return (
    <div className={cx(styles.container, className)}>
      <RadioGroup
        className={styles.radioGroup}
        nameGroup={`provideLiquidity${token}`}
        options={Object.keys(pages)}
        callback={setOption}
        theme="lightBlueMini"
      />

      {pages[option]}
    </div>
  );
};

export default PlStakeWrapper;

import React, { FC } from 'react';
import cx from 'classnames';
import {
  CoinCrv, CoinCvx, CoinXbe, SvgIcon, Text,
} from '@workstream/shared';
import { IconStatisticsDaily, IconStatisticsStaked } from 'assets/img';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  type: 'crv' | 'cvx' | 'cvxCRV' | 'xbe' | 'stakedAssets' | 'dailyYield',
  value: string,
};

const TYPES: Record<Props['type'], { icon: string, label: string, classLogo: string }> = {
  crv: {
    icon: CoinCrv,
    label: 'Total CRV Earned',
    classLogo: 'crv',
  },
  cvx: {
    icon: CoinCvx,
    label: 'Total CVX Earned',
    classLogo: 'cvx',
  },
  cvxCRV: {
    icon: CoinCvx,
    label: 'Total cvxCRV Earned',
    classLogo: 'cvxCRV',
  },
  xbe: {
    icon: CoinXbe,
    label: 'Total XBE Earned',
    classLogo: 'xbe',
  },
  stakedAssets: {
    icon: IconStatisticsStaked,
    label: 'Total Productive Assets (CRV, CVX, cvxCRV, XBE)',
    classLogo: 'stakedAssets',
  },
  dailyYield: {
    icon: IconStatisticsDaily,
    label: 'Daily yield (USD)',
    classLogo: 'dailyYield',
  },
};

const StatisticsTokenItem: FC<Props> = ({
  className,
  type,
  value,
}) => (
  <div className={cx(styles.container, className)}>
    <SvgIcon
      icon={TYPES[type].icon}
      className={cx(styles.logo, styles[TYPES[type].classLogo])}
    />
    <div className={styles.inner}>
      <Text
        color="extra"
        className={styles.value}
      >
        {value}
      </Text>
      <Text
        color="secondary"
        className={styles.label}
      >
        {TYPES[type].label}
      </Text>
    </div>
  </div>
);

export default StatisticsTokenItem;

import { HandlerFn } from 'store/types';
import { CvxCrvStakingActionTypes } from 'store/cvxCrvStaking/actionTypes';
import { CvxCrvStakingState } from 'store/cvxCrvStaking/types';
import { cvxCrvStakingSetStateAction, cvxCrvStakingSetStatusAction } from 'store/cvxCrvStaking/actions';

type CvxCrvStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<CvxCrvStakingState, ReturnType<F>>;

const setState: CvxCrvStakingActionFn<typeof cvxCrvStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: CvxCrvStakingActionFn<typeof cvxCrvStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const CVX_CRV_STAKING_HANDLERS = {
  [CvxCrvStakingActionTypes.SET_STATE]: setState,
  [CvxCrvStakingActionTypes.SET_STATUS]: setStatus,
};

import { ReferralsInfoActionTypes } from 'store/referrals/info/actionTypes';
import { ReferralsInfoState } from 'store/referrals/info/types';
import { SetStatusPayload } from 'store/types';

export const referralsInfoSetStateAction = (payload: Partial<ReferralsInfoState>) => ({
  type: ReferralsInfoActionTypes.SET_STATE,
  payload,
});

export const referralsInfoSetStatusAction = (
  payload: SetStatusPayload<ReferralsInfoActionTypes>,
) => ({
  type: ReferralsInfoActionTypes.SET_STATUS,
  payload,
});

export const referralsInfoGetStatisticsAction = () => ({
  type: ReferralsInfoActionTypes.GET_STATISTICS,
});

export const referralsInfoGetClaimableRewardsAction = () => ({
  type: ReferralsInfoActionTypes.GET_CLAIMABLE_REWARDS,
});

export const referralsInfoGetNetworkDepositAction = () => ({
  type: ReferralsInfoActionTypes.GET_NETWORK_DEPOSIT,
});

export const referralsInfoGetMembersReferralsAction = () => ({
  type: ReferralsInfoActionTypes.GET_MEMBERS_REFFERALS,
});

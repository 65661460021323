import React, { useEffect } from 'react';
import { Routes, Web3Provider, InitializeActionsProvider } from 'containers';
import { ToastProvider } from '@workstream/shared';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { wsConnectAction } from 'store/ws/actions';
import styles from './styles.module.scss';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(wsConnectAction());
  }, [dispatch]);

  return (
    <ToastProvider>
      <Web3Provider>
        <InitializeActionsProvider>
          <BrowserRouter>
            <div className={styles.container}>
              <Routes />
            </div>
          </BrowserRouter>
        </InitializeActionsProvider>
      </Web3Provider>
    </ToastProvider>
  );
};

export default App;

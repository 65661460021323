import {
  referralsMeGetDateRegisteredAction,
  referralsMeSetStateAction,
  referralsMeSetStatusAction,
} from 'store/referrals/me/actions';
import { RequestStatus, setNotification, formattersDate } from '@workstream/shared';
import { put, takeLeading } from 'redux-saga/effects';
import { ReferralsMeActionTypes } from 'store/referrals/me/actionTypes';
import { api } from 'utils';
import { selectAddress } from 'store/utils';
import { AxiosResponse } from 'axios';

type Result = {
  date: string,
};

function* referralsMeGetDateRegisteredSaga(
  { type }: ReturnType<typeof referralsMeGetDateRegisteredAction>,
) {
  try {
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    const result: AxiosResponse<Result> = yield api.get(`/users/user/date-registered?address=${address}`);
    const date = new Date(result.data.date);

    yield put(referralsMeSetStateAction({
      dateRegistered: formattersDate.format(date, 'dd MMMM ‘yy'),
    }));
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    if (!e.response || (e.response && e.response.status !== 400)) {
      setNotification({ type: 'error', message: e.message });
    }
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getDateRegisteredSaga() {
  yield takeLeading(
    ReferralsMeActionTypes.GET_DATE_REGISTERED,
    referralsMeGetDateRegisteredSaga,
  );
}

import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  number: string,
};
const NumberInSquare: FC<Props> = ({
  className,
  number,
}) => (
  <div className={cx(styles.container, className)}>
    {number}
  </div>
);

export default NumberInSquare;

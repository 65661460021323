import createReducer from 'store/createReducer';
import { CvxCrvStakingState } from 'store/cvxCrvStaking/types';
import { CVX_CRV_STAKING_HANDLERS } from 'store/cvxCrvStaking/handlers';
import { RequestStatus, WriteContractStatus } from '@workstream/shared';
import { CvxCrvStakingActionTypes } from 'store/cvxCrvStaking/actionTypes';

const initialState: CvxCrvStakingState = {
  allowance: '0',
  totalSupply: '0',
  stakingStatus: WriteContractStatus.INIT,
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  listRewardsContract: [],
  ui: {
    [CvxCrvStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
    [CvxCrvStakingActionTypes.GET_ALLOWANCE]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, CVX_CRV_STAKING_HANDLERS);

import { put, takeLeading } from 'redux-saga/effects';
import { CvxStakingActionTypes } from 'store/cvxStaking/actionTypes';
import {
  getContract, setNotification,
} from '@workstream/shared';
import { cvxAbi } from 'assets/abi';
import {
  CVX_VAULT_CONTRACT_ADDRESS,
  CVX_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { cvxStakingSetStateAction } from 'store/cvxStaking/actions';
import { selectAddress } from 'store/utils';

function* cvxStakingGetAllowanceSaga() {
  try {
    const address: string = yield selectAddress();
    const contract = getContract(cvxAbi, CVX_CONTRACT_ADDRESS);
    const allowance: string = yield contract
      .methods
      .allowance(address, CVX_VAULT_CONTRACT_ADDRESS)
      .call();

    yield put(cvxStakingSetStateAction({ allowance }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
  }
}

export function* getAllowanceSaga() {
  yield takeLeading(
    CvxStakingActionTypes.GET_ALLOWANCE,
    cvxStakingGetAllowanceSaga,
  );
}

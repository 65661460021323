export enum XbeClaimActionTypes {
  SET_STATE = 'XBE_CLAIM.SET_STATE',

  SET_STATUS = 'XBE_CLAIM.SET_STATUS',

  GET_BLOCK_INFO = 'XBE_CLAIM.GET_BLOCK_INFO',

  CLAIM = 'XBE_CLAIM.CLAIM',

  UNBOND = 'XBE_CLAIM.UNBOND',

  UNSTAKE = 'XBE_CLAIM.UNSTAKE',
}

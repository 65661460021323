import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { api } from 'utils';
import { AxiosResponse } from 'axios';
import {
  lockupInfoGetBlockInfoAction,
  lockupInfoSetStateAction,
  lockupInfoSetStatusAction,
} from 'store/lockupInfo/actions';
import { LockupInfoActionTypes } from 'store/lockupInfo/actionTypes';
import { RequestStatus, setNotification } from '@workstream/shared';
import { GetXbeLockupResult } from 'store/lockupInfo/types';
import { selectAddress } from 'store/utils';

function* lockupInfoGetBlockInfoSaga(
  { type }: ReturnType<typeof lockupInfoGetBlockInfoAction>,
) {
  try {
    yield put(lockupInfoSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    const { data }: AxiosResponse<GetXbeLockupResult> = yield api.get(
      `/statistics/xbe/lockup?address=${address}`,
    );

    yield put(lockupInfoSetStateAction({
      dailyApy: new BigNumber(data.daily_apy).toFixed(2),
      apyPercentage: new BigNumber(data.apy).toFixed(2),
      currentCirculatingSupply: data.circulating_supply.toString(),
      totalLockedXBE: data.total_locked_xbe.toString(),
      percentageTotalLockedXBE: new BigNumber(data.total_locked_xbe_share).toFixed(2),
      maxSupply: data.total_circulating_supply.toString(),
    }));

    yield put(lockupInfoSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(lockupInfoSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    LockupInfoActionTypes.GET_BLOCK_INFO,
    lockupInfoGetBlockInfoSaga,
  );
}

export enum CvxCrvStakingActionTypes {
  SET_STATE = 'CVX_CRV_STAKING.SET_STATE',

  SET_STATUS = 'CVX_CRV_STAKING.SET_STATUS',

  GET_BLOCK_INFO = 'CVX_CRV_STAKING.GET_BLOCK_INFO',

  GET_ALLOWANCE = 'CVX_CRV_STAKING.GET_ALLOWANCE',

  APPROVE = 'CVX_CRV_STAKING.APPROVE',

  DEPOSIT = 'CVX_CRV_STAKING.DEPOSIT',

  WITHDRAW = 'CVX_CRV_STAKING.WITHDRAW',
}

import { fork } from 'redux-saga/effects';
import { getBlockInfoSaga } from './getBlockInfo';
import { getAllowanceSaga } from './getAllowance';
import { approveSaga } from './approve';
import { convertAndStakeSaga } from './convertAndStake';
import { withdrawSaga } from './withdraw';
import { convertSaga } from './convert';

export default [
  fork(getBlockInfoSaga),
  fork(getAllowanceSaga),
  fork(approveSaga),
  fork(convertAndStakeSaga),
  fork(withdrawSaga),
  fork(convertSaga),
];

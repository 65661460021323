import { put, takeLatest } from 'redux-saga/effects';
import { RequestStatus, getContract, setNotification } from '@workstream/shared';
import {
  minterSetStatusAction,
  minterClaimAction,
  minterGetStatsAction,
} from 'store/minter/actions';
import { MinterActionTypes } from 'store/minter/actionTypes';
import { votingStakingRewardsAbi } from 'assets/abi';
import { VOTING_STAKING_REWARDS_CONTRACT_ADDRESS } from 'appConstants';
import { selectAddress } from 'store/utils';
import { Contract } from 'web3-eth-contract';

function* minterClaimSaga({ type }: ReturnType<typeof minterClaimAction>) {
  try {
    yield put(minterSetStatusAction({
      type,
      status: RequestStatus.REQUEST,
    }));
    const address: string = yield selectAddress();
    const votingStakingRewardsContract: Contract = getContract(
      votingStakingRewardsAbi,
      VOTING_STAKING_REWARDS_CONTRACT_ADDRESS,
    );

    yield votingStakingRewardsContract
      .methods
      .getReward()
      .send({
        from: address,
      });

    yield put(minterSetStatusAction({
      type,
      status: RequestStatus.SUCCESS,
    }));

    setNotification({
      type: 'success',
      message: 'Claim has been successful',
    });

    yield put(minterGetStatsAction());
  } catch (err) {
    yield put(minterSetStatusAction({
      type,
      status: RequestStatus.ERROR,
    }));
    setNotification({
      type: 'error',
      message: err.message,
    });
  }
}

export default function* listener() {
  yield takeLatest(
    MinterActionTypes.CLAIM,
    minterClaimSaga,
  );
}

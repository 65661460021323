import React, { FC, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { ReferralInfoItem } from 'components';
import { referralsMeSelector, useShallowSelector, referralsInfoSelector } from 'store/selectors';
import { referralsMeGetDateRegisteredAction } from 'store/referrals/me/actions';
import { routes } from 'appConstants';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const ReferralInfo: FC<Props> = ({
  className,
}) => {
  const { referralCode, dateRegistered } = useShallowSelector(referralsMeSelector.getState);
  const { referralHits } = useShallowSelector(referralsInfoSelector.getState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(referralsMeGetDateRegisteredAction());
  }, [dispatch]);

  const linkReferral = useMemo(() => `${window.location.origin}${routes.referrals.root}?code=${referralCode}`,
    [referralCode]);

  return (
    <div className={cx(styles.container, className)}>
      <ReferralInfoItem
        type="code"
        title="My Referral Code"
        value={referralCode}
        isValueCopy
        className={styles.referralItem}
      />
      <ReferralInfoItem
        type="link"
        title="My Referral Link"
        value={linkReferral}
        isValueCopy
        className={styles.referralItem}
      />
      <ReferralInfoItem
        type="dateRegister"
        title="Date Registered"
        value={dateRegistered}
        className={styles.referralItem}
      />
      <ReferralInfoItem
        type="hits"
        title="My Referral Hits"
        value={`${referralHits} visits`}
        className={styles.referralItem}
      />
    </div>
  );
};

export default ReferralInfo;

import { RateState } from 'store/rate/types';
import { State } from 'store/types';

export default {
  getProp: <T extends keyof RateState>(propKey: T) => (state: State) => state.rate[propKey],
  getRateProp: <
    T extends keyof RateState,
    U extends keyof RateState[T],
  >(propKey: T, propKeyRate: U) => (state: State) => state.rate[propKey][propKeyRate] || '',
  getState: (state: State) => state.rate,
  getStatus: <T extends keyof RateState['ui']>(propKey: T) => (state: State) => state.rate.ui[propKey],
};

import { RequestStatus } from '@workstream/shared';
import { FraxStakingActionTypes } from 'store/fraxStaking/actionTypes';
import { FraxStakingState } from 'store/fraxStaking/types';

export const fraxStakingSetStateAction = (payload: Partial<FraxStakingState>) => ({
  type: FraxStakingActionTypes.SET_STATE,
  payload,
});

export const fraxStakingSetStatusAction = (
  payload: { type: FraxStakingActionTypes, status: RequestStatus },
) => ({
  type: FraxStakingActionTypes.SET_STATUS,
  payload,
});

export const fraxStakingGetBlockInfoAction = () => ({
  type: FraxStakingActionTypes.GET_BLOCK_INFO,
});

import { put, takeEvery } from 'redux-saga/effects';
import { BoostActionTypes } from 'store/boost/actionTypes';
import {
  boostGetBlockDepositInfoAction,
  boostSetStateAction,
  boostSetStatusAction,
} from 'store/boost/actions';
import {
  getContract, RequestStatus, setNotification,
} from '@workstream/shared';
import { bonusCampaignAbi, veXbeAbi } from 'assets/abi';
import { BONUS_CAMPAIGN_CONTRACT_ADDRESS, VE_XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

type LockedResult = {
  0: string,
  1: string,
  amount: string,
  end: string,
};

function* boostGetBlockDepositInfoSaga(
  { type }: ReturnType<typeof boostGetBlockDepositInfoAction>,
) {
  try {
    yield put(boostSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contractVeXBE = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);
    const contractBonusCampaign = getContract(bonusCampaignAbi, BONUS_CAMPAIGN_CONTRACT_ADDRESS);

    const lockedResult: LockedResult = yield contractVeXBE
      .methods
      .locked(address)
      .call();

    const campaignDateEnd: string = yield contractBonusCampaign
      .methods
      .periodFinish()
      .call();

    const meLockedDateEnd: string = yield contractVeXBE
      .methods
      .lockedEnd(address)
      .call();

    yield put(boostSetStateAction({
      myXBELocked: lockedResult.amount,
      campaignDateEnd: `${campaignDateEnd}000`,
      meLockedDateEnd: `${meLockedDateEnd}000`,
    }));
    yield put(boostSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(boostSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockDepositInfoSaga() {
  yield takeEvery(
    BoostActionTypes.GET_BLOCK_DEPOSIT_INFO,
    boostGetBlockDepositInfoSaga,
  );
}

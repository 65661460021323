import React, { useCallback, useEffect } from 'react';
import {
  Boost, Deposit, Liquidity, Minter, StakedXBE, StakingForms, Stats,
} from 'containers';
import {
  Button, H1, RequestStatus, Text, H2,
} from '@workstream/shared';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import {
  cvxCrvStakingWithdrawAction,
} from 'store/cvxCrvStaking/actions';
import {
  stakeCurveLpGetBalanceVaultAction, stakeCurveLpWithdrawAction,
} from 'store/stakeCurveLp/actions';
import { CurveLpToken } from 'store/stakeCurveLp/constants';
import { BalancesToken } from 'store/me/constants';
import { CvxCrvStakingActionTypes } from 'store/cvxCrvStaking/actionTypes';
import { getWithdrawAllBalanceAction, meWithdrawAllAction, meGetBalancesAction } from 'store/me/actions';
import {
  meSelector, useShallowSelector, web3Selector, cvxCrvStakingSelector, stakeCurveLpSelector,
} from 'store/selectors';
import { MeActionTypes } from 'store/me/actionTypes';
import styles from './styles.module.scss';

const TEXT_IF_YOU_STILL = 'If you still have XBE Sushi LP staked in v1 and you get a gas error when withdrawing using this button, please contact any of our Admins in Telegram for assistance';

const Lockup = () => {
  const withdrawAllStatus = useShallowSelector(meSelector.getStatus(MeActionTypes.WITHDRAW_ALL));
  const withdrawAllBalance = useShallowSelector(meSelector.getProp('withdrawAllBalance'));
  const metamaskAddress = useShallowSelector(web3Selector.getProp('address'));

  const {
    cvxCrvVaultOld: cvxCrvVaultBalance,
  } = useShallowSelector(meSelector.getProp('balances'));
  const withdrawStatusCvxCrv = useShallowSelector(
    cvxCrvStakingSelector.getStatus(CvxCrvStakingActionTypes.WITHDRAW),
  );
  const balanceVaultFrax = useShallowSelector(
    stakeCurveLpSelector.getBalanceVaultOld(CurveLpToken.FRAX),
  );
  const withdrawStatusFrax = useShallowSelector(
    stakeCurveLpSelector.getWithdrawStatus(CurveLpToken.FRAX),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (metamaskAddress) {
      dispatch(getWithdrawAllBalanceAction());
      dispatch(stakeCurveLpGetBalanceVaultAction(CurveLpToken.FRAX, true));
      dispatch(meGetBalancesAction(BalancesToken.CVX_CRV_VAULT_OLD));
    }
  }, [dispatch, metamaskAddress]);

  const onWithdrawAllClick = useCallback(() => {
    dispatch(meWithdrawAllAction());
  }, [dispatch]);

  const onUnStakeCvxCrv = useCallback(() => {
    dispatch(cvxCrvStakingWithdrawAction(cvxCrvVaultBalance, true));
  }, [dispatch, cvxCrvVaultBalance]);

  const unstakeFrax = useCallback(() => {
    dispatch(stakeCurveLpWithdrawAction({
      token: CurveLpToken.FRAX,
      value: balanceVaultFrax,
      isOld: true,
    }));
  }, [dispatch, balanceVaultFrax]);

  return (
    <div>
      <div className={cx('container', styles.container)}>
        <H1 className={styles.title}>STAKE & LOCKUP XBE</H1>
        <Text
          className={styles.description}
          color="secondary"
        >
          {
            // eslint-disable-next-line max-len
            'Lockup your XBE for a maximum period of 23 months starting on 6th September 2021 for an additional bonus of 5,000 XBE - distributed daily, claimable from month 18 of the lockup period. By locking up your tokens for the bonus, you will also have maximum boost on the XBE staking contract allowing for maximum yield from the XBE Hive for the entire term. Don’t forget to check out our FAQs for more info'
          }
        </Text>
        <Text
          className={styles.moreInfo}
          color="secondary"
          dangerouslySetInnerHTML={{ __html: 'For more information on the XBE hive please visit <a href="https://docs.xbe.finance/" target="_blank" rel="noopener noreferrer">docs.xbe.finance</a>' }}
        />
      </div>
      <div className={styles.containerWithdraw}>
        <H2 className={styles.titleWithdraw}>Withdraw from v2 Vaults</H2>
        <div className={styles.buttons}>
          <Button
            onClick={unstakeFrax}
            disabled={
              withdrawStatusFrax === RequestStatus.REQUEST ||
              balanceVaultFrax === '0'
            }
            size="sm"
            className={styles.withdrawButton}
          >
            Withdraw from Frax v2
          </Button>
          <Button
            type="button"
            onClick={onUnStakeCvxCrv}
            disabled={
              withdrawStatusCvxCrv === RequestStatus.REQUEST ||
              cvxCrvVaultBalance === '0'
            }
            color="blue"
            size="sm"
            isOutline
            className={cx(styles.withdrawButton, styles.withdrawCvxCrv)}
          >
            Withdraw from cvxCRV v2
          </Button>
        </div>
      </div>
      <Stats />
      <Text
        className={cx('container', styles.guideText)}
        color="secondary"
      >
        {'You can find a detailed guide on locking up and staking '}
        <a
          href="https://docs.xbe.finance/how-to-use-xbe.finance/xbe-hive/staking-xbe"
          className={styles.guideLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </Text>
      <StakingForms />
      <Liquidity />
      <div
        className={cx('container', styles.containerWithdrawAll)}
      >
        <Button
          onClick={onWithdrawAllClick}
          disabled={
            withdrawAllStatus === RequestStatus.REQUEST ||
            withdrawAllBalance === '0'
          }
        >
          Withdraw V1 LP
        </Button>
        <Text
          className={styles.textIfYouStill}
        >
          {TEXT_IF_YOU_STILL}
        </Text>
      </div>
      <StakedXBE
        className={styles.stakedXBE}
        withContainer
      />
      <Boost />
      <Deposit />
      <Minter />
    </div>
  );
};

export default Lockup;

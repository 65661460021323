import createReducer from 'store/createReducer';
import { StatisticsState } from 'store/statistics/types';
import { STATISTICS_HANDLERS } from 'store/statistics/handlers';
import { RequestStatus } from '@workstream/shared';
import { StatisticsActionTypes } from 'store/statistics/actionTypes';

const initialState: StatisticsState = {
  isExistStatistics: false,
  totalClaimable: '0',
  totalDeposits: '0',
  totalCombinedApy: '0',
  totalCrvEarned: '0',
  totalCvxEarned: '0',
  totalCvxCrvEarned: '0',
  totalXbeEarned: '0',
  stakedAssetsYield: '0',
  dailyYield: '0',
  ui: {
    [StatisticsActionTypes.GET_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, STATISTICS_HANDLERS);

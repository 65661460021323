import { put, takeLeading } from 'redux-saga/effects';
import {
  boostDepositAction,
  boostGetBlockDepositInfoAction, boostGetBlockLockupInfoAction, boostGetStartingVotingPowerAction,
  boostSetStateAction,
} from 'store/boost/actions';
import { BoostActionTypes } from 'store/boost/actionTypes';
import { meGetBoostLevelAction } from 'store/me/actions';
import {
  getContract, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { veXbeAbi } from 'assets/abi';
import { VE_XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { xbeLockupGetBlockInfoAction } from 'store/xbeLockup/actions';
import { xbeStakingGetBlockInfoAction } from 'store/xbeStaking/actions';
import { selectAddress } from 'store/utils';

function* boostDepositSaga(
  { payload: value }: ReturnType<typeof boostDepositAction>,
) {
  try {
    yield put(boostSetStateAction({ submitStatus: WriteContractStatus.WRITING }));
    const address: string = yield selectAddress();
    const contract = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);

    yield contract
      .methods
      .increaseAmount(web3.utils.toWei(value))
      .send({ from: address });

    yield put(xbeStakingGetBlockInfoAction());
    yield put(xbeLockupGetBlockInfoAction());
    yield put(boostGetBlockLockupInfoAction());
    yield put(boostGetStartingVotingPowerAction());
    yield put(boostGetBlockDepositInfoAction());
    yield put(meGetBoostLevelAction());
    setNotification({ type: 'success', message: 'Deposit success!' });
    yield put(boostSetStateAction({ submitStatus: WriteContractStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(boostSetStateAction({ submitStatus: WriteContractStatus.ERROR }));
  }
}

export function* depositSaga() {
  yield takeLeading(
    BoostActionTypes.DEPOSIT,
    boostDepositSaga,
  );
}

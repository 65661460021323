import { MetamaskStatus } from '@workstream/shared';
import createReducer from 'store/createReducer';
import { Web3State } from './types';
import { WEB3_HANDLERS } from './handlers';

const initialState: Web3State = {
  metamaskStatus: MetamaskStatus.NOT_AVAILABLE,
  address: '',
  chainId: '0x1',
};

export default createReducer(initialState, WEB3_HANDLERS);

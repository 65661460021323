import axios from 'axios';
import { API_BASE_URL } from 'appConstants';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.defaults.headers['Content-Type'] = 'application/json; charset=utf-8';

export default api;

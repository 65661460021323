export enum XbeStakingActionTypes {
  SET_STATE = 'XBE_STAKING.SET_STATE',

  SET_STATUS = 'XBE_STAKING.SET_STATUS',

  GET_BLOCK_INFO = 'XBE_STAKING.GET_BLOCK_INFO',

  GET_ALLOWANCE = 'XBE_STAKING.GET_ALLOWANCE',

  APPROVE = 'XBE_STAKING.APPROVE',

  DEPOSIT = 'XBE_STAKING.DEPOSIT',

  WITHDRAW = 'XBE_STAKING.WITHDRAW',
}

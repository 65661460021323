import React, { useMemo } from 'react';
import cx from 'classnames';
import { Icon } from "./..";
import styles from './styles.module.scss';
const Button = ({ color = 'blue', size = 'default', title, onClick = () => { }, icon, isOutline = false, className, classNameIcon, type = 'button', disabled = false, children, }) => {
    const renderButtonContent = useMemo(() => {
        if (icon) {
            return (React.createElement(React.Fragment, null,
                children,
                React.createElement(Icon, { icon: icon, className: cx(styles.icon, classNameIcon) })));
        }
        return children;
    }, [children, icon, classNameIcon]);
    return (React.createElement("button", { 
        /* eslint-disable-next-line react/button-has-type */
        type: type, disabled: disabled, title: title, className: cx(styles.button, styles[size], styles[color], isOutline && styles.outline, className), onClick: onClick }, renderButtonContent));
};
export default Button;

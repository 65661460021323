import { all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import web3Sagas from 'store/web3/sagas';
import provideLiquiditySagas from 'store/provideLiquidity/sagas';
import sushiSwapLPStaking from 'store/sushiSwapLPStaking/sagas';
import boostSagas from 'store/boost/sagas';
import meSagas from 'store/me/sagas';
import lockupInfoSagas from 'store/lockupInfo/sagas';
import minterSagas from 'store/minter/sagas';
import xbeLockupSagas from 'store/xbeLockup/sagas';
import xbeStakingSagas from 'store/xbeStaking/sagas';
import rateSagas from 'store/rate/sagas';
import claimSagas from 'store/claim/sagas';
import xbeClaimSagas from 'store/xbeClaim/sagas';
import referralsMeSagas from 'store/referrals/me/sagas';
import referralsInfoSagas from 'store/referrals/info/sagas';
import cvxStakingSagas from 'store/cvxStaking/sagas';
import cvxCrvStakingSagas from 'store/cvxCrvStaking/sagas';
import crvConvertingSagas from 'store/crvConverting/sagas';
import statisticsSagas from 'store/statistics/sagas';
import ironBankStakingSagas from 'store/ironBankStaking/sagas';
import eurtStakingSagas from 'store/eurtStaking/sagas';
import stEthStakingSagas from 'store/stEthStaking/sagas';
import fraxStakingSagas from 'store/fraxStaking/sagas';
import stakeCurveLpSagas from 'store/stakeCurveLp/sagas';

export default function* rootSaga(): SagaIterator {
  yield all([
    ...web3Sagas,
    ...provideLiquiditySagas,
    ...meSagas,
    ...sushiSwapLPStaking,
    ...lockupInfoSagas,
    ...minterSagas,
    ...boostSagas,
    ...xbeLockupSagas,
    ...xbeStakingSagas,
    ...rateSagas,
    ...claimSagas,
    ...referralsMeSagas,
    ...referralsInfoSagas,
    ...xbeClaimSagas,
    ...cvxStakingSagas,
    ...cvxCrvStakingSagas,
    ...crvConvertingSagas,
    ...statisticsSagas,
    ...ironBankStakingSagas,
    ...eurtStakingSagas,
    ...stEthStakingSagas,
    ...fraxStakingSagas,
    ...stakeCurveLpSagas,
  ]);
}

import React, { useEffect, useMemo } from 'react';
import { BlockWithList } from 'components';
import {
  BlockWrapper, DefaultBlock, Exchange, Medal, Text, IconWithOption,
} from '@workstream/shared';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { lockupInfoGetBlockInfoAction } from 'store/lockupInfo/actions';
import { lockupInfoSelector, rateSelector, useShallowSelector } from 'store/selectors';
import { RatesToken, RatesCurrencyToken } from 'store/rate/types';
import BigNumber from 'bignumber.js';
import styles from './styles.module.scss';

const Stats = () => {
  const {
    dailyApy, apyPercentage, currentCirculatingSupply, totalLockedXBE,
    percentageTotalLockedXBE, maxSupply,
  } = useShallowSelector(lockupInfoSelector.getState);
  const rateXbeUsd = useShallowSelector(
    rateSelector.getRateProp(RatesToken.XBE, RatesCurrencyToken.USD),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lockupInfoGetBlockInfoAction());
  }, [dispatch]);

  const list = useMemo(() => [
    {
      label: 'Daily APR (in %)',
      value: `${dailyApy}%`,
    },
    {
      label: 'APR (in %)',
      value: `${apyPercentage}%`,
    },
    {
      label: 'Current circulating supply',
      value: new BigNumber(currentCirculatingSupply).toFormat(3),
    },
    {
      label: 'Total locked in XBE',
      value: `${new BigNumber(totalLockedXBE).toFormat(3)} XBE`,
    },
    {
      label: '% of total locked XBE',
      value: `${percentageTotalLockedXBE}%`,
    },
    {
      label: 'Max supply',
      value: `${new BigNumber(maxSupply).toFormat(3)} XBE`,
    },
    {
      label: 'Total value locked in USD',
      value: `${new BigNumber(totalLockedXBE).multipliedBy(rateXbeUsd || '0').toFormat(2)} USD`,
    },
  ], [
    dailyApy, apyPercentage, currentCirculatingSupply, totalLockedXBE,
    percentageTotalLockedXBE, maxSupply, rateXbeUsd,
  ]);

  return (
    <BlockWrapper
      tag="section"
      className={styles.wrapper}
    >
      <div className={styles.blocks}>
        <BlockWithList
          className={styles.blockWithList}
          title="XBE Lockup"
          list={list}
          classNameHeader={styles.blockWithListHeader}
          classNameLabel={styles.blockWithListListLabels}
        />
        <div className={styles.bonusMaximumBlock}>
          <IconWithOption
            className={cx(styles.statsAdvantage, styles.starYellow)}
            descriptionClassName={styles.statsAdvantageDescription}
            icon={Medal}
            title="5000 XBE bonus campaign closed!"
            description="Users that locked XBE for max term before 5 Oct '21 earned their share of an extra 5000 XBE.  Distributed daily, claimable from month 18 to month 23 after 5 Oct '21."
          />
          <IconWithOption
            className={cx(styles.statsAdvantage, styles.starBlue)}
            descriptionClassName={styles.statsAdvantageDescription}
            icon={Exchange}
            title="Maximum Boost"
            description="Locking max term gives 100% boost on XBE staking and the best yield on protocol revenues.  As unlock date nears boost degrades, but extending lockup increases it again.  XBE locked before 5 Oct '21 generates fixed 100% boost until 5 Sep '23."
          />
          <DefaultBlock
            withContainer={false}
            tag="div"
            theme="darkGrey"
            className={styles.textBlock}
          >
            <Text size="small" className={styles.note}>
              {/* eslint-disable-next-line max-len */}
              Boost directly impacts your share of protocol revenue, locking up for a longer period allows for higher boost, and locking up for the maximum period allows for maximum boost. Please see our <a href="https://xbe.finance/faq/">FAQs</a> for more info.
            </Text>
          </DefaultBlock>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default Stats;

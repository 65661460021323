import createReducer from 'store/createReducer';
import { FraxStakingState } from 'store/fraxStaking/types';
import { FRAX_STAKING_HANDLERS } from 'store/fraxStaking/handlers';
import { RequestStatus } from '@workstream/shared';
import { FraxStakingActionTypes } from 'store/fraxStaking/actionTypes';

const initialState: FraxStakingState = {
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  ui: {
    [FraxStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, FRAX_STAKING_HANDLERS);

import { put, takeEvery } from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import {
  meBalancesTokenAbi,
  meBalancesTokenAddress,
  meBalancesTokenDecimals,
} from 'store/me/constants';
import {
  meBalancesSetStatusAction,
  meGetBalancesAction,
  meSetBalanceAction,
} from 'store/me/actions';
import {
  setNotification,
  web3,
  getContract,
  RequestStatus,
} from '@workstream/shared';
import { Contract } from 'web3-eth-contract';
import { selectAddress } from 'store/utils';

function* meGetBalancesSaga({ payload }: ReturnType<typeof meGetBalancesAction>) {
  try {
    yield put(meBalancesSetStatusAction({ [payload]: RequestStatus.REQUEST }));

    const userAddress: string = yield selectAddress();

    const contract: Contract = yield getContract(
      meBalancesTokenAbi[payload],
      meBalancesTokenAddress[payload],
    );
    const result: string = yield contract.methods.balanceOf(userAddress).call();

    yield put(meSetBalanceAction({
      [payload]: web3.utils.fromWei(result, meBalancesTokenDecimals[payload]),
    }));

    yield put(meBalancesSetStatusAction({ [payload]: RequestStatus.SUCCESS }));
  } catch (err) {
    setNotification({ type: 'error', message: err.message });
    yield put(meBalancesSetStatusAction({ [payload]: RequestStatus.ERROR }));
  }
}

export default function* listener() {
  yield takeEvery(
    MeActionTypes.GET_BALANCES,
    meGetBalancesSaga,
  );
}

import React, { ChangeEvent, FC } from 'react';
import cx from 'classnames';
import { InputWithAvailable, NumberInSquare } from 'components';
import {
  Button, DefaultBlock, Text,
} from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  name: string,
  description?: string,
  note?: string,
  tokenOne?: string,
  tokenTwo?: string,
  tokenOneAvailable?: string,
  tokenTwoAvailable?: string,
  tokenOneIcon?: string,
  tokenTwoIcon?: string,
  inputPairOneOnMaxClick?: () => void,
  inputPairOneValue?: string,
  inputPairOneError?: boolean,
  inputPairOneOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  inputPairTwoOnMaxClick?: () => void,
  inputPairTwoValue?: string,
  inputPairTwoError?: boolean,
  inputPairTwoOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  inputTokenOnMaxClick?: () => void,
  inputTokenValue?: string,
  inputTokenOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  approvePairButtonText?: string,
  approvePairButtonDisabled?: boolean,
  approvePairButtonOnClick?: () => void,
  depositPairButtonText?: string,
  depositPairButtonDisabled?: boolean,
  depositPairButtonOnClick?: () => void,
  approveTokenButtonText?: string,
  approveTokenButtonDisabled?: boolean,
  approveTokenButtonOnClick?: () => void,
  depositTokenButtonText?: string,
  depositTokenButtonDisabled?: boolean,
  depositTokenButtonOnClick?: () => void,
};

const PlStake: FC<Props> = ({
  className,
  name,
  description,
  note,
  tokenOne = '',
  tokenTwo = '',
  tokenOneAvailable = '0',
  tokenTwoAvailable = '0',
  tokenOneIcon,
  tokenTwoIcon,
  inputPairOneOnMaxClick = () => {},
  inputPairOneValue = '',
  inputPairOneError = false,
  inputPairOneOnChange = () => {},
  inputPairTwoOnMaxClick = () => {},
  inputPairTwoValue = '',
  inputPairTwoError = false,
  inputPairTwoOnChange = () => {},
  inputTokenOnMaxClick = () => {},
  inputTokenValue = '',
  inputTokenOnChange = () => {},
  approvePairButtonText = 'Approve',
  approvePairButtonDisabled = false,
  approvePairButtonOnClick = () => {},
  depositPairButtonText = 'Deposit',
  depositPairButtonDisabled = false,
  depositPairButtonOnClick = () => {},
  approveTokenButtonText = 'Approve',
  approveTokenButtonDisabled,
  approveTokenButtonOnClick,
  depositTokenButtonText = 'Provide LP using ETH only',
  depositTokenButtonDisabled = false,
  depositTokenButtonOnClick = () => {},
}) => (
  <div className={cx(styles.container, className)}>
    { description && (
      <Text
        className={styles.text}
        color="secondary"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
    { note && (
      <DefaultBlock
        theme="grey"
        className={styles.noteWrapper}
      >
        <Text
          className={styles.text}
          color="primary"
        >
          Note
        </Text>
        <Text
          className={styles.textNote}
          dangerouslySetInnerHTML={{ __html: note }}
        />
      </DefaultBlock>
    )}

    <div className={styles.inner}>
      <div className={styles.left}>
        <InputWithAvailable
          className={styles.inputFirst}
          icon={tokenOneIcon}
          name={`${name}-inputFirst`}
          placeholder="0.00"
          isShowAvailableLegend={false}
          available={<span><b>{`${tokenOneAvailable} ${tokenOne}`}</b>  tokens available</span>}
          onMaxClick={inputPairOneOnMaxClick}
          value={inputPairOneValue}
          onChange={inputPairOneOnChange}
          error={inputPairOneError}
        />
        <InputWithAvailable
          className={styles.inputSecond}
          icon={tokenTwoIcon}
          name={`${name}-inputSecond`}
          placeholder="0.00"
          isShowAvailableLegend={false}
          available={<span><b>{`${tokenTwoAvailable} ${tokenTwo}`}</b>  tokens available</span>}
          onMaxClick={inputPairTwoOnMaxClick}
          value={inputPairTwoValue}
          onChange={inputPairTwoOnChange}
          error={inputPairTwoError}
        />
        <InputWithAvailable
          className={styles.inputThird}
          icon={tokenOneIcon}
          name={`${name}-inputThird`}
          placeholder="0.00"
          isShowAvailableLegend={false}
          available={<span><b>{`${tokenOneAvailable} ${tokenOne}`}</b>  tokens available</span>}
          onMaxClick={inputTokenOnMaxClick}
          value={inputTokenValue}
          onChange={inputTokenOnChange}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.buttons}>
          <Button
            className={cx(styles.button, styles.buttonInRow)}
            isOutline
            size="sm"
            disabled={approvePairButtonDisabled}
            onClick={approvePairButtonOnClick}
          >
            <NumberInSquare className={styles.numberInSquare} number="1" />
            {approvePairButtonText}
          </Button>
          <Button
            className={cx(styles.button, styles.buttonInRow)}
            isOutline
            size="sm"
            disabled={depositPairButtonDisabled}
            onClick={depositPairButtonOnClick}
          >
            <NumberInSquare className={styles.numberInSquare} number="2" />
            {depositPairButtonText}
          </Button>
        </div>

        <div className={styles.buttons}>
          {approveTokenButtonOnClick && (
            <Button
              className={cx(styles.button, styles.buttonInRow)}
              isOutline
              size="sm"
              disabled={approveTokenButtonDisabled}
              onClick={approveTokenButtonOnClick}
            >
              <NumberInSquare className={styles.numberInSquare} number="1" />
              {approveTokenButtonText}
            </Button>
          )}
          <Button
            className={cx(styles.button, styles.buttonInRow)}
            isOutline
            size="sm"
            disabled={depositTokenButtonDisabled}
            onClick={depositTokenButtonOnClick}
          >
            {approveTokenButtonOnClick && (
              <NumberInSquare className={styles.numberInSquare} number="2" />
            )}
            {depositTokenButtonText}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default PlStake;

import React from 'react';
import RModal from 'react-modal';
import cx from 'classnames';
import { SvgIcon } from "./..";
import { IconClose } from "../../assets/img";
import styles from './styles.module.scss';
const Modal = ({ className, overlayClassName, isOpen, onClose, children, }) => (React.createElement(RModal, { isOpen: isOpen, appElement: document.getElementById('root') || document.body, className: cx(styles.modal, className), shouldCloseOnEsc: true, shouldCloseOnOverlayClick: true, overlayClassName: cx(styles.overlay, overlayClassName), htmlOpenClassName: "modal-open", onRequestClose: onClose },
    React.createElement("button", { type: "button", className: styles.buttonClose, onClick: onClose },
        React.createElement(SvgIcon, { icon: IconClose })),
    children));
export default Modal;

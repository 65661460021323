import { RequestStatus } from '@workstream/shared';
import { XbeClaimActionTypes } from 'store/xbeClaim/actionTypes';
import { XbeClaimState } from 'store/xbeClaim/types';

export const xbeClaimSetStateAction = (payload: Partial<XbeClaimState>) => ({
  type: XbeClaimActionTypes.SET_STATE,
  payload,
});

export const xbeClaimSetStatusAction = (
  payload: { type: XbeClaimActionTypes, status: RequestStatus },
) => ({
  type: XbeClaimActionTypes.SET_STATUS,
  payload,
});

export const xbeClaimGetBlockInfoAction = () => ({
  type: XbeClaimActionTypes.GET_BLOCK_INFO,
});

export const xbeClaimClaimAction = () => ({
  type: XbeClaimActionTypes.CLAIM,
});

export const xbeClaimUnbondAction = () => ({
  type: XbeClaimActionTypes.UNBOND,
});

export const xbeClaimUnstakeAction = () => ({
  type: XbeClaimActionTypes.UNSTAKE,
});

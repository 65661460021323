import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import {
  RequestStatus, setNotification,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';
import { StatisticsActionTypes } from 'store/statistics/actionTypes';
import { statisticsSetStateAction, statisticsSetStatusAction, statisticsGetInfoAction } from 'store/statistics/actions';
import { ResponseStatistics } from 'store/statistics/types';

function* statisticsGetInfoSaga(
  { type }: ReturnType<typeof statisticsGetInfoAction>,
) {
  try {
    yield put(statisticsSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const address: string = yield selectAddress();

    const { data }: AxiosResponse<ResponseStatistics> = yield api.get(`/statistics/get-statistics?address=${address}`);

    yield put(statisticsSetStateAction({
      totalClaimable: new BigNumber(data.totalClaimable).toFixed(2),
      totalDeposits: new BigNumber(data.totalDeposits).toFixed(2),
      totalCombinedApy: new BigNumber(data.totalCombinedApy).toFixed(2),
      totalCrvEarned: new BigNumber(data.totalCrvEarned).toFixed(2),
      totalCvxEarned: new BigNumber(data.totalCvxEarned).toFixed(2),
      totalCvxCrvEarned: new BigNumber(data.totalCvxCrvEarned).toFixed(2),
      totalXbeEarned: new BigNumber(data.totalXbeEarned).toFixed(2),
      stakedAssetsYield: new BigNumber(data.stakedAssetsYield).toFixed(2),
      dailyYield: new BigNumber(data.dailyYield).toFixed(2),
      isExistStatistics: true,
    }));

    yield put(statisticsSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    if (e.response && e.response.status === 404) {
      yield put(statisticsSetStateAction({
        isExistStatistics: false,
      }));
    } else {
      setNotification({ type: 'error', message: e.message });
    }
    yield put(statisticsSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getInfoSaga() {
  yield takeEvery(
    StatisticsActionTypes.GET_INFO,
    statisticsGetInfoSaga,
  );
}

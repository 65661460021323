import { HandlerFn } from 'store/types';
import { ClaimActionTypes } from 'store/claim/actionTypes';
import { ClaimState } from 'store/claim/types';
import { claimSetClaimStatusAction, claimSetStateAction, claimSetStatusAction } from 'store/claim/actions';

type ClaimActionFn<F extends (...args: any) => any> = HandlerFn<ClaimState, ReturnType<F>>;

const setState: ClaimActionFn<typeof claimSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: ClaimActionFn<typeof claimSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

const setClaimStatus: ClaimActionFn<typeof claimSetClaimStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  uiClaim: {
    ...state.uiClaim,
    ...payload,
  },
});

export const CLAIM_HANDLERS = {
  [ClaimActionTypes.SET_STATE]: setState,
  [ClaimActionTypes.SET_STATUS]: setStatus,
  [ClaimActionTypes.SET_CLAIM_STATUS]: setClaimStatus,
};

import { fork } from 'redux-saga/effects';
import { getBlockInfoSaga } from './getBlockInfo';
import { getAllowanceSaga } from './getAllowance';
import { approveSaga } from './approve';
import { depositSaga } from './deposit';
import { withdrawSaga } from './withdraw';

export default [
  fork(getBlockInfoSaga),
  fork(getAllowanceSaga),
  fork(approveSaga),
  fork(depositSaga),
  fork(withdrawSaga),
];

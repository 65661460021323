import React from 'react';
import cx from 'classnames';
import { Icon, Text } from "./..";
import styles from './styles.module.scss';
const icons = {
    success: 'checkmark',
    error: 'cross',
    info: 'info',
};
const Toastify = ({ type, title, message, className, }) => (React.createElement("div", { className: cx(styles.toastify, styles[type], className) },
    React.createElement(Icon, { icon: icons[type], className: styles.icon }),
    React.createElement("div", { className: styles.body },
        React.createElement(Text, { className: styles.title, bold: true },
            title || type,
            "!"),
        React.createElement(Text, { color: "primary", className: styles.message }, message))));
export default Toastify;

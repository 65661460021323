export enum ClaimActionTypes {
  SET_STATE = 'CLAIM.SET_STATE',

  SET_STATUS = 'CLAIM.SET_STATUS',

  GET_STAKE = 'CLAIM.GET_STAKE',

  SET_CLAIM_STATUS = 'CLAIM.SET_CLAIM_STATUS',
  CLAIM = 'CLAIM.CLAIM',

  CLAIM_SUSHI = 'CLAIM.CLAIM_SUSHI',

  CLAIM_CVX_CRV = 'CLAIM.CLAIM_CVX_CRV',

  CLAIM_CVX = 'CLAIM.CLAIM_CVX',

  CLAIM_REFERRAL = 'CLAIM.CLAIM_REFERRAL',
}

import { Middleware } from 'redux';
import { State } from 'store/types';
import { WsActionTypes } from 'store/ws/actionTypes';
import { wsDisconnectAction } from 'store/ws/actions';
import { WsEventEnum } from 'appConstants';
import { WsRegistrationUser } from 'store/referrals/me/types';
import { referralsMeSetStateAction } from 'store/referrals/me/actions';

type BaseEvent<T, M> = {
  type: T,
  message: M,
};

type MessageEvent = BaseEvent<WsEventEnum.REGISTER_WALLET, WsRegistrationUser>;

const SERVER_URL = process.env.REACT_APP_WS_URL as string;

const WS_PINGPONG_TIME = 30000;
const WS_PINGPONG_MSG = 'Ping Pong!';

const socketMiddleware: Middleware<
{}, State
> = ({ dispatch, getState }) => {
  let intervalID: NodeJS.Timeout;
  let ws: WebSocket;

  const pingPongInterval = () => setInterval(() => {
    if (ws && ws.readyState === 1) ws.send(WS_PINGPONG_MSG);
  }, WS_PINGPONG_TIME);

  const messageHandler = (data: MessageEvent) => {
    switch (data.type) {
      case WsEventEnum.REGISTER_WALLET:
        if (getState().web3.address === data.message.address) {
          dispatch(referralsMeSetStateAction({
            referralCode: data.message.referralCode,
          }));
        }
        break;
      default: break;
    }
  };

  return (next) => (action) => {
    switch (action.type) {
      case WsActionTypes.CONNECT:
        ws = new WebSocket(SERVER_URL);
        intervalID = pingPongInterval();

        ws.onmessage = (event) => {
          messageHandler(JSON.parse(event.data));
        };

        ws.onclose = () => {
          dispatch(wsDisconnectAction());
        };

        break;
      case WsActionTypes.DISCONNECT:
        clearInterval(intervalID);
        ws.close();
        break;
      default:
        next(action);
    }
  };
};

export default socketMiddleware;

import React, { ChangeEvent, FC } from 'react';
import cx from 'classnames';
import { InputWithAvailable, NumberInSquare } from 'components';
import { Button, DefaultBlock, Text } from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  icon: string,
  name: string,
  description?: string,
  depositCurveLink?: string,
  note?: string,
  token?: string,
  available?: string,
  onMaxClick?: () => void,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  value?: string,
  approveButtonText?: string,
  approveButtonDisabled?: boolean,
  approveButtonOnClick?: () => void,
  depositButtonText?: string,
  depositButtonDisabled?: boolean,
  depositButtonOnClick?: () => void,
};

const Stake: FC<Props> = ({
  className,
  icon,
  name,
  description = '',
  depositCurveLink,
  note = '',
  token = '',
  available = '',
  onMaxClick = () => {},
  onChange = () => {},
  value = '',
  approveButtonText = 'Approve',
  approveButtonDisabled = false,
  approveButtonOnClick = () => {},
  depositButtonText = 'Deposit & Stake',
  depositButtonDisabled = false,
  depositButtonOnClick = () => {},
}) => (
  <div className={cx(styles.container, className)}>
    <Text
      className={styles.text}
      color="secondary"
      dangerouslySetInnerHTML={{ __html: description }}
    />

    <div className={styles.inner}>
      <div className={styles.left}>
        <DefaultBlock
          theme="grey"
          className={styles.noteWrapper}
        >
          <Text
            className={styles.text}
            color="primary"
          >
            Note
          </Text>
          <Text
            className={styles.textNote}
            dangerouslySetInnerHTML={{ __html: note }}
          />
        </DefaultBlock>

        <InputWithAvailable
          className={styles.inputWithAvailableWrapper}
          classNameInput={styles.inputWithAvailable}
          icon={icon}
          name={`${name}-input`}
          placeholder="0.00"
          isShowAvailableLegend={false}
          available={<span><b>{`${available} ${token}`}</b>  tokens available</span>}
          onMaxClick={onMaxClick}
          onChange={onChange}
          value={value}
        />
      </div>
      <div className={styles.right}>
        {depositCurveLink && (
          <Button
            className={cx(styles.button, styles.buttonDeposit)}
            isOutline
            size="sm"
          >
            Deposit into Curve
          </Button>
        )}

        <div className={styles.buttons}>
          <Button
            className={cx(styles.button, styles.buttonInRow)}
            isOutline
            size="sm"
            disabled={approveButtonDisabled}
            onClick={approveButtonOnClick}
          >
            <NumberInSquare className={styles.numberInSquare} number="1" />
            {approveButtonText}
          </Button>
          <Button
            className={cx(styles.button, styles.buttonInRow)}
            isOutline
            size="sm"
            disabled={depositButtonDisabled}
            onClick={depositButtonOnClick}
          >
            <NumberInSquare className={styles.numberInSquare} number="2" />
            {depositButtonText}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default Stake;

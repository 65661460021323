import createReducer from 'store/createReducer';
import { CvxStakingState } from 'store/cvxStaking/types';
import { CVX_STAKING_HANDLERS } from 'store/cvxStaking/handlers';
import { RequestStatus, WriteContractStatus } from '@workstream/shared';
import { CvxStakingActionTypes } from 'store/cvxStaking/actionTypes';

const initialState: CvxStakingState = {
  allowance: '0',
  totalSupply: '0',
  stakingStatus: WriteContractStatus.INIT,
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  listRewardsContract: [],
  ui: {
    [CvxStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
    [CvxStakingActionTypes.GET_ALLOWANCE]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, CVX_STAKING_HANDLERS);

import { RequestStatus } from '@workstream/shared';
import { StEthStakingActionTypes } from 'store/stEthStaking/actionTypes';
import { StEthStakingState } from 'store/stEthStaking/types';

export const stEthStakingSetStateAction = (payload: Partial<StEthStakingState>) => ({
  type: StEthStakingActionTypes.SET_STATE,
  payload,
});

export const stEthStakingSetStatusAction = (
  payload: { type: StEthStakingActionTypes, status: RequestStatus },
) => ({
  type: StEthStakingActionTypes.SET_STATUS,
  payload,
});

export const stEthStakingGetBlockInfoAction = () => ({
  type: StEthStakingActionTypes.GET_BLOCK_INFO,
});

import createReducer from 'store/createReducer';
import { IronBankStakingState } from 'store/ironBankStaking/types';
import { IRON_BANK_STAKING_HANDLERS } from 'store/ironBankStaking/handlers';
import { RequestStatus } from '@workstream/shared';
import { IronBankStakingActionTypes } from 'store/ironBankStaking/actionTypes';

const initialState: IronBankStakingState = {
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  ui: {
    [IronBankStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, IRON_BANK_STAKING_HANDLERS);

import React, { FC, useEffect, useMemo } from 'react';
import cx from 'classnames';
import {
  Button, web3, WriteContractStatus, Text,
} from '@workstream/shared';
import { InputWithMaxButton } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BigNumber from 'bignumber.js';
import { meSelector, useShallowSelector, xbeStakingSelector } from 'store/selectors';
import { useDispatch } from 'react-redux';
import { xbeStakingApproveAction, xbeStakingDepositAction } from 'store/xbeStaking/actions';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const initialValues = {
  deposit: '',
};

Yup.addMethod(Yup.string, 'deposit', function (
  errorMessage: string,
  maxDeposit: string,
  allowance: string,
) {
  return this.test('test-value', errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      const valueBN = new BigNumber(value);

      const conditions: boolean[] = [
        valueBN.isGreaterThan(0),
        valueBN.isLessThanOrEqualTo(maxDeposit),
        valueBN.isLessThanOrEqualTo(web3.utils.fromWei(allowance)),
      ];

      if (conditions.includes(false)) {
        return createError({
          path,
          message: errorMessage,
        });
      }
      return true;
    }

    return false;
  });
});

const Stake: FC<Props> = ({
  className,
}) => {
  const { xbe: xbeBalance } = useShallowSelector(meSelector.getProp('balances'));
  const { allowance, stakingStatus } = useShallowSelector(xbeStakingSelector.getState);

  const dispatch = useDispatch();

  const {
    values,
    setValues,
    isValid,
    handleSubmit,
    validateField,
  } = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      deposit: Yup.string()
      // @ts-ignore
        .deposit('Error', xbeBalance, allowance)
        .required(),
    }),
    onSubmit: ({ deposit }) => {
      dispatch(xbeStakingDepositAction(deposit));
    },
  });

  useEffect(() => {
    validateField('deposit');
  }, [allowance, validateField]);

  const onApproveClick = () => {
    if (!values.deposit || values.deposit === '0') {
      dispatch(xbeStakingApproveAction('0'));
    } else {
      dispatch(xbeStakingApproveAction(values.deposit));
    }
  };

  const disabledApprove = useMemo(() => {
    const valueBN = new BigNumber(values.deposit);
    if (valueBN.isEqualTo(0)) return false;
    return values.deposit.length === 0 ||
      [
        valueBN.isLessThanOrEqualTo(web3.utils.fromWei(allowance)),
      ].includes(true) ||
      stakingStatus === WriteContractStatus.APPROVING ||
      stakingStatus === WriteContractStatus.WRITING;
  }, [allowance, stakingStatus, values.deposit]);

  return (
    <form
      className={cx(styles.container, className)}
      onSubmit={handleSubmit}
    >
      <Text
        color="secondary"
        size="tiny"
        className={styles.label}
      >
        Stake
      </Text>
      <InputWithMaxButton
        value={values.deposit}
        onChange={(event) => setValues({ deposit: event.target.value })}
        onClick={() => setValues({ deposit: xbeBalance })}
        className={styles.inputWrapper}
        placeholder="0.00 XBE"
      />
      <Button
        size="mini"
        className={styles.buttonApprove}
        onClick={onApproveClick}
        disabled={disabledApprove}
      >
        { stakingStatus === WriteContractStatus.APPROVING ? 'Approving...' : 'Approve' }
      </Button>
      <Button
        type="submit"
        isOutline
        size="mini"
        className={styles.buttonDeposit}
        disabled={[
          !isValid,
          stakingStatus === WriteContractStatus.APPROVING,
          stakingStatus === WriteContractStatus.WRITING,
        ].includes(true)}
      >
        { stakingStatus === WriteContractStatus.WRITING ? 'Processing...' : 'Stake' }
      </Button>
    </form>
  );
};

export default Stake;

import createReducer from 'store/createReducer';
import { ReferralsMeState } from 'store/referrals/me/types';
import { REFERRALS_ME_HANDLERS } from 'store/referrals/me/handlers';
import { ReferralsMeActionTypes } from 'store/referrals/me/actionTypes';
import { RequestStatus } from '@workstream/shared';

const initialState: ReferralsMeState = {
  referralCode: '',
  urlReferralCode: '',
  dateRegistered: '',
  ui: {
    [ReferralsMeActionTypes.REGISTER_WALLET]: RequestStatus.INIT,
    [ReferralsMeActionTypes.GET_REFERRAL_CODE]: RequestStatus.INIT,
    [ReferralsMeActionTypes.INC_REFERRAL_HIT]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, REFERRALS_ME_HANDLERS);

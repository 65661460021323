import React, { FC, useState } from 'react';
import {
  Modal, H3, Text, Checkbox, Button,
} from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onProceedClick: () => void,
};

export const ModalUnstakePenalty: FC<Props> = ({
  isOpen,
  onClose,
  onProceedClick,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <H3
        color="primary"
      >
        Confirm Withdrawal
      </H3>
      <Text
        className={styles.textWithdrawing}
        size="big"
        color="secondary"
      >
        Withdrawing before unbonding has completed will<br />
        <strong>deduct 50% of your rewards as penalty.</strong>
      </Text>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          name="instakeAt50%"
          theme="bigGreen"
          label="I accept the early withdrawal penalty"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
      </div>
      <Button
        disabled={!isChecked}
        onClick={onProceedClick}
        size="sm"
        className={styles.buttonProceed}
      >
        Proceed with early withdrawal
      </Button>
    </Modal>
  );
};

import createReducer from 'store/createReducer';
import { EurtStakingState } from 'store/eurtStaking/types';
import { EURT_STAKING_HANDLERS } from 'store/eurtStaking/handlers';
import { RequestStatus } from '@workstream/shared';
import { EurtStakingActionTypes } from 'store/eurtStaking/actionTypes';

const initialState: EurtStakingState = {
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  ui: {
    [EurtStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, EURT_STAKING_HANDLERS);

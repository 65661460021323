import { HandlerFn } from 'store/types';
import { ReferralsMeActionTypes } from 'store/referrals/me/actionTypes';
import { ReferralsMeState } from 'store/referrals/me/types';
import {
  referralsMeSetStatusAction,
  referralsMeSetStateAction,
} from 'store/referrals/me/actions';

type ReferralsMeActionFn<F extends (...args: any) => any> =
  HandlerFn<ReferralsMeState, ReturnType<F>>;

const setState: ReferralsMeActionFn<typeof referralsMeSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: ReferralsMeActionFn<typeof referralsMeSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const REFERRALS_ME_HANDLERS = {
  [ReferralsMeActionTypes.SET_STATE]: setState,
  [ReferralsMeActionTypes.SET_STATUS]: setStatus,
};

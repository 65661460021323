import { put, takeEvery } from 'redux-saga/effects';
import { BoostActionTypes } from 'store/boost/actionTypes';
import { boostGetStartingVotingPowerAction, boostSetStateAction, boostSetStatusAction } from 'store/boost/actions';
import { getContract, RequestStatus, setNotification } from '@workstream/shared';
import { veXbeAbi } from 'assets/abi';
import { VE_XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* boostGetStartingVotingPowerSaga(
  { type }: ReturnType<typeof boostGetStartingVotingPowerAction>,
) {
  try {
    yield put(boostSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    const contract = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);
    const startingVotingPower: string = yield contract
      .methods
      .balanceOf(address)
      .call();

    yield put(boostSetStateAction({ startingVotingPower }));
    yield put(boostSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(boostSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getStartingVotingPowerSaga() {
  yield takeEvery(
    BoostActionTypes.GET_STARTING_VOTING_POWER,
    boostGetStartingVotingPowerSaga,
  );
}

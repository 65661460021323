import createReducer from 'store/createReducer';
import { RatesCurrencyToken, RateState, RatesToken } from 'store/rate/types';
import { RATE_HANDLERS } from 'store/rate/handlers';

const initialState: RateState = {
  [RatesToken.XBE]: {
    [RatesCurrencyToken.USD]: '0',
  },
  [RatesToken.ETH]: {
    [RatesCurrencyToken.USD]: '0',
  },
  ui: {},
};

export default createReducer(initialState, RATE_HANDLERS);

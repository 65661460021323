export enum SushiSwapLPStakingActionTypes {
  SET_STATE = 'SUSHISWAP_LP_STAKING.SET_STATE',

  SET_STATUS = 'SUSHISWAP_LP_STAKING.SET_STATUS',

  GET_BLOCK_INFO = 'SUSHISWAP_LP_STAKING.GET_BLOCK_INFO',

  APPROVE = 'SUSHISWAP_LP_STAKING.APPROVE',

  GET_ALLOWANCE = 'SUSHISWAP_LP_STAKING.GET_ALLOWANCE',

  DEPOSIT = 'SUSHISWAP_LP_STAKING.DEPOSIT',

  WITHDRAW_CLAIM = 'SUSHISWAP_LP_STAKING.WITHDRAW_CLAIM',
}

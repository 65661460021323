import { fork } from 'redux-saga/effects';
import { getBlockInfoSaga } from './getBlockInfo';
import { depositSaga } from './deposit';
import { unlockSaga } from './unlock';

export default [
  fork(getBlockInfoSaga),
  fork(depositSaga),
  fork(unlockSaga),
];

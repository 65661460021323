import React from 'react';
import { BlockWrapper } from '@workstream/shared';
import { RoutesStake } from 'containers';
import styles from './styles.module.scss';

const Stake = () => (
  <BlockWrapper
    className={`container ${styles.container}`}
    tag="div"
  >
    <RoutesStake />
  </BlockWrapper>
);

export default Stake;

import { HandlerFn } from 'store/types';
import { RateActionTypes } from 'store/rate/actionTypes';
import { RateState } from 'store/rate/types';
import { rateSetRateAction, rateSetStateAction, rateSetStatusAction } from 'store/rate/actions';

type RateActionFn<F extends (...args: any) => any> = HandlerFn<RateState, ReturnType<F>>;

const setState: RateActionFn<typeof rateSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: RateActionFn<typeof rateSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

const setRate: RateActionFn<typeof rateSetRateAction> = (
  state,
  { payload: { token, currency, payload } },
) => ({
  ...state,
  [token]: {
    ...state[token],
    [currency]: payload,
  },
});

export const RATE_HANDLERS = {
  [RateActionTypes.SET_STATE]: setState,
  [RateActionTypes.SET_STATUS]: setStatus,
  [RateActionTypes.SET_RATE]: setRate,
};

import {
  crvConvertingConvertAndStakeAction,
  crvConvertingGetBlockInfoAction,
  crvConvertingSetStateAction,
} from 'store/crvConverting/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { CrvConvertingActionTypes } from 'store/crvConverting/actionTypes';
import {
  getContract, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { cvxCrvStrategyAbi } from 'assets/abi';
import { CVX_CRV_STRATEGY_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';
import { meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';

function* crvConvertingConvertAndStakeSaga(
  { payload }: ReturnType<typeof crvConvertingConvertAndStakeAction>,
) {
  try {
    yield put(crvConvertingSetStateAction({ stakingStatus: WriteContractStatus.WRITING }));
    const address: string = yield selectAddress();
    const contract = getContract(cvxCrvStrategyAbi, CVX_CRV_STRATEGY_CONTRACT_ADDRESS);

    yield contract
      .methods
      .convertAndStakeTokens(web3.utils.toWei(payload))
      .send({
        from: address,
      });

    yield put(crvConvertingGetBlockInfoAction());
    yield put(meGetBalancesAction(BalancesToken.CRV));
    yield put(meGetBalancesAction(BalancesToken.CVX_CRV));
    setNotification({ type: 'success', message: 'Deposit success!' });
    yield put(crvConvertingSetStateAction({ stakingStatus: WriteContractStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(crvConvertingSetStateAction({ stakingStatus: WriteContractStatus.ERROR }));
  }
}

export function* convertAndStakeSaga() {
  yield takeLeading(
    CrvConvertingActionTypes.CONVERT_AND_STAKE,
    crvConvertingConvertAndStakeSaga,
  );
}

import { HandlerFn } from 'store/types';
import { BoostActionTypes } from 'store/boost/actionTypes';
import { BoostState } from 'store/boost/types';
import { boostSetStateAction, boostSetStatusAction } from 'store/boost/actions';

type BoostActionFn<F extends (...args: any) => any> = HandlerFn<BoostState, ReturnType<F>>;

const setState: BoostActionFn<typeof boostSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: BoostActionFn<typeof boostSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const BOOST_HANDLERS = {
  [BoostActionTypes.SET_STATE]: setState,
  [BoostActionTypes.SET_STATUS]: setStatus,
};

import { put, takeLeading } from 'redux-saga/effects';
import { boostApproveAction, boostSetStateAction } from 'store/boost/actions';
import { BoostActionTypes } from 'store/boost/actionTypes';
import {
  getContract, MAX_UINT_256, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { xbeAbi } from 'assets/abi';
import { VE_XBE_CONTRACT_ADDRESS, XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* boostApproveSaga(
  { payload: value }: ReturnType<typeof boostApproveAction>,
) {
  try {
    yield put(boostSetStateAction({ submitStatus: WriteContractStatus.APPROVING }));

    const address: string = yield selectAddress();
    const contract = getContract(xbeAbi, XBE_CONTRACT_ADDRESS);
    const approveCount = Number(value) > 0 ? MAX_UINT_256.toFixed() : web3.utils.toWei('0');

    yield contract
      .methods
      .approve(VE_XBE_CONTRACT_ADDRESS, approveCount)
      .send({
        from: address,
      });

    setNotification({ type: 'success', message: 'Approving!' });
    yield put(boostSetStateAction({ submitStatus: WriteContractStatus.APPROVED }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(boostSetStateAction({ submitStatus: WriteContractStatus.APPROVED_ERROR }));
  }
}

export function* approveSaga() {
  yield takeLeading(
    BoostActionTypes.APPROVE,
    boostApproveSaga,
  );
}

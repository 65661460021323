import { RequestStatus } from '@workstream/shared';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import { XbeStakingState } from 'store/xbeStaking/types';

export const xbeStakingSetStateAction = (payload: Partial<XbeStakingState>) => ({
  type: XbeStakingActionTypes.SET_STATE,
  payload,
});

export const xbeStakingSetStatusAction = (
  payload: { type: XbeStakingActionTypes, status: RequestStatus },
) => ({
  type: XbeStakingActionTypes.SET_STATUS,
  payload,
});

export const xbeStakingGetBlockInfoAction = () => ({
  type: XbeStakingActionTypes.GET_BLOCK_INFO,
});

export const xbeStakingGetAllowanceAction = () => ({
  type: XbeStakingActionTypes.GET_ALLOWANCE,
});

export const xbeStakingApproveAction = (payload: string) => ({
  type: XbeStakingActionTypes.APPROVE,
  payload,
});

export const xbeStakingDepositAction = (payload: string) => ({
  type: XbeStakingActionTypes.DEPOSIT,
  payload,
});

export const xbeStakingWithdrawAction = (payload: string) => ({
  type: XbeStakingActionTypes.WITHDRAW,
  payload,
});

import { fork } from 'redux-saga/effects';
import provideLiquidityAddLiquiditySaga from './provideLiquidityAddLiquiditySaga';
import provideLiquidityAddLiquidityOnlyEthSaga from './provideLiquidityAddLiquidityOnlyEthSaga';
import provideLiquidityGetPriceSaga from './provideLiquidityGetPriceSaga';
import provideLiquidityApproveTokenSaga from './provideLiquidityApproveTokenSaga';
import provideLiquidityGetTokenAllowanceSaga from './provideLiquidityGetTokenAllowanceSaga';
import provideLiquidityGetTotalLiquiditySaga from './provideLiquidityGetTotalLiquiditySaga';
import provideLiquidityUnstakeSaga from './provideLiquidityUnstakeSaga';

export default [
  fork(provideLiquidityAddLiquiditySaga),
  fork(provideLiquidityAddLiquidityOnlyEthSaga),
  fork(provideLiquidityGetPriceSaga),
  fork(provideLiquidityApproveTokenSaga),
  fork(provideLiquidityGetTokenAllowanceSaga),
  fork(provideLiquidityGetTotalLiquiditySaga),
  fork(provideLiquidityUnstakeSaga),
];

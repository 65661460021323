import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import type { TableCell as Cell } from '@workstream/shared';
import { Table } from '@workstream/shared';
import { useDispatch } from 'react-redux';
import { referralsInfoSelector, referralsMeSelector, useShallowSelector } from 'store/selectors';
import { MembersReferralsRow } from 'store/referrals/info/types';
import { referralsInfoGetMembersReferralsAction } from 'store/referrals/info/actions';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const RegistrationTimeList: FC<Props> = ({
  className,
}) => {
  const membersReferrals = useShallowSelector(referralsInfoSelector.getProp('membersReferrals'));
  const refCode = useShallowSelector(referralsMeSelector.getProp('referralCode'));
  const dispatch = useDispatch();

  const data = membersReferrals.length ? membersReferrals : [{
    date: '-',
    referralCode: '-',
    liquidity: '-',
  }];

  useEffect(() => {
    if (refCode) {
      dispatch(referralsInfoGetMembersReferralsAction());
    }
  }, [dispatch, refCode]);

  const [columns] = useState([
    {
      Header: 'REGISTRATION TIME (±)',
      id: 'regTime',
      width: '33%',
      Cell: ({ row }: Cell<MembersReferralsRow>) => {
        const { date } = row.original;

        return date;
      },
    },
    {
      Header: 'MEMBER CODE (±)',
      id: 'memberCode',
      width: '33.5%',
      Cell: ({ row }: Cell<MembersReferralsRow>) => {
        const { referralCode } = row.original;

        return referralCode;
      },
    },
    {
      Header: 'MEMBER LIQUIDITY (±)',
      id: 'memberLiquidity',
      width: '33.5%',
      Cell: ({ row }: Cell<MembersReferralsRow>) => {
        const { liquidity } = row.original;

        return liquidity === '-' ? liquidity : `$${liquidity} (±USDC)`;
      },
    },
  ]);

  return (
    <div className={cx(styles.container, className)}>
      <Table
        classNameTH={styles.thCell}
        classNameRow={styles.trCell}
        columns={columns}
        data={data}
      />
    </div>
  );
};

export default RegistrationTimeList;

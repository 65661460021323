import React, { FC, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { Button, RequestStatus, Text } from '@workstream/shared';
import { InputWithMaxButton } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BigNumber from 'bignumber.js';
import { meSelector, useShallowSelector, xbeStakingSelector } from 'store/selectors';
import { useDispatch } from 'react-redux';
import { xbeStakingWithdrawAction } from 'store/xbeStaking/actions';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const initialValues = {
  withdraw: '',
};

Yup.addMethod(Yup.string, 'withdraw', function (
  errorMessage: string,
  maxWithdraw: string,
) {
  return this.test('test-value', errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      const valueBN = new BigNumber(value);

      const conditions: boolean[] = [
        valueBN.isGreaterThan(0),
        valueBN.isLessThanOrEqualTo(maxWithdraw),
      ];

      if (conditions.includes(false)) {
        return createError({
          path,
          message: errorMessage,
        });
      }
      return true;
    }

    return false;
  });
});

const Withdraw: FC<Props> = ({
  className,
}) => {
  const withdrawStatus =
    useShallowSelector(xbeStakingSelector.getStatus(XbeStakingActionTypes.WITHDRAW));
  const bondedAndLockXbe = useShallowSelector(xbeStakingSelector.getProp('bondedAndLockedXbe'));
  const { votingStakingRewards } = useShallowSelector(meSelector.getProp('balances'));

  const maxValue = new BigNumber(votingStakingRewards).minus(bondedAndLockXbe).toString();

  const dispatch = useDispatch();

  const {
    values,
    setValues,
    isValid,
    handleSubmit,
  } = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      withdraw: Yup.string()
        // @ts-ignore
        .withdraw(
          'Error',
          maxValue,
        )
        .required(),
    }),
    onSubmit: ({ withdraw }) => {
      dispatch(xbeStakingWithdrawAction(withdraw));
    },
  });

  const onMaxClick = useCallback(() => {
    if (Number(maxValue) > 0) {
      setValues({ withdraw: maxValue });
    } else {
      setValues({ withdraw: '0' });
    }
  }, [maxValue, setValues]);

  const disabledSubmit = useMemo(() => (
    !isValid || withdrawStatus === RequestStatus.REQUEST || maxValue === '0'
  ), [isValid, maxValue, withdrawStatus]);

  return (
    <form
      className={cx(styles.container, className)}
      onSubmit={handleSubmit}
    >
      <Text
        color="secondary"
        size="tiny"
        className={cx(
          styles.label,
        )}
      >
        Unstake
      </Text>
      <InputWithMaxButton
        value={values.withdraw}
        onChange={(event) => setValues({ withdraw: event.target.value })}
        onClick={onMaxClick}
        className={styles.inputWrapper}
        disabled={maxValue === '0'}
        placeholder="0.00 XBE"
      />
      <Button
        type="submit"
        isOutline
        size="mini"
        className={styles.buttonWithdraw}
        disabled={disabledSubmit}
      >
        { withdrawStatus === RequestStatus.REQUEST ? 'Unstake...' : 'Unstake' }
      </Button>
    </form>
  );
};

export default Withdraw;

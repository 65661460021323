import React, {
  ChangeEvent, FC, useEffect, useMemo, useState, useCallback,
} from 'react';
import { BlockWithList, InputWithMaxButton } from 'components';
import { RequirementWalletProvider } from 'containers';
import { BlockWithListProps } from 'components/BlockWithList';
import {
  Button,
  CoinEth,
  CoinSushi,
  CoinXbe,
  PlatformHeaderBlock,
  RequestStatus,
  web3,
  WriteContractStatus,
} from '@workstream/shared';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import {
  sushiSwapLPStakingApproveAction,
  sushiSwapLPStakingDepositAction,
  sushiSwapLPStakingGetBlockInfoAction,
  sushiSwapLPStakingWithdrawClaimAction,
} from 'store/sushiSwapLPStaking/actions';
import { claimClaimSushiAction } from 'store/claim/actions';
import {
  claimSelector,
  meSelector,
  sushiSwapLPStakingSelector,
  useShallowSelector,
  provideLiquiditySelector,
  rateSelector,
} from 'store/selectors';
import { ClaimTokens } from 'store/claim/constants';
import { SushiSwapLPStakingActionTypes } from 'store/sushiSwapLPStaking/actionTypes';
import { RatesToken, RatesCurrencyToken } from 'store/rate/types';
import BigNumber from 'bignumber.js';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const COINS = [{ name: 'sushi', img: CoinSushi }, { name: 'eth', img: CoinEth }, { name: 'xbe', img: CoinXbe }];

const SushiswapLPStakingForm: FC<Props> = ({ className }) => {
  const {
    uiBalances: {
      sushiVault: sushiVaultBalanceUI,
    },
    balances: {
      ethXbe: ethXbeBalance,
      sushiVault: sushiVaultBalance,
    },
  } = useShallowSelector(meSelector.getState);
  const {
    percentageOfPool, potentialXBEReturns, allowance,
  } = useShallowSelector(sushiSwapLPStakingSelector.getState);
  const { lp: claimStatus } = useShallowSelector(claimSelector.getProp('uiClaim'));
  const { deposits, earned } = useShallowSelector(claimSelector.getProp(ClaimTokens.SUSHI));
  const submitStatus = useShallowSelector(sushiSwapLPStakingSelector.getProp('submitStatus'));
  const withdrawStatus = useShallowSelector(
    sushiSwapLPStakingSelector.getStatus(SushiSwapLPStakingActionTypes.WITHDRAW_CLAIM),
  );
  const {
    totalLiquidity,
  } = useShallowSelector(provideLiquiditySelector.getState);
  const rateEthUsd = useShallowSelector(
    rateSelector.getRateProp(RatesToken.ETH, RatesCurrencyToken.USD),
  );

  const dispatch = useDispatch();

  const list = useMemo<BlockWithListProps['list']>(() => [
    {
      label: 'Available for deposit',
      value: `${Number(ethXbeBalance).toFixed(5)}`,
    },
    {
      label: 'Staked amount(USD)',
      value: `${Number(deposits).toFixed(2)}`,
    },
    {
      label: 'Percentage of Pool',
      value: `${percentageOfPool}%`,
    },
    {
      label: 'Total Liquidity(USD)',
      value: new BigNumber(totalLiquidity).multipliedBy(rateEthUsd || '0').toFixed(2),
    },
    {
      label: 'Potential XBE returns* ',
      value: Number(web3.utils.fromWei(potentialXBEReturns)).toFixed(2),
    },
  ], [
    ethXbeBalance,
    deposits,
    percentageOfPool,
    totalLiquidity,
    rateEthUsd,
    potentialXBEReturns,
  ]);

  useEffect(() => {
    if (sushiVaultBalanceUI === RequestStatus.SUCCESS) {
      dispatch(sushiSwapLPStakingGetBlockInfoAction());
    }
  }, [dispatch, sushiVaultBalanceUI]);

  const [value, setValue] = useState('');
  const [valueWithdraw, setValueWithdraw] = useState('');

  const handlerInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value),
    [],
  );

  const handlerInputWithdraw = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setValueWithdraw(event.target.value),
    [],
  );

  const onApproveClick = useCallback(() => {
    if (!value || value === '0') {
      dispatch(sushiSwapLPStakingApproveAction('0'));
    } else {
      dispatch(sushiSwapLPStakingApproveAction(value));
    }
  }, [dispatch, value]);

  const onDepositClick = useCallback(() => {
    dispatch(sushiSwapLPStakingDepositAction(value));
  }, [dispatch, value]);

  const onWithdrawClick = useCallback(() => {
    dispatch(sushiSwapLPStakingWithdrawClaimAction(valueWithdraw));
  }, [dispatch, valueWithdraw]);

  const onClaimClick = useCallback(() => {
    dispatch(claimClaimSushiAction());
  }, [dispatch]);

  const isApproveDisabled = useMemo(() => {
    if (!value) return true;
    const valueBN = new BigNumber(value);

    return [
      ![
        valueBN.isLessThanOrEqualTo(0),
        valueBN.isGreaterThan(web3.utils.fromWei(allowance)),
      ].includes(true),
      submitStatus === WriteContractStatus.APPROVING,
      submitStatus === WriteContractStatus.WRITING,
      withdrawStatus === RequestStatus.REQUEST,
      claimStatus === RequestStatus.REQUEST,
    ].includes(true);
  }, [value, allowance, submitStatus, withdrawStatus, claimStatus]);

  const isDepositDisabled = useMemo(() => {
    if (!value) return true;

    const valueBN = new BigNumber(value);

    return [
      submitStatus === WriteContractStatus.WRITING,
      withdrawStatus === RequestStatus.REQUEST,
      claimStatus === RequestStatus.REQUEST,
      valueBN.isLessThanOrEqualTo(0),
      valueBN.isGreaterThan(ethXbeBalance),
      valueBN.isGreaterThan(web3.utils.fromWei(allowance)),
    ].includes(true);
  }, [
    submitStatus, withdrawStatus, value, ethXbeBalance, allowance, claimStatus,
  ]);

  const isWithdrawDisabled = useMemo(() => {
    if (!valueWithdraw) return true;

    const valueBN = new BigNumber(valueWithdraw);

    return [
      submitStatus === WriteContractStatus.APPROVING,
      submitStatus === WriteContractStatus.WRITING,
      withdrawStatus === RequestStatus.REQUEST,
      claimStatus === RequestStatus.REQUEST,
      valueBN.isLessThanOrEqualTo(0),
      valueBN.isGreaterThan(sushiVaultBalance),
    ].includes(true);
  }, [
    submitStatus, withdrawStatus, claimStatus, valueWithdraw, sushiVaultBalance,
  ]);

  const handleMaxClick = useCallback(() => {
    setValue(`${ethXbeBalance}`);
  }, [ethXbeBalance]);

  const handleMaxClickWithdraw = useCallback(() => {
    setValueWithdraw(`${sushiVaultBalance}`);
  }, [sushiVaultBalance]);

  return (
    <div className={className}>
      <RequirementWalletProvider>
        <PlatformHeaderBlock
          backgroundColor="grey"
          centerText="STAKE YOUR SUSHISWAP LP TOKENS FOR XBE REWARDS"
          sizeText="small"
        />
        <BlockWithList
          list={list}
          className={styles.bottomBlock}
          theme="lightBlue"
          icons={COINS}
          title="XBE_ETH"
          classNameHeader={styles.header}
          classNameOption={styles.option}
        >
          <form>
            <div className={styles.footer}>
              <div className={styles.inputButtons}>
                <InputWithMaxButton
                  placeholder="0.00"
                  className={styles.inputWrapper}
                  classNameInput={styles.input}
                  value={value}
                  onChange={handlerInput}
                  onClick={handleMaxClick}
                />
                <Button
                  size="sm"
                  color="primary-dark"
                  className={cx(styles.button, styles.buttonApprove)}
                  onClick={onApproveClick}
                  disabled={isApproveDisabled}
                >
                  { submitStatus === WriteContractStatus.APPROVING ? 'Approve...' : 'Approve'}
                </Button>
                <Button
                  size="sm"
                  color="primary-dark"
                  isOutline
                  className={cx(styles.button, styles.buttonDeposit)}
                  onClick={onDepositClick}
                  disabled={isDepositDisabled}
                >
                  { submitStatus === WriteContractStatus.WRITING ? 'Processing...' : 'Deposit'}
                </Button>
              </div>
              <div className={styles.buttons}>
                <InputWithMaxButton
                  placeholder="0.00"
                  className={styles.inputWrapper}
                  classNameInput={styles.input}
                  value={valueWithdraw}
                  onChange={handlerInputWithdraw}
                  onClick={handleMaxClickWithdraw}
                />
                <Button
                  size="sm"
                  color="primary-dark"
                  isOutline
                  className={cx(styles.button, styles.buttonWithdrawClaim)}
                  onClick={onWithdrawClick}
                  disabled={isWithdrawDisabled}
                >
                  { withdrawStatus === RequestStatus.REQUEST ? 'Processing...' : 'Withdraw'}
                </Button>
                <Button
                  size="sm"
                  color="primary-dark"
                  isOutline
                  className={cx(styles.button, styles.buttonWithdrawClaim)}
                  disabled={claimStatus === RequestStatus.REQUEST || earned === '0'}
                  onClick={onClaimClick}
                >
                  {claimStatus === RequestStatus.REQUEST ? 'Claiming...' : 'Claim'}
                </Button>
              </div>
            </div>
          </form>
        </BlockWithList>
      </RequirementWalletProvider>
    </div>
  );
};

export default SushiswapLPStakingForm;

import React from 'react';
import { XBELockupForm, XBEStakingForm } from 'containers';
import { BlockWrapper, Text } from '@workstream/shared';
import styles from './styles.module.scss';

const StakingForms = () => (
  <>
    <BlockWrapper
      tag="section"
      className={styles.body}
    >
      <div className={styles.wrapper}>
        <XBEStakingForm className={styles.XBEStakingForm} />
        <XBELockupForm className={styles.XBELockupForm} />
      </div>
      <Text className={styles.footer} color="secondary" size="small">
        Please note that for security reasons you are able to revoke any of our
        contract’s permissions by simply approving a 0 value.
      </Text>
      <Text className={styles.footer} color="secondary" size="small" bold>
        NB: Please note you must stake first in order to lockup.
      </Text>
      <Text className={styles.footer} color="secondary" size="small" bold>
        To add more to your lockup, please use the add to lockup feature below.
      </Text>
    </BlockWrapper>
  </>
);

export default StakingForms;

import { put, takeEvery, all } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { CvxStakingActionTypes } from 'store/cvxStaking/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  cvxStakingGetAllowanceAction,
  cvxStakingGetBlockInfoAction,
  cvxStakingSetStateAction,
  cvxStakingSetStatusAction,
} from 'store/cvxStaking/actions';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { cvxAbi, cvxVaultAbi } from 'assets/abi';
import { CVX_CONTRACT_ADDRESS, CVX_VAULT_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* cvxStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof cvxStakingGetBlockInfoAction>,
) {
  try {
    yield put(cvxStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const contractCvx = getContract(cvxAbi, CVX_CONTRACT_ADDRESS);

    const totalSupply: string = yield contractCvx
      .methods
      .totalSupply()
      .call();

    const address: string = yield selectAddress();

    const contract = getContract(cvxVaultAbi, CVX_VAULT_CONTRACT_ADDRESS);

    const tokensCount: string = yield contract
      .methods
      .getRewardTokensCount()
      .call();

    const listRewardsContract: string[] = yield all(
      Array(Number(tokensCount)).fill(0).map((_, index) => contract
        .methods
        .getRewardToken(index)
        .call()),
    );

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(
      `/pools?address=${address}&pool=cvx-vault`,
    );

    yield put(cvxStakingSetStateAction({
      totalSupply: web3.utils.fromWei(totalSupply),
      earned: new BigNumber(data.earned).toFixed(2),
      deposits: new BigNumber(data.deposit).toFixed(2),
      tvl: new BigNumber(data.tvl).toFixed(2),
      apr: new BigNumber(data.apr).toFixed(2),
      listRewardsContract,
    }));
    yield put(cvxStakingGetAllowanceAction());
    yield put(cvxStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(cvxStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    CvxStakingActionTypes.GET_BLOCK_INFO,
    cvxStakingGetBlockInfoSaga,
  );
}

import React, { FC } from 'react';
import cx from 'classnames';
import {
  IconFileCopy,
  IconReferralCode, IconReferralDateRegister, IconReferralHits, IconReferralLink,
} from 'assets/img';
import { Copyable, SvgIcon, Text } from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  type: 'code' | 'link' | 'dateRegister' | 'hits',
  title: string,
  value: string,
  isValueCopy?: boolean,
};

const TYPES: Record<Props['type'], { icon: string, class: string }> = {
  code: {
    icon: IconReferralCode,
    class: styles.code,
  },
  link: {
    icon: IconReferralLink,
    class: styles.link,
  },
  dateRegister: {
    icon: IconReferralDateRegister,
    class: styles.dateRegister,
  },
  hits: {
    icon: IconReferralHits,
    class: styles.hits,
  },
};

const ReferralInfoItem: FC<Props> = ({
  className,
  type,
  title,
  value,
  isValueCopy,
}) => (
  <div className={cx(
    styles.container, TYPES[type].class, className,
  )}
  >
    <SvgIcon
      icon={TYPES[type].icon}
      className={styles.icon}
    />
    <div className={styles.inner}>
      <Text
        color="extra"
        className={styles.title}
      >
        {title}
      </Text>
      <div className={styles.valueCopy}>
        <Text
          size="huge"
          className={styles.value}
        >
          {value}
        </Text>
        {isValueCopy && (
          <Copyable valueToCopy={value}>
            <button
              type="button"
              className={styles.buttonCopy}
            >
              <SvgIcon icon={IconFileCopy} />
            </button>
          </Copyable>
        )}
      </div>
    </div>
  </div>
);

export default ReferralInfoItem;

import { HandlerFn } from 'store/types';
import { MinterActionTypes } from './actionTypes';
import { MinterState } from './types';
import {
  minterSetStateAction,
  minterGetStatsAction,
  minterSetStatusAction,
} from './actions';

type MinterActionFn<F extends (...args: any) => any> =
  HandlerFn<MinterState, ReturnType<F>>;

const setState: MinterActionFn<typeof minterSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const getStats: MinterActionFn<typeof minterGetStatsAction> = (
  state,
) => state;

const setStatus: MinterActionFn<typeof minterSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const MINTER_HANDLERS = {
  [MinterActionTypes.SET_STATE]: setState,
  [MinterActionTypes.GET_STATS]: getStats,
  [MinterActionTypes.SET_STATUS]: setStatus,
};

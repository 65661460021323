import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import {
  DefaultBlock, SvgIcon, H5, Button,
} from '@workstream/shared';
import { ClaimBlockItem } from 'components';
import styles from './styles.module.scss';

export type Props = {
  className?: string,
  icon: string,
  title: string,
  valuesList?: {
    value: string,
    label: string,
    id?: string,
  }[],
  earned: string,
  averageAPR?: string,
  onClaim?: () => void,
  buttonClaimClass?: string,
  buttonClaimDisabled?: boolean,
  buttonClaimText?: string,
  withContainer?: boolean,
};

const ClaimBlock: FC<PropsWithChildren<Props>> = ({
  className,
  icon,
  children,
  title,
  earned,
  averageAPR,
  onClaim,
  buttonClaimClass,
  buttonClaimDisabled,
  buttonClaimText,
  withContainer,
  valuesList,
}) => (
  <DefaultBlock
    className={cx(styles.container, className)}
    tag="div"
    withContainer={withContainer}
  >
    <div className={styles.header}>
      <div className={styles.iconTitle}>
        <SvgIcon icon={icon} className={styles.icon} />
        <H5
          color="extra"
          className={styles.title}
        >
          {title}
        </H5>
      </div>

      <ClaimBlockItem
        className={cx(styles.valueWrapper, styles.earnedWrapper)}
        value={earned}
        label="Earned (USD Value)"
      />

      { averageAPR && (
        <ClaimBlockItem
          className={cx(styles.valueWrapper, styles.averageWrapper)}
          value={averageAPR}
          label="Average APR"
        />
      )}

      {onClaim && (
        <Button
          size="sm"
          className={cx(styles.buttonClaim, buttonClaimClass)}
          onClick={onClaim}
          disabled={buttonClaimDisabled}
        >
          { buttonClaimText || 'Claim' }
        </Button>
      )}
    </div>
    { valuesList && (
      <ul className={styles.valuesList}>
        {valuesList.map((item) => (
          <li
            key={`${item.value}${item.label}${item.id}`}
            className={styles.valuesItem}
          >
            <ClaimBlockItem
              className={styles.valueItemClaim}
              value={item.value}
              label={item.label}
            />
          </li>
        ))}
      </ul>
    )}
    {children}
  </DefaultBlock>
);

export default ClaimBlock;

import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { Contract } from 'web3-eth-contract';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  xbeStakingGetAllowanceAction,
  xbeStakingGetBlockInfoAction,
  xbeStakingSetStateAction,
  xbeStakingSetStatusAction,
} from 'store/xbeStaking/actions';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { veXbeAbi, votingStakingRewardsAbi } from 'assets/abi';
import { VE_XBE_CONTRACT_ADDRESS, VOTING_STAKING_REWARDS_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

type BondedRewardLocksResult = {
  amount: string,
  requested: boolean,
  unlockTime: string,
};

type LockedResult = {
  amount: string,
  end: string,
};

function* getBoostLevel(contract: Contract, address: string) {
  const calculateBoostLevel: string = yield contract
    .methods
    .calculateBoostLevel(address)
    .call();

  const maxBoostCoefficient: string = yield contract
    .methods
    .inverseMaxBoostCoefficient()
    .call();

  return new BigNumber(web3.utils.fromWei(calculateBoostLevel))
    .multipliedBy(100)
    .div(maxBoostCoefficient)
    .toFixed(1);
}

function* xbeStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof xbeStakingGetBlockInfoAction>,
) {
  try {
    yield put(xbeStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const contractVoting =
      getContract(votingStakingRewardsAbi, VOTING_STAKING_REWARDS_CONTRACT_ADDRESS);
    const contractVeXbe = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);

    const totalSupply: string = yield contractVoting
      .methods
      .totalSupply()
      .call();

    const address: string = yield selectAddress();

    const { amount: bonded }: BondedRewardLocksResult = yield contractVoting
      .methods
      .bondedRewardLocks(address)
      .call();

    const { amount: locked }: LockedResult = yield contractVeXbe
      .methods
      .locked(address)
      .call();

    const bondedAndLockedXbe = new BigNumber(web3.utils.fromWei(bonded))
      .plus(web3.utils.fromWei(locked))
      .toString();

    const boostLevel: string = yield getBoostLevel(contractVoting, address);

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(
      `/pools?address=${address}&pool=voting-staking-rewards`,
    );

    yield put(xbeStakingSetStateAction({
      totalSupply: web3.utils.fromWei(totalSupply),
      bondedAndLockedXbe,
      boostLevel,
      earned: new BigNumber(data.earned).toFixed(2),
      deposits: new BigNumber(data.deposit).toFixed(2),
      tvl: new BigNumber(data.tvl).toFixed(2),
      apr: new BigNumber(data.apr).toFixed(2),
    }));
    yield put(xbeStakingGetAllowanceAction());
    yield put(xbeStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(xbeStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    XbeStakingActionTypes.GET_BLOCK_INFO,
    xbeStakingGetBlockInfoSaga,
  );
}

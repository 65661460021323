import { RequestStatus } from '@workstream/shared';
import { BoostActionTypes } from 'store/boost/actionTypes';
import { BoostState } from 'store/boost/types';

export const boostSetStateAction = (payload: Partial<BoostState>) => ({
  type: BoostActionTypes.SET_STATE,
  payload,
});

export const boostSetStatusAction = (
  payload: { type: BoostActionTypes, status: RequestStatus },
) => ({
  type: BoostActionTypes.SET_STATUS,
  payload,
});

export const boostGetBlockLockupInfoAction = () => ({
  type: BoostActionTypes.GET_BLOCK_LOCKUP_INFO,
});

export const boostGetBlockDepositInfoAction = () => ({
  type: BoostActionTypes.GET_BLOCK_DEPOSIT_INFO,
});

export const boostGetStartingVotingPowerAction = () => ({
  type: BoostActionTypes.GET_STARTING_VOTING_POWER,
});

export const boostApproveAction = (payload: string) => ({
  type: BoostActionTypes.APPROVE,
  payload,
});

export const boostDepositAction = (payload: string) => ({
  type: BoostActionTypes.DEPOSIT,
  payload,
});

export const boostSubmitLockTimeAction = (payload: string) => ({
  type: BoostActionTypes.SUBMIT_LOCK_TIME,
  payload,
});

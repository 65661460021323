import React, { ChangeEvent, FC } from 'react';
import cx from 'classnames';
import { InputWithAvailable } from 'components';
import { Button, DefaultBlock, Text } from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  icon: string,
  name: string,
  description?: string,
  token?: string,
  tokenAvailable?: string,
  inputOnMaxClick?: () => void,
  inputValue?: string,
  inputOnChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  buttonText?: string,
  buttonDisabled?: boolean,
  buttonOnClick?: () => void,
};

const Unstake: FC<Props> = ({
  className,
  icon,
  name,
  description = '',
  token = '',
  tokenAvailable = '0',
  inputOnMaxClick = () => {},
  inputValue = '',
  inputOnChange = () => {},
  buttonText = 'Unstake & Withdraw',
  buttonDisabled = false,
  buttonOnClick = () => {},
}) => (
  <div className={cx(styles.container, className)}>
    { description && (
      <DefaultBlock
        theme="grey"
        className={styles.noteWrapper}
      >
        <Text
          className={styles.noteTitle}
          color="primary"
        >
          Note
        </Text>
        <Text
          className={styles.noteText}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </DefaultBlock>
    )}

    <div className={styles.inner}>
      <InputWithAvailable
        className={styles.inputWithAvailable}
        icon={icon}
        name={`${name}-input`}
        placeholder="0.00"
        isShowAvailableLegend={false}
        available={<span><b>{`${tokenAvailable} ${token}`}</b>  tokens available</span>}
        onMaxClick={inputOnMaxClick}
        onChange={inputOnChange}
        value={inputValue}
      />
      <Button
        className={styles.buttonUnstake}
        isOutline
        size="sm"
        disabled={buttonDisabled}
        onClick={buttonOnClick}
      >
        {buttonText}
      </Button>
    </div>
  </div>
);

export default Unstake;

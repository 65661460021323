import { StakeCurveLpState } from 'store/stakeCurveLp/types';
import { State } from 'store/types';
import { CurveLpToken } from '../stakeCurveLp/constants';

export default {
  // eslint-disable-next-line max-len
  getProp: <T extends keyof StakeCurveLpState>(propKey: T) => (state: State) => state.stakeCurveLp[propKey],
  getState: (state: State) => state.stakeCurveLp,
  getStatus: <T extends keyof StakeCurveLpState['ui']>(propKey: T) => (state: State) => state.stakeCurveLp.ui[propKey],
  getSubmitStatus: <T extends keyof StakeCurveLpState['uiSubmitStatus']>(propKey: T) => (state: State) => state.stakeCurveLp.uiSubmitStatus[propKey],
  getBalance: (token: CurveLpToken) => (state: State) => state.stakeCurveLp.balances[token],
  // eslint-disable-next-line max-len
  getBalanceVault: (token: CurveLpToken) => (state: State) => state.stakeCurveLp.balancesVaults[token],
  // eslint-disable-next-line max-len
  getBalanceVaultOld: (token: CurveLpToken) => (state: State) => state.stakeCurveLp.balancesVaultsOld[token] || '0',
  // eslint-disable-next-line max-len
  getContractsRewardsList: (token: CurveLpToken) => (state: State) => state.stakeCurveLp.listRewardsContractsAddresses[token],
  // eslint-disable-next-line max-len
  getLpTokenAddress: (token: CurveLpToken) => (state: State) => state.stakeCurveLp.lpTokensAddresses[token],
  getAllowance: (token: CurveLpToken) => (state: State) => state.stakeCurveLp.allowances[token],
  getWithdrawStatus: <T extends keyof StakeCurveLpState['uiWithdrawStatus']>(propKey: T) => (state: State) => state.stakeCurveLp.uiWithdrawStatus[propKey],
};

import { RequestStatus } from '@workstream/shared';
import { RateActionTypes } from 'store/rate/actionTypes';
import {
  RatesCurrencyToken, RateState, RatesToken, RatesTokenBody,
} from 'store/rate/types';

export const rateSetStateAction = (payload: Partial<RateState>) => ({
  type: RateActionTypes.SET_STATE,
  payload,
});

export const rateSetStatusAction = (
  payload: { type: RateActionTypes, status: RequestStatus },
) => ({
  type: RateActionTypes.SET_STATUS,
  payload,
});

export const rateGetRate = (payload: {
  token: RatesToken,
  currency: RatesCurrencyToken,
}) => ({
  type: RateActionTypes.GET_RATE,
  payload,
});

export const rateSetRateAction = (
  payload: { token: RatesToken, currency: RatesCurrencyToken, payload: RatesTokenBody },
) => ({
  type: RateActionTypes.SET_RATE,
  payload,
});

import { put, takeEvery } from 'redux-saga/effects';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import {
  getContract,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import {
  provideLiquidityAddLiquidityAction,
  provideLiquidityGetTokenAllowanceAction,
  provideLiquidityGetPriceAction,
  provideLiquiditySetStatusAction,
  provideLiquidityGetTotalLiquidityAction,
} from 'store/provideLiquidity/actions';
import {
  SUSHISWAP_ROUTER_CONTRACT_ADDRESS,
  XBE_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { sushiswapRouterAbi } from 'assets/abi';
import { selectAddress } from 'store/utils';
import { sushiSwapLPStakingGetBlockInfoAction } from 'store/sushiSwapLPStaking/actions';
import { meGetBalanceEthAction, meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import BigNumber from 'bignumber.js';

const DEADLINE_SECONDS = 1200;

function* provideLiquidityAddLiquiditySaga(
  { type, payload }: ReturnType<typeof provideLiquidityAddLiquidityAction>,
) {
  try {
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.REQUEST }));
    const { amountToken, amountEth } = payload;
    const address: string = yield selectAddress();
    const deadline = String(Math.floor(Date.now() / 1000) + DEADLINE_SECONDS);

    const amountTokenMin = new BigNumber(amountToken)
      .multipliedBy(0.995).toFixed(18, 1);
    const amountEthMin = new BigNumber(amountEth)
      .multipliedBy(0.995).toFixed(18, 1);

    const params = [
      XBE_CONTRACT_ADDRESS,
      web3.utils.toWei(amountToken),
      web3.utils.toWei(amountTokenMin), // 0,
      web3.utils.toWei(amountEthMin), // 0,
      address,
      web3.utils.toHex(deadline),
    ];

    const contract = getContract(sushiswapRouterAbi, SUSHISWAP_ROUTER_CONTRACT_ADDRESS);
    const gasLimit: string = yield contract
      .methods
      .addLiquidityETH(...params)
      .estimateGas({
        from: address,
        gas: 1_000_000,
        value: web3.utils.toWei(amountEth),
      });

    yield contract
      .methods
      .addLiquidityETH(...params)
      .send({
        from: address,
        gasLimit,
        value: web3.utils.toWei(amountEth),
      });

    yield put(provideLiquidityGetPriceAction());
    yield put(provideLiquidityGetTokenAllowanceAction());
    yield put(provideLiquidityGetTotalLiquidityAction());
    yield put(sushiSwapLPStakingGetBlockInfoAction());
    yield put(meGetBalancesAction(BalancesToken.SUSHI_VAULT));
    yield put(meGetBalancesAction(BalancesToken.XBE));
    yield put(meGetBalancesAction(BalancesToken.ETH_XBE));
    yield put(meGetBalanceEthAction());
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.SUCCESS }));
    setNotification({ type: 'success', message: 'Deposit success' });
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export default function* listener() {
  yield takeEvery(
    ProvideLiquidityActionTypes.ADD_LIQUIDITY,
    provideLiquidityAddLiquiditySaga,
  );
}

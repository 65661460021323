import React, { FC, useState } from 'react';
import cx from 'classnames';
import { RadioGroup, RequestStatus } from '@workstream/shared';
import styles from './styles.module.scss';
import Stake from '../Stake';
import Unstake from '../Unstake';
import Info from '../Info';

type Props = {
  className?: string,
  token: string,
  iconInput: string,
  balance?: string,
  allowance?: string,
  stakeDescription?: string,
  stakeBeforeDescription?: string,
  unstakeDescription?: string,
  stakeNote?: string,
  stakingStatus?: string,
  maxValueUnStaked: string,
  withdrawStatus?: RequestStatus,
  listRewardsContract?: string[],
  onUnStake: (value: string) => void,
  onApprove: (value: string) => void,
  onStake: (value: string) => void,
  isShowStakeTitle?: boolean,
  isShowStakeDepositCurveButton?: boolean,
};

const StakeWrapper: FC<Props> = ({
  className,
  iconInput,
  token,
  balance = '',
  allowance,
  maxValueUnStaked,
  onApprove,
  onStake,
  onUnStake,
  stakeDescription,
  stakeBeforeDescription,
  unstakeDescription,
  stakeNote,
  withdrawStatus,
  stakingStatus,
  listRewardsContract,
  isShowStakeTitle = true,
  isShowStakeDepositCurveButton = true,
}) => {
  const pages: { [ index: string]: JSX.Element } = {
    Stake: <Stake
      icon={iconInput}
      name={`stakeCvxCvxCrvXbe${token}`}
      description={stakeDescription}
      beforeDescription={stakeBeforeDescription}
      note={stakeNote}
      balance={balance}
      allowance={allowance}
      onApprove={onApprove}
      onStake={onStake}
      stakingStatus={stakingStatus}
      token={token}
      isShowTitle={isShowStakeTitle}
      isShowDepositCurveButton={isShowStakeDepositCurveButton}
    />,
    Unstake: <Unstake
      icon={iconInput}
      token={token}
      description={unstakeDescription}
      name={`unstakeCvxCvxCrvXbe${token}`}
      maxValueUnStaked={maxValueUnStaked}
      withdrawStatus={withdrawStatus}
      onUnStake={onUnStake}
    />,
    Info: <Info
      token={token}
      listRewardsContract={listRewardsContract}
    />,
  };

  const [option, setOption] = useState(Object.keys(pages)[0]);

  return (
    <div className={cx(styles.container, className)}>
      <RadioGroup
        className={styles.radioGroup}
        nameGroup={`stakeCurve${token}`}
        options={Object.keys(pages)}
        callback={setOption}
        theme="lightBlueMini"
      />

      {pages[option]}
    </div>
  );
};

export default StakeWrapper;

import React, {
  FC, FormEvent, ChangeEvent, useState, useCallback, useEffect, useMemo,
} from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button, DefaultBlock, Input, RequestStatus, Text,
} from '@workstream/shared';
import { RequirementWalletProvider } from 'containers';
import {
  referralsMeRegisterWalletAction,
  referralsMeIncReferralHitAction,
  referralsMeSetStateAction,
} from 'store/referrals/me/actions';
import { referralsMeSelector, useShallowSelector } from 'store/selectors';
import { ReferralsMeActionTypes } from 'store/referrals/me/actionTypes';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const description = "*Register your wallet with an existing referral code, to get your own unique referral link and start your network.  If you don't have a referral link, use XBE-DEFAULT as a referral code.";

const ReferralRegister: FC<Props> = ({
  className,
}) => {
  const urlReferralCode = useShallowSelector(referralsMeSelector.getProp('urlReferralCode'));
  const [referralCode, setReferralCode] = useState(urlReferralCode);
  const location = useLocation();
  const dispatch = useDispatch();
  const registerWalletStatus = useShallowSelector(
    referralsMeSelector.getStatus(ReferralsMeActionTypes.REGISTER_WALLET),
  );
  const getReferralCodeStatus = useShallowSelector(
    referralsMeSelector.getStatus(ReferralsMeActionTypes.GET_REFERRAL_CODE),
  );

  useEffect(() => {
    const code = (new URLSearchParams(location.search)).get('code');
    setReferralCode(code || urlReferralCode);
    if(code && getReferralCodeStatus === RequestStatus.ERROR) {
      dispatch(referralsMeSetStateAction({ urlReferralCode: code }));
      dispatch(referralsMeIncReferralHitAction(code || ''));
    }
  }, [getReferralCodeStatus, dispatch, location.search, urlReferralCode]);

  const handleRegisterWalletClick = useCallback((event: FormEvent) => {
    event.preventDefault();
    dispatch(referralsMeRegisterWalletAction(referralCode));
  }, [dispatch, referralCode]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setReferralCode(e.target.value);
  }, []);

  const disabledRegisterWallet = useMemo(
    () => registerWalletStatus === RequestStatus.REQUEST || referralCode.length === 0,
    [referralCode.length, registerWalletStatus],
  );

  return (
    <RequirementWalletProvider>
      <DefaultBlock
        tag="div"
        className={cx(styles.container, className)}
      >
        <Text
          color="secondary"
        >
          Enter a Referral Code:
        </Text>
        <form onSubmit={handleRegisterWalletClick} className={styles.inputButton}>
          <Input
            classNameWrapper={styles.inputWrapper}
            className={styles.input}
            color="grey"
            name="referralCode"
            placeholder="XBE-AF0X2ZRQ9B4"
            value={referralCode}
            onChange={handleChange}
          />
          <Button
            className={styles.button}
            type="submit"
            disabled={disabledRegisterWallet}
          >
            {registerWalletStatus === RequestStatus.REQUEST ? 'Registration...' : 'Register My Wallet'}
          </Button>
        </form>
        <Text
          className={styles.description}
          color="secondary"
        >
          {description}
        </Text>
      </DefaultBlock>
    </RequirementWalletProvider>
  );
};

export default ReferralRegister;

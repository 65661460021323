import { HandlerFn } from 'store/types';
import { FraxStakingActionTypes } from 'store/fraxStaking/actionTypes';
import { FraxStakingState } from 'store/fraxStaking/types';
import { fraxStakingSetStateAction, fraxStakingSetStatusAction } from 'store/fraxStaking/actions';

type FraxStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<FraxStakingState, ReturnType<F>>;

const setState: FraxStakingActionFn<typeof fraxStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: FraxStakingActionFn<typeof fraxStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const FRAX_STAKING_HANDLERS = {
  [FraxStakingActionTypes.SET_STATE]: setState,
  [FraxStakingActionTypes.SET_STATUS]: setStatus,
};

import { put, takeEvery } from 'redux-saga/effects';
import { StakeCurveLpActionTypes } from 'store/stakeCurveLp/actionTypes';
import {
  getContract, setNotification, web3,
} from '@workstream/shared';
import {
  stakeCurveLpGetBalanceAction,
  stakeCurveLpSetBalanceAction,
} from 'store/stakeCurveLp/actions';
import { selectAddress } from 'store/utils';
import {
  stakeCurveLpTokensAbi, stakeCurveLpTokensAddress,
} from 'store/stakeCurveLp/constants';

function* stakeCurveLpGetBalanceSaga(
  { payload: token }: ReturnType<typeof stakeCurveLpGetBalanceAction>,
) {
  try {
    const address: string = yield selectAddress();
    const contract = getContract(
      stakeCurveLpTokensAbi[token],
      stakeCurveLpTokensAddress[token],
    );

    const balance: string = yield contract
      .methods
      .balanceOf(address)
      .call();

    yield put(stakeCurveLpSetBalanceAction({
      token,
      balance: web3.utils.fromWei(balance),
    }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
  }
}

export function* getBalanceSaga() {
  yield takeEvery(
    StakeCurveLpActionTypes.GET_BALANCE,
    stakeCurveLpGetBalanceSaga,
  );
}

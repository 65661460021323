/* eslint-disable max-len */
import type { Action } from 'redux';
import type { HandlerFn } from './types';

const createReducer = <T>(
  initialState: T,
  handlers: Record<string, HandlerFn<T, any>>,
) => (state = initialState, action: Action<string> & any) => (Object.prototype.hasOwnProperty.call(handlers, action.type) ? handlers[action.type](state, action) : state);

export default createReducer;

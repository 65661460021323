import { HandlerFn } from 'store/types';
import { EurtStakingActionTypes } from 'store/eurtStaking/actionTypes';
import { EurtStakingState } from 'store/eurtStaking/types';
import { eurtStakingSetStateAction, eurtStakingSetStatusAction } from 'store/eurtStaking/actions';

type EurtStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<EurtStakingState, ReturnType<F>>;

const setState: EurtStakingActionFn<typeof eurtStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: EurtStakingActionFn<typeof eurtStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const EURT_STAKING_HANDLERS = {
  [EurtStakingActionTypes.SET_STATE]: setState,
  [EurtStakingActionTypes.SET_STATUS]: setStatus,
};

import {
  cvxStakingDepositAction,
  cvxStakingGetBlockInfoAction,
  cvxStakingSetStateAction,
} from 'store/cvxStaking/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { CvxStakingActionTypes } from 'store/cvxStaking/actionTypes';
import {
  getContract, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { cvxVaultAbi } from 'assets/abi';
import { CVX_VAULT_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';
import { meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';

function* cvxStakingDepositSaga(
  { payload }: ReturnType<typeof cvxStakingDepositAction>,
) {
  try {
    yield put(cvxStakingSetStateAction({ stakingStatus: WriteContractStatus.WRITING }));
    const address: string = yield selectAddress();
    const contract = getContract(cvxVaultAbi, CVX_VAULT_CONTRACT_ADDRESS);

    yield contract
      .methods
      .deposit(web3.utils.toWei(payload))
      .send({
        from: address,
      });

    yield put(cvxStakingGetBlockInfoAction());
    yield put(meGetBalancesAction(BalancesToken.CVX));
    setNotification({ type: 'success', message: 'Deposit success!' });
    yield put(cvxStakingSetStateAction({ stakingStatus: WriteContractStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(cvxStakingSetStateAction({ stakingStatus: WriteContractStatus.ERROR }));
  }
}

export function* depositSaga() {
  yield takeLeading(
    CvxStakingActionTypes.DEPOSIT,
    cvxStakingDepositSaga,
  );
}

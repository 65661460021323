import React, { useEffect } from 'react';
import {
  ReferralExtendedInfo, ReferralInfo, ReferralRegister, RegistrationTimeList,
} from 'containers';
import { BlockWrapper, DefaultBlock, Text } from '@workstream/shared';
import { useDispatch } from 'react-redux';
import { referralsMeGetReferralCodeAction } from 'store/referrals/me/actions';
import { referralsMeSelector, useShallowSelector, web3Selector } from 'store/selectors';
import styles from './styles.module.scss';

const note = 'Only your TIER 1 Network members can be displayed below. Liquidity is estimated in USDC.';

const Referrals = () => {
  const dispatch = useDispatch();
  const metamaskAddress = useShallowSelector(web3Selector.getProp('address'));
  const referralCode = useShallowSelector(referralsMeSelector.getProp('referralCode'));

  useEffect(() => {
    dispatch(referralsMeGetReferralCodeAction());
  }, [dispatch, metamaskAddress]);

  return (
    <BlockWrapper
      className={`container ${styles.container}`}
      tag="div"
    >
      {referralCode ? (
        <ReferralInfo className={styles.referralInfo} />
      ) : (
        <ReferralRegister className={styles.referralRegister} />
      )}
      <ReferralExtendedInfo />
      <DefaultBlock
        theme="lightBlue"
        withContainer={false}
        className={styles.noteBlock}
        tag="div"
      >
        <Text
          color="secondary"
          className={styles.note}
        >
          <b>Note:</b> {note}
        </Text>
      </DefaultBlock>
      <RegistrationTimeList
        className={styles.registrationTimeList}
      />
    </BlockWrapper>
  );
};

export default Referrals;

export default [{
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'admin', type: 'address',
  }],
  name: 'ApplyOwnership',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'admin', type: 'address',
  }],
  name: 'CommitOwnership',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'provider', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
  }, {
    indexed: true, internalType: 'uint256', name: 'locktime', type: 'uint256',
  }, {
    indexed: false, internalType: 'int128', name: '_type', type: 'int128',
  }, {
    indexed: false, internalType: 'uint256', name: 'ts', type: 'uint256',
  }],
  name: 'Deposit',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'prevSupply', type: 'uint256',
  }, {
    indexed: false, internalType: 'uint256', name: 'supply', type: 'uint256',
  }],
  name: 'Supply',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'provider', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
  }, {
    indexed: false, internalType: 'uint256', name: 'ts', type: 'uint256',
  }],
  name: 'Withdraw',
  type: 'event',
}, {
  inputs: [], name: 'CREATE_LOCK_TYPE', outputs: [{ internalType: 'int128', name: '', type: 'int128' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'DEPOSIT_FOR_TYPE', outputs: [{ internalType: 'int128', name: '', type: 'int128' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'INCREASE_LOCK_AMOUNT', outputs: [{ internalType: 'int128', name: '', type: 'int128' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'INCREASE_UNLOCK_TIME', outputs: [{ internalType: 'int128', name: '', type: 'int128' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'MAXTIME', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'MULTIPLIER', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'WEEK', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'YEAR', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'admin', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'applyTransferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'addr', type: 'address' }, { internalType: 'uint256', name: '_t', type: 'uint256' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'addr', type: 'address' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'addr', type: 'address' }, { internalType: 'uint256', name: '_block', type: 'uint256' }], name: 'balanceOfAt', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_newController', type: 'address' }], name: 'changeController', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'checkpoint', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'addr', type: 'address' }], name: 'commitTransferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'tokenAddr', type: 'address' }, { internalType: 'address', name: '_votingStakingRewards', type: 'address' }, { internalType: 'address', name: '_registrationMediator', type: 'address' }, { internalType: 'uint256', name: '_minLockDuration', type: 'uint256' }, { internalType: 'string', name: '_name', type: 'string' }, { internalType: 'string', name: '_symbol', type: 'string' }, { internalType: 'string', name: '_version', type: 'string' }], name: 'configure', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'controller', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '_value', type: 'uint256' }, { internalType: 'uint256', name: '_unlockTime', type: 'uint256' }], name: 'createLock', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'address', name: '', type: 'address' }], name: 'createLockAllowance', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_for', type: 'address' }, { internalType: 'uint256', name: '_value', type: 'uint256' }, { internalType: 'uint256', name: '_unlockTime', type: 'uint256' }], name: 'createLockFor', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'decimals', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_addr', type: 'address' }, { internalType: 'uint256', name: '_value', type: 'uint256' }], name: 'depositFor', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'epoch', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'futureAdmin', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'addr', type: 'address' }], name: 'getLastUserSlope', outputs: [{ internalType: 'int128', name: '', type: 'int128' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '_value', type: 'uint256' }], name: 'increaseAmount', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '_unlockTime', type: 'uint256' }], name: 'increaseUnlockTime', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'addr', type: 'address' }], name: 'lockStarts', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'locked', outputs: [{ internalType: 'int128', name: 'amount', type: 'int128' }, { internalType: 'uint256', name: 'end', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'addr', type: 'address' }], name: 'lockedAmount', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'addr', type: 'address' }], name: 'lockedEnd', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'lockedSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'minLockDuration', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'pointHistory', outputs: [{ internalType: 'int128', name: 'bias', type: 'int128' }, { internalType: 'int128', name: 'slope', type: 'int128' }, { internalType: 'uint256', name: 'ts', type: 'uint256' }, { internalType: 'uint256', name: 'blk', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'registrationMediator', outputs: [{ internalType: 'contract ILockSubscription', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_sender', type: 'address' }, { internalType: 'bool', name: '_status', type: 'bool' }], name: 'setCreateLockAllowance', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '_minLockDuration', type: 'uint256' }], name: 'setMinLockDuration', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_votingStakingRewards', type: 'address' }], name: 'setVoting', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'slopeChanges', outputs: [{ internalType: 'int128', name: '', type: 'int128' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'supply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 't', type: 'uint256' }], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '_block', type: 'uint256' }], name: 'totalSupplyAt', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'userPointEpoch', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'uint256', name: '', type: 'uint256' }], name: 'userPointHistory', outputs: [{ internalType: 'int128', name: 'bias', type: 'int128' }, { internalType: 'int128', name: 'slope', type: 'int128' }, { internalType: 'uint256', name: 'ts', type: 'uint256' }, { internalType: 'uint256', name: 'blk', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'addr', type: 'address' }, { internalType: 'uint256', name: 'idx', type: 'uint256' }], name: 'userPointHistoryTs', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'version', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'votingStakingRewards', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'withdraw', outputs: [], stateMutability: 'nonpayable', type: 'function',
}];

import { put, takeEvery } from 'redux-saga/effects';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import {
  getContract,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import BigNumber from 'bignumber.js';
import {
  provideLiquidityGetTotalLiquidityAction, provideLiquiditySetStateAction,
  provideLiquiditySetStatusAction,
} from 'store/provideLiquidity/actions';
import { ETH_XBE_PAIR_CONTRACT_ADDRESS, WETH_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { wethAbi } from 'assets/abi';

function* provideLiquidityGetTotalLiquiditySaga(
  { type }: ReturnType<typeof provideLiquidityGetTotalLiquidityAction>,
) {
  try {
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.REQUEST }));
    const contract = getContract(wethAbi, WETH_CONTRACT_ADDRESS);

    const totalLiquidity: string = yield contract
      .methods
      .balanceOf(ETH_XBE_PAIR_CONTRACT_ADDRESS)
      .call();

    yield put(provideLiquiditySetStateAction({
      totalLiquidity: new BigNumber(web3.utils.fromWei(totalLiquidity)).multipliedBy(2).toFixed(2),
    }));
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export default function* listener() {
  yield takeEvery(
    ProvideLiquidityActionTypes.GET_TOTAL_LIQUIDITY,
    provideLiquidityGetTotalLiquiditySaga,
  );
}

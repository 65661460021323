import React, { useMemo } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
const Range = ({ className, value, minValue = 0, maxValue = 100, symbolValue = '', }) => {
    const range = maxValue - minValue;
    const percent = (range) / 100;
    const percentWidth = useMemo(() => {
        const result = (range + value - maxValue) / percent;
        if (result > 100)
            return 100;
        if (result < 0)
            return 0;
        return result;
    }, [value, maxValue, minValue]);
    const fixedValue = useMemo(() => {
        if (value < minValue)
            return minValue;
        if (value > maxValue)
            return maxValue;
        return value;
    }, [value, maxValue, minValue]);
    return (React.createElement("div", { className: cx(styles.wrapper, className) },
        React.createElement("div", { className: styles.track },
            React.createElement("div", { className: styles.progress, style: { width: `${percentWidth}%` } })),
        React.createElement("div", { className: styles.scrubber, style: { left: `${percentWidth}%` } }),
        React.createElement("div", { className: styles.percentageWrapper, style: { left: `${percentWidth}%` } }, `${fixedValue.toFixed(0)}${symbolValue}`)));
};
export default Range;

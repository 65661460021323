import createReducer from 'store/createReducer';
import { XbeStakingState } from 'store/xbeStaking/types';
import { XBE_STAKING_HANDLERS } from 'store/xbeStaking/handlers';
import { RequestStatus, WriteContractStatus } from '@workstream/shared';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';

const initialState: XbeStakingState = {
  allowance: '0',
  totalSupply: '0',
  boostLevel: '0',
  stakingStatus: WriteContractStatus.INIT,
  bondedAndLockedXbe: '0',
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  ui: {
    [XbeStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
    [XbeStakingActionTypes.GET_ALLOWANCE]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, XBE_STAKING_HANDLERS);

import React from 'react';
import {
  BlockWrapper, DefaultBlock, Range, Text,
} from '@workstream/shared';
import { meSelector, useShallowSelector } from 'store/selectors';
import { RequirementWalletProvider } from 'containers';
import styles from './styles.module.scss';

const Boost = () => {
  const percentBoost = useShallowSelector(meSelector.getProp('boostLevelPercent'));

  return (
    <RequirementWalletProvider>
      <BlockWrapper
        className={styles.wrapper}
        title="BOOST"
        description="Lockup XBE to increase your boost."
      >
        <DefaultBlock
          tag="div"
          withContainer={false}
          theme="lightBlue"
          isBorder={false}
          className={styles.inner}
        >
          <Text
            size="large"
            className={styles.percentageLeft}
          >
            0 %
          </Text>
          <Range
            value={Number(percentBoost)}
            minValue={0}
            maxValue={100}
            className={styles.range}
            symbolValue="%"
          />
          <Text
            size="large"
            className={styles.percentageRight}
          >
            100 %
          </Text>
        </DefaultBlock>
      </BlockWrapper>
    </RequirementWalletProvider>
  );
};

export default Boost;

import { put, takeEvery } from 'redux-saga/effects';
import { RequestStatus, setNotification } from '@workstream/shared';
import { rateSetStatusAction, rateGetRate, rateSetRateAction } from 'store/rate/actions';
import { RateActionTypes } from 'store/rate/actionTypes';
import { api } from 'utils';
import { AxiosResponse } from 'axios';
import { RatesTokensRoot } from 'store/rate/types';

function* rateGetRateSaga({
  type,
  payload: {
    token,
    currency,
  },
}: ReturnType<typeof rateGetRate>) {
  try {
    yield put(rateSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const { data }: AxiosResponse<RatesTokensRoot[]> = yield api.get(
      `/rates?token=${token}&currency=${currency}`,
    );

    yield put(rateSetRateAction({
      token,
      currency,
      payload: data[0][token][currency] || '0',
    }));
    yield put(rateSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(rateSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getRateSaga() {
  yield takeEvery(RateActionTypes.GET_RATE, rateGetRateSaga);
}

import {
  cvxStakingWithdrawAction,
  cvxStakingGetBlockInfoAction,
  cvxStakingSetStatusAction,
} from 'store/cvxStaking/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { CvxStakingActionTypes } from 'store/cvxStaking/actionTypes';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { cvxVaultAbi } from 'assets/abi';
import { CVX_VAULT_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import { selectAddress } from 'store/utils';

function* cvxStakingWithdrawSaga(
  { type, payload }: ReturnType<typeof cvxStakingWithdrawAction>,
) {
  try {
    yield put(cvxStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contract = getContract(cvxVaultAbi, CVX_VAULT_CONTRACT_ADDRESS);

    yield contract
      .methods
      .withdraw(web3.utils.toWei(payload))
      .send({
        from: address,
      });

    yield put(cvxStakingGetBlockInfoAction());
    yield put(meGetBalancesAction(BalancesToken.CVX));
    setNotification({ type: 'success', message: 'Withdraw success!' });
    yield put(cvxStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(cvxStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* withdrawSaga() {
  yield takeLeading(
    CvxStakingActionTypes.WITHDRAW,
    cvxStakingWithdrawSaga,
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
const TableRow = ({ row, className, }) => (React.createElement(React.Fragment, null,
    React.createElement("tr", Object.assign({ className: cx(styles.row, className) }, row.getRowProps()), row.cells.map((cell) => (React.createElement("td", Object.assign({}, cell.getCellProps({
        className: styles.cell,
    }), { style: {
            width: cell.column.width,
        } }), cell.render('Cell'))))),
    React.createElement("tr", { className: styles.divider })));
export default TableRow;

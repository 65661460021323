import { put, takeLeading } from 'redux-saga/effects';
import { Web3ActionTypes } from 'store/web3/actionTypes';
import { web3SetStateAction } from 'store/web3/actions';
import { MetamaskStatus, setNotification } from '@workstream/shared';

function* web3LogoutSaga() {
  yield put(web3SetStateAction({ metamaskStatus: MetamaskStatus.AVAILABLE, address: '' }));

  setNotification({
    type: 'info',
    title: 'Please note',
    message: 'You will need to remove the connection from “connected sites” in metamask',
  });
}

export function* logoutSaga() {
  yield takeLeading(
    Web3ActionTypes.LOGOUT,
    web3LogoutSaga,
  );
}

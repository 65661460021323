import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { minterSelector, useShallowSelector } from 'store/selectors';
import { minterGetStatsAction, minterClaimAction } from 'store/minter/actions';
import { MinterActionTypes } from 'store/minter/actionTypes';
import {
  BlockWrapper,
  Button,
  IconWithOption,
  Medal,
  formatters,
  RequestStatus,
} from '@workstream/shared';
import { BlockWithList } from 'components';
import cx from 'classnames';
import styles from './styles.module.scss';

const Minter = () => {
  const dispatch = useDispatch();
  const {
    totalXBEMinted,
    allClaimableXBE,
    isAccessToClaim,
    ui,
  } = useShallowSelector(minterSelector.getState);

  const isClaimLoading = ui[MinterActionTypes.CLAIM] === RequestStatus.REQUEST;

  const options = useMemo(() => [
    {
      label: 'Total XBE Minted from Strategies',
      value: formatters.toFormatViaBN(totalXBEMinted, 5),
    },
    {
      label: 'Claimable XBE STAKING Rewards',
      value: formatters.toFormatViaBN(allClaimableXBE, 5),
    },
  ], [totalXBEMinted, allClaimableXBE]);

  useEffect(() => {
    dispatch(minterGetStatsAction());
  }, [dispatch]);

  return (
    <BlockWrapper
      className={styles.wrapper}
      title="Сlaim XBE Staking rewards only"
      description="See your accrued STAKING XBE rewards in real-time, and Claim them to Mint XBE to your wallet as it becomes available!"
    >
      <div className={styles.columns}>
        <BlockWithList
          title="XBE STAKING REWARDS ONLY"
          list={options}
          classNameHeader={styles.listHeader}
          className={cx(styles.blockWithList, styles.leftColumn)}
          classNameLabel={styles.listOptions}
        >
          <Button
            size="sm"
            isOutline
            className={styles.buttonClaimAll}
            disabled={!isAccessToClaim || isClaimLoading}
            onClick={() => dispatch(minterClaimAction())}
          >
            {isClaimLoading ? 'Processing... ' : 'Claim All'}
          </Button>
        </BlockWithList>

        <IconWithOption
          className={styles.medal}
          descriptionClassName={styles.medalDescription}
          icon={Medal}
          title="Update your boost"
          description="Claiming from a strategy will update your boost!"
          valueSize="tiny"
        />

        <i className={cx(styles.star, styles.blue, styles.blueOne)} />
        <i className={cx(styles.star, styles.blue, styles.blueTwo)} />
        <i className={cx(styles.star, styles.yellow)} />
      </div>
    </BlockWrapper>
  );
};

export default Minter;

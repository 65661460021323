export default [{
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256[]', name: 'distribution', type: 'uint256[]',
  }],
  name: 'NewDistribution',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'token', type: 'address',
  }],
  name: 'NewToken',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'user', type: 'address',
  }, {
    indexed: false, internalType: 'address', name: 'referrer', type: 'address',
  }],
  name: 'RegisterUser',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'user', type: 'address',
  }, {
    indexed: false, internalType: 'address', name: 'token', type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256',
  }],
  name: 'RewardReceived',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'user', type: 'address',
  }, {
    indexed: false, internalType: 'address[]', name: 'tokens', type: 'address[]',
  }, {
    indexed: false, internalType: 'uint256[]', name: 'amounts', type: 'uint256[]',
  }],
  name: 'RewardsClaimed',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'address', name: 'admin', type: 'address',
  }],
  name: 'TransferOwnership',
  type: 'event',
}, {
  inputs: [{ internalType: 'address', name: 'tokenAddress', type: 'address' }], name: 'addNewToken', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'admin', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256[]', name: 'newDistribution', type: 'uint256[]' }], name: 'changeDistribution', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'claimRewards', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'userAddr', type: 'address' }], name: 'claimRewardsFor', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'claimRewardsForRoot', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address[]', name: 'tokenAddresses', type: 'address[]' }, { internalType: 'address', name: '_rootAddress', type: 'address' }, { internalType: 'address', name: '_registry', type: 'address' }], name: 'configure', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'distribution', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_for', type: 'address' }, { internalType: 'address', name: '_token', type: 'address' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'feeReceiving', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'getDistributionList', outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'getFeeDistributors', outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'getTokensList', outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'referrer', type: 'address' }, { internalType: 'address', name: 'referral', type: 'address' }], name: 'registerUser', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'referrer', type: 'address' }], name: 'registerUser', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'registry', outputs: [{ internalType: 'contract IRegistry', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'address', name: '', type: 'address' }], name: 'rewards', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'rootAddress', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'tokens', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newAdmin', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'users', outputs: [{ internalType: 'bool', name: 'exists', type: 'bool' }, { internalType: 'address', name: 'referrer', type: 'address' }], stateMutability: 'view', type: 'function',
}];

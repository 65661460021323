import React from 'react';
import cx from 'classnames';
import { Text } from "./..";
import styles from './styles.module.scss';
const Checkbox = ({ name, checked = false, error = '', label = '', disabled = false, className, classNameLabel, onChange = () => { }, theme = 'min', }) => (React.createElement("div", { className: cx(styles.wrap, className) },
    React.createElement("label", { htmlFor: name, className: cx(styles.checkbox, { [styles.disabled]: disabled }, classNameLabel, styles[theme]) },
        React.createElement("input", { type: "checkbox", disabled: disabled, id: name, checked: checked, className: cx(styles.input, {
                [styles.errorCheckbox]: error,
            }), onChange: (e) => onChange(e, !checked) }),
        React.createElement("span", { className: cx(styles.checkmark, {
                [styles.error]: error,
            }) }),
        label && (React.createElement("span", { className: cx(styles.text, {
                [styles.error]: error,
            }) }, label))),
    error && React.createElement(Text, { className: styles.errorText, color: "error", size: "tiny", tag: "span" },
        "* ",
        error)));
export default Checkbox;

import { HandlerFn } from 'store/types';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import { ProvideLiquidityState } from 'store/provideLiquidity/types';
import {
  provideLiquiditySetStateAction, provideLiquiditySetStatusAction,
} from 'store/provideLiquidity/actions';

type ProvideLiquidityActionFn<F extends (...args: any) => any>
  = HandlerFn<ProvideLiquidityState, ReturnType<F>>;

const setState: ProvideLiquidityActionFn<typeof provideLiquiditySetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: ProvideLiquidityActionFn<typeof provideLiquiditySetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const PROVIDE_LIQUIDITY_HANDLERS = {
  [ProvideLiquidityActionTypes.SET_STATE]: setState,
  [ProvideLiquidityActionTypes.SET_STATUS]: setStatus,
};

import { referralsMeRegisterWalletAction, referralsMeSetStatusAction, referralsMeGetReferralCodeAction } from 'store/referrals/me/actions';
import { RequestStatus, setNotification, getContract } from '@workstream/shared';
import { put, takeLeading } from 'redux-saga/effects';
import { ReferralsMeActionTypes } from 'store/referrals/me/actionTypes';
import { api } from 'utils';
import { AxiosResponse } from 'axios';
import { referralProgramAbi } from 'assets/abi';
import { REFERRAL_PROGRAM_CONTRACT_ADDRESS, FEE_TO_TREASURY_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

type Result = {
  address: string,
};

function* referralsMeRegisterWalletSaga(
  { type, payload: referralCode }: ReturnType<typeof referralsMeRegisterWalletAction>,
) {
  try {
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const address: string = yield selectAddress();
    const contract = getContract(referralProgramAbi, REFERRAL_PROGRAM_CONTRACT_ADDRESS);

    if (referralCode !== 'XBE-DEFAULT') {
      const result: AxiosResponse<Result> = yield api.get(`users/user/address?referralCode=${referralCode}`);
      yield contract.methods.registerUser(result.data.address).send({
        from: address,
      });
    } else {
      yield contract.methods.registerUser(FEE_TO_TREASURY_CONTRACT_ADDRESS).send({
        from: address,
      });
    }
    yield put(referralsMeGetReferralCodeAction());
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    if (e.response && e.response.status === 404) {
      setNotification({ type: 'error', message: e.response.data.message });
    } else {
      setNotification({ type: 'error', message: e.message });
    }
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* registerWalletSaga() {
  yield takeLeading(
    ReferralsMeActionTypes.REGISTER_WALLET,
    referralsMeRegisterWalletSaga,
  );
}

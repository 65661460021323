import BigNumber from 'bignumber.js';
export var MetamaskStatus;
(function (MetamaskStatus) {
    MetamaskStatus["AVAILABLE"] = "AVAILABLE";
    MetamaskStatus["NOT_AVAILABLE"] = "NOT_AVAILABLE";
    MetamaskStatus["ADDRESS_SELECTED"] = "ADDRESS_SELECTED";
})(MetamaskStatus || (MetamaskStatus = {}));
export var RequestStatus;
(function (RequestStatus) {
    RequestStatus["INIT"] = "INIT";
    RequestStatus["REQUEST"] = "REQUEST";
    RequestStatus["SUCCESS"] = "SUCCESS";
    RequestStatus["RESET"] = "RESET";
    RequestStatus["ERROR"] = "ERROR";
})(RequestStatus || (RequestStatus = {}));
export var WriteContractStatus;
(function (WriteContractStatus) {
    WriteContractStatus["INIT"] = "INIT";
    WriteContractStatus["APPROVING"] = "APPROVING";
    WriteContractStatus["APPROVED"] = "APPROVED";
    WriteContractStatus["APPROVED_ERROR"] = "APPROVED_ERROR";
    WriteContractStatus["WRITING"] = "WRITING";
    WriteContractStatus["SUCCESS"] = "SUCCESS";
    WriteContractStatus["ERROR"] = "ERROR";
})(WriteContractStatus || (WriteContractStatus = {}));
export var ProviderLoadingStatus;
(function (ProviderLoadingStatus) {
    ProviderLoadingStatus["INIT"] = "INIT";
    ProviderLoadingStatus["LOADING"] = "LOADING";
    ProviderLoadingStatus["LOADED"] = "LOADED";
})(ProviderLoadingStatus || (ProviderLoadingStatus = {}));
export const METAMASK_EXTENSION_URL = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
export const MAX_UINT_256 = new BigNumber('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');

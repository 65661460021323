import { RequestStatus } from '@workstream/shared';
import createReducer from 'store/createReducer';
import { ClaimState } from 'store/claim/types';
import { ClaimTokens } from 'store/claim/constants';
import { CLAIM_HANDLERS } from 'store/claim/handlers';

const initialState: ClaimState = {
  [ClaimTokens.SUSHI]: {
    earned: '0',
    apr: '0',
    deposits: '0',
  },
  [ClaimTokens.CVX]: {
    earned: '0',
    apr: '0',
    deposits: '0',
  },
  [ClaimTokens.CVX_CRV]: {
    earned: '0',
    apr: '0',
    deposits: '0',
  },
  [ClaimTokens.IRON_BANK]: {
    earned: '0',
  },
  [ClaimTokens.FRAX]: {
    earned: '0',
  },
  [ClaimTokens.ST_ETH]: {
    earned: '0',
  },
  [ClaimTokens.EURT]: {
    earned: '0',
  },
  ui: {},
  uiClaim: {
    [ClaimTokens.SUSHI]: RequestStatus.INIT,
    [ClaimTokens.CVX]: RequestStatus.INIT,
    [ClaimTokens.CVX_CRV]: RequestStatus.INIT,
    [ClaimTokens.IRON_BANK]: RequestStatus.INIT,
    [ClaimTokens.FRAX]: RequestStatus.INIT,
    [ClaimTokens.ST_ETH]: RequestStatus.INIT,
    [ClaimTokens.EURT]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, CLAIM_HANDLERS);

import React, { useEffect, useMemo, useState } from 'react';
import {
  BlockWrapper, DefaultBlock, MetamaskStatus, Preloader, RequestStatus, Text, web3,
} from '@workstream/shared';
import { DepositForm, RequirementWalletProvider } from 'containers';
import { BlockWithList } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { boostGetBlockLockupInfoAction, boostGetStartingVotingPowerAction } from 'store/boost/actions';
import { boostSelector, useShallowSelector, web3Selector } from 'store/selectors';
import { BoostActionTypes } from 'store/boost/actionTypes';
import cx from 'classnames';
import styles from './styles.module.scss';

const Deposit = () => {
  const metamaskStatus = useSelector(web3Selector.getProp('metamaskStatus'));
  const getBlockLockupInfoStatus =
    useSelector(boostSelector.getStatus(BoostActionTypes.GET_BLOCK_LOCKUP_INFO));

  const {
    totalXBEVoteLocked, ofAllCirculatingXBE, totalVeXBE,
    averageLockTime, startingVotingPower,
  } = useShallowSelector(boostSelector.getState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (metamaskStatus === MetamaskStatus.ADDRESS_SELECTED) {
      dispatch(boostGetBlockLockupInfoAction());
      dispatch(boostGetStartingVotingPowerAction());
    }
  }, [dispatch, metamaskStatus]);

  const XBELockupList = [
    {
      label: 'Total XBE Vote Locked',
      value: totalXBEVoteLocked,
    },
    {
      label: 'Of all circulating XBE',
      value: `${ofAllCirculatingXBE}%`,
    },
    {
      label: 'Total VeXBE',
      value: Number(totalVeXBE).toFixed(3),
    },
    {
      label: 'Average Lock Time',
      value: `${averageLockTime} Months`,
    },
  ];

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isLoading = useMemo(() => {
    if (metamaskStatus !== MetamaskStatus.ADDRESS_SELECTED) {
      return false;
    }

    if(isFirstLoad) {
      if (getBlockLockupInfoStatus === RequestStatus.SUCCESS) {
        setIsFirstLoad(false);
        return false;
      }
      return true;
    }

    return false;
  }, [getBlockLockupInfoStatus, isFirstLoad, metamaskStatus]);

  const fixedStartingVotingPower = Number(web3.utils.fromWei(startingVotingPower)).toFixed(2);

  return (
    <BlockWrapper
      tag="div"
      className={styles.wrapper}
    >
      <div className={styles.lockupBlock}>
        <Preloader isLoading={isLoading}>
          <RequirementWalletProvider>
            <BlockWithList
              className={cx(
                styles.blockWithList,
                metamaskStatus !== MetamaskStatus.ADDRESS_SELECTED && styles.metamaskNoConnect,
              )}
              title="ADD XBE to lockup"
              list={XBELockupList}
            />
            <DefaultBlock
              withContainer={false}
              tag="div"
              theme="darkGrey"
              className={cx(
                styles.textBlock,
                metamaskStatus !== MetamaskStatus.ADDRESS_SELECTED && styles.metamaskNoConnect,
              )}
            >
              <Text size="small">
                Your starting voting power will be: <b> {fixedStartingVotingPower} veXBE </b>
              </Text>
            </DefaultBlock>
          </RequirementWalletProvider>
        </Preloader>
      </div>
      <DepositForm className={styles.depositBlock} />
    </BlockWrapper>
  );
};

export default Deposit;

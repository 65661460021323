import React, { FC, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { web3Selector, useShallowSelector } from 'store/selectors';
import { web3SetStateAction } from 'store/web3/actions';
import { CHAIN_ID } from 'appConstants';
import {
  RequirementWallet, handleMetamaskConnect, MetamaskStatus, setNotification,
} from '@workstream/shared';

type Props = {
  className?: string,
};

const RequirementWalletProvider: FC<PropsWithChildren<Props>> = ({
  children,
  className,
}) => {
  const metamaskStatus = useShallowSelector(web3Selector.getProp('metamaskStatus'));
  const dispatch = useDispatch();

  return (
    <RequirementWallet
      className={className}
      isConnected={metamaskStatus === MetamaskStatus.ADDRESS_SELECTED}
      onConnect={() => handleMetamaskConnect((address, chainID) => {
        dispatch(web3SetStateAction({
          address,
          metamaskStatus: address ? MetamaskStatus.ADDRESS_SELECTED : MetamaskStatus.AVAILABLE,
        }));
        if (chainID !== CHAIN_ID) {
          setNotification({
            type: 'error',
            message: 'Please select ETH mainnet as your network',
          });
          dispatch(web3SetStateAction({
            address: '',
            metamaskStatus: MetamaskStatus.NOT_AVAILABLE,
          }));
        }
      })}
    >
      {children}
    </RequirementWallet>
  );
};

export default RequirementWalletProvider;

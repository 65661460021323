import { HandlerFn } from 'store/types';
import { CrvConvertingActionTypes } from 'store/crvConverting/actionTypes';
import { CrvConvertingState } from 'store/crvConverting/types';
import { crvConvertingSetStateAction, crvConvertingSetStatusAction } from 'store/crvConverting/actions';

type CrvConvertingActionFn<F extends (...args: any) => any>
  = HandlerFn<CrvConvertingState, ReturnType<F>>;

const setState: CrvConvertingActionFn<typeof crvConvertingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: CrvConvertingActionFn<typeof crvConvertingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const CRV_CONVERTING_HANDLERS = {
  [CrvConvertingActionTypes.SET_STATE]: setState,
  [CrvConvertingActionTypes.SET_STATUS]: setStatus,
};

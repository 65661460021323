import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import {
  MetamaskStatus,
  PlatformHeaderBlock, Preloader, RequestStatus, Text,
} from '@workstream/shared';
import { BlockWithList } from 'components';
import { RequirementWalletProvider } from 'containers';
import { useDispatch, useSelector } from 'react-redux';
import { BlockWithListProps } from 'components/BlockWithList';
import { xbeStakingGetBlockInfoAction } from 'store/xbeStaking/actions';
import {
  meSelector, useShallowSelector, web3Selector, xbeStakingSelector,
} from 'store/selectors';
import BigNumber from 'bignumber.js';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import cx from 'classnames';
import styles from './styles.module.scss';
import Stake from './Stake';
import Withdraw from './Withdraw';

type Props = {
  className?: string,
};

const XBEStakingForm: FC<Props> = ({
  className,
}) => {
  const { votingStakingRewards: balance } = useShallowSelector(meSelector.getProp('balances'));
  const boostLevel = useShallowSelector(meSelector.getProp('boostLevelPercent'));
  const { totalSupply } = useShallowSelector(xbeStakingSelector.getState);

  const metamaskStatus = useSelector(web3Selector.getProp('metamaskStatus'));
  const getBlockXbeStakingInfo =
    useSelector(xbeStakingSelector.getStatus(XbeStakingActionTypes.GET_BLOCK_INFO));

  const list: BlockWithListProps['list'] = useMemo(() => {
    const mePercentOfTotalStaked = () => {
      if (totalSupply === '0') {
        return '0';
      }

      const mePercentOfTotalStakedBN = new BigNumber(balance).div(totalSupply).toNumber();

      return Number.isNaN(mePercentOfTotalStakedBN) ? '0' : (mePercentOfTotalStakedBN * 100).toFixed(2);
    };
    return [
      {
        label: 'Your staked assets',
        value: Number(balance).toFixed(2),
      },
      {
        label: 'Your % of total staked',
        value: `${mePercentOfTotalStaked()}%`,
      },
    ];
  }, [balance, totalSupply]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (metamaskStatus === MetamaskStatus.ADDRESS_SELECTED) {
      dispatch(xbeStakingGetBlockInfoAction());
    }
  }, [dispatch, metamaskStatus]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isLoading = useMemo(() => {
    if (metamaskStatus !== MetamaskStatus.ADDRESS_SELECTED) {
      return false;
    }

    if(isFirstLoad) {
      if (getBlockXbeStakingInfo === RequestStatus.SUCCESS) {
        setIsFirstLoad(false);
        return false;
      }
      return true;
    }

    return false;
  }, [getBlockXbeStakingInfo, isFirstLoad, metamaskStatus]);

  return (
    <div className={cx(styles.container, className)}>
      <Preloader isLoading={isLoading}>
        <RequirementWalletProvider>
          <PlatformHeaderBlock
            backgroundColor="blue"
            icon="xbe"
            leftText="XBE"
            rightText="STAKING"
            classRightText={styles.headerRight}
          />
          <BlockWithList
            theme="whiteShadow"
            className={styles.bottomBlock}
            classNameLabel={styles.listLabel}
            list={list}
          >
            <div>
              <div className={styles.option}>
                <Text className={styles.optionLabel} size="small" color="secondary">Boosts Levels:</Text>
                <Text className={styles.optionValue} size="small">{boostLevel}%</Text>
              </div>

              <div className={styles.cols}>
                <Stake className={styles.col} />
                <Withdraw className={styles.col} />
              </div>
            </div>
          </BlockWithList>
        </RequirementWalletProvider>
      </Preloader>
    </div>
  );
};

export default XBEStakingForm;

import {
  CVX_CRV_VAULT_CONTRACT_ADDRESS,
  CVX_VAULT_CONTRACT_ADDRESS,
  SUSHI_VAULT_CONTRACT_ADDRESS,
  VAULT_EURT_CONTRACT_ADDRESS,
  VAULT_IRON_BANK_CONTRACT_ADDRESS,
  VAULT_ST_ETH_CONTRACT_ADDRESS, VAULT_FRAX_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import {
  cvxCrvVaultAbi, cvxVaultAbi, sushiVaultAbi, vaultEurtAbi,
  vaultIronBankAbi, vaultStEthAbi, vaultFraxAbi,
} from 'assets/abi';
import { Unit } from 'web3-utils';

export enum ClaimTokens {
  SUSHI = 'lp',
  CVX = 'CVX',
  CVX_CRV = 'CVX_CRV',
  IRON_BANK = 'ironbank',
  FRAX = 'frax',
  ST_ETH = 'stEth',
  EURT = 'eurt',
}

export const ClaimPools: Record<ClaimTokens, string> = {
  [ClaimTokens.SUSHI]: 'sushi-vault',
  [ClaimTokens.CVX]: 'cvx-vault',
  [ClaimTokens.CVX_CRV]: 'cvx-crv-vault',
  [ClaimTokens.IRON_BANK]: 'ironbank-vault',
  [ClaimTokens.FRAX]: 'frax-vault',
  [ClaimTokens.ST_ETH]: 'st-eth-vault',
  [ClaimTokens.EURT]: 'eurt-vault',
};

export const claimTokensAddress: Record<ClaimTokens, string> = {
  [ClaimTokens.SUSHI]: SUSHI_VAULT_CONTRACT_ADDRESS,
  [ClaimTokens.CVX]: CVX_VAULT_CONTRACT_ADDRESS,
  [ClaimTokens.CVX_CRV]: CVX_CRV_VAULT_CONTRACT_ADDRESS,
  [ClaimTokens.IRON_BANK]: VAULT_IRON_BANK_CONTRACT_ADDRESS,
  [ClaimTokens.FRAX]: VAULT_FRAX_CONTRACT_ADDRESS,
  [ClaimTokens.ST_ETH]: VAULT_ST_ETH_CONTRACT_ADDRESS,
  [ClaimTokens.EURT]: VAULT_EURT_CONTRACT_ADDRESS,
};

export const claimTokensAbi: Record<ClaimTokens, any> = {
  [ClaimTokens.SUSHI]: sushiVaultAbi,
  [ClaimTokens.CVX]: cvxVaultAbi,
  [ClaimTokens.CVX_CRV]: cvxCrvVaultAbi,
  [ClaimTokens.IRON_BANK]: vaultIronBankAbi,
  [ClaimTokens.FRAX]: vaultFraxAbi,
  [ClaimTokens.ST_ETH]: vaultStEthAbi,
  [ClaimTokens.EURT]: vaultEurtAbi,
};

export const claimTokensDecimals: Record<ClaimTokens, Unit> = {
  [ClaimTokens.SUSHI]: 'ether',
  [ClaimTokens.CVX]: 'ether',
  [ClaimTokens.CVX_CRV]: 'ether',
  [ClaimTokens.IRON_BANK]: 'ether',
  [ClaimTokens.FRAX]: 'ether',
  [ClaimTokens.ST_ETH]: 'ether',
  [ClaimTokens.EURT]: 'ether',
};

import React, { createElement, } from 'react';
import cx from 'classnames';
import { H5, Label, Text, } from "./..";
import styles from './styles.module.scss';
const BlockWrapper = ({ children, tag = 'section', className, withContainer = true, title, label, id, description, descriptionClassName, }) => createElement(tag, {
    className: cx(withContainer && styles.section, className),
    id,
}, (React.createElement(React.Fragment, null,
    (title || label) && (React.createElement("div", { className: styles.header },
        title && React.createElement(H5, { uppercase: true, color: "secondary", className: styles.title }, title),
        label && React.createElement(Label, { color: "teal", title: label, className: styles.label }))),
    description && React.createElement(Text, { className: cx(styles.description, descriptionClassName), color: "secondary", size: "small" }, description),
    children)));
export default BlockWrapper;

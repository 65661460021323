import { put, takeLeading } from 'redux-saga/effects';
import {
  getContract, MAX_UINT_256, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';
import { StakeCurveLpActionTypes } from 'store/stakeCurveLp/actionTypes';
import {
  stakeCurveLpApproveAction,
  stakeCurveLpGetAllowanceAction,
  stakeCurveLpSetSubmitStatusAction,
} from 'store/stakeCurveLp/actions';
import { stakeCurveLpTokensAbi, stakeCurveLpTokensAddress, stakeCurveLpVaultTokensAddress } from 'store/stakeCurveLp/constants';

function* stakeCurveLpApproveSaga(
  { payload: { token, value } }: ReturnType<typeof stakeCurveLpApproveAction>,
) {
  try {
    yield put(stakeCurveLpSetSubmitStatusAction({ [token]: WriteContractStatus.APPROVING }));
    const address: string = yield selectAddress();

    const contract = getContract(
      stakeCurveLpTokensAbi[token],
      stakeCurveLpTokensAddress[token],
    );

    const approveCount = Number(value) > 0 ? MAX_UINT_256.toFixed() : web3.utils.toWei('0');

    yield contract
      .methods
      .approve(stakeCurveLpVaultTokensAddress[token], approveCount)
      .send({
        from: address,
      });

    yield put(stakeCurveLpGetAllowanceAction(token));
    yield put(stakeCurveLpSetSubmitStatusAction({ [token]: WriteContractStatus.APPROVED }));

    setNotification({ type: 'success', message: 'Approving!' });
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(stakeCurveLpSetSubmitStatusAction({ [token]: WriteContractStatus.APPROVED_ERROR }));
  }
}

export function* approveSaga() {
  yield takeLeading(
    StakeCurveLpActionTypes.APPROVE,
    stakeCurveLpApproveSaga,
  );
}

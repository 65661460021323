import { HandlerFn } from 'store/types';
import { StEthStakingActionTypes } from 'store/stEthStaking/actionTypes';
import { StEthStakingState } from 'store/stEthStaking/types';
import { stEthStakingSetStateAction, stEthStakingSetStatusAction } from 'store/stEthStaking/actions';

type StEthStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<StEthStakingState, ReturnType<F>>;

const setState: StEthStakingActionFn<typeof stEthStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: StEthStakingActionFn<typeof stEthStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const ST_ETH_STAKING_HANDLERS = {
  [StEthStakingActionTypes.SET_STATE]: setState,
  [StEthStakingActionTypes.SET_STATUS]: setStatus,
};

import {
  xbeStakingWithdrawAction,
  xbeStakingGetBlockInfoAction,
  xbeStakingSetStatusAction,
} from 'store/xbeStaking/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { votingStakingRewardsAbi } from 'assets/abi';
import { VOTING_STAKING_REWARDS_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { meGetBalancesAction, meGetBoostLevelAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import { selectAddress } from 'store/utils';

function* xbeStakingWithdrawSaga(
  { type, payload }: ReturnType<typeof xbeStakingWithdrawAction>,
) {
  try {
    yield put(xbeStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contract = getContract(votingStakingRewardsAbi, VOTING_STAKING_REWARDS_CONTRACT_ADDRESS);

    yield contract
      .methods
      .withdrawUnbonded(web3.utils.toWei(payload))
      .send({
        from: address,
      });

    yield put(xbeStakingGetBlockInfoAction());
    yield put(meGetBoostLevelAction());
    yield put(meGetBalancesAction(BalancesToken.VOTING_STAKING_REWARDS));
    setNotification({ type: 'success', message: 'Withdraw success!' });
    yield put(xbeStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(xbeStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* withdrawSaga() {
  yield takeLeading(
    XbeStakingActionTypes.WITHDRAW,
    xbeStakingWithdrawSaga,
  );
}

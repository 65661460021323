import { put, takeEvery } from 'redux-saga/effects';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import {
  getContract,
  MAX_UINT_256,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import {
  provideLiquidityApproveTokenAction,
  provideLiquiditySetStatusAction,
  provideLiquidityGetTokenAllowanceAction,
} from 'store/provideLiquidity/actions';
import { xbeAbi } from 'assets/abi';
import { SUSHISWAP_ROUTER_CONTRACT_ADDRESS, XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* provideLiquidityApproveTokenSaga(
  { type, payload }: ReturnType<typeof provideLiquidityApproveTokenAction>,
) {
  try {
    const address: string = yield selectAddress();
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.REQUEST }));
    const contract = getContract(xbeAbi, XBE_CONTRACT_ADDRESS);

    const approveCount = Number(payload) > 0 ? MAX_UINT_256.toFixed() : web3.utils.toWei('0');

    yield contract
      .methods
      .approve(SUSHISWAP_ROUTER_CONTRACT_ADDRESS, approveCount)
      .send({
        from: address,
      });

    yield put(provideLiquidityGetTokenAllowanceAction());
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export default function* listener() {
  yield takeEvery(
    ProvideLiquidityActionTypes.APPROVE_TOKEN,
    provideLiquidityApproveTokenSaga,
  );
}

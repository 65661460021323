import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { BoostActionTypes } from 'store/boost/actionTypes';
import { boostGetBlockLockupInfoAction, boostSetStateAction, boostSetStatusAction } from 'store/boost/actions';
import { RequestStatus, setNotification } from '@workstream/shared';
import { GetXbeLockupBoostResult } from 'store/boost/types';
import { api } from 'utils';

function* boostGetBlockLockupInfoSaga(
  { type }: ReturnType<typeof boostGetBlockLockupInfoAction>,
) {
  try {
    yield put(boostSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const { data }: AxiosResponse<GetXbeLockupBoostResult> = yield api.get('/statistics/xbe/lockup/boost/');

    yield put(boostSetStateAction({
      totalXBEVoteLocked: data.total_votes_locked.toString(),
      ofAllCirculatingXBE: new BigNumber(data.total_locked_xbe_share).toFixed(2),
      totalVeXBE: data.total_locked_xbe_ve_total.toString(),
      averageLockTime: new BigNumber(data.total_locked_xbe_avg_lock_time).div(2592000).toFixed(0),
    }));

    yield put(boostSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(boostSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockLockupInfoSaga() {
  yield takeEvery(
    BoostActionTypes.GET_BLOCK_LOCKUP_INFO,
    boostGetBlockLockupInfoSaga,
  );
}

import React, { FC, useMemo } from 'react';
import { InfoList } from 'components';
import { Props as InfoListProps } from 'components/InfoList';
import {
  CVX_CONTRACT_ADDRESS,
  CVX_VAULT_CONTRACT_ADDRESS,
  CVX_CRV_CONTRACT_ADDRESS,
  CVX_CRV_VAULT_CONTRACT_ADDRESS,
  VOTING_STAKING_REWARDS_CONTRACT_ADDRESS,
  XBE_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  token: string,
  listRewardsContract?: string[],
};

const Info: FC<Props> = ({
  className,
  token,
  listRewardsContract = [],
}) => {
  const list: InfoListProps['list'] = useMemo(() => {
    if (token === 'cvx') {
      return [
        {
          label: 'LP token Address',
          value: CVX_CONTRACT_ADDRESS,
        },
        {
          label: 'Deposit contract address',
          value: CVX_VAULT_CONTRACT_ADDRESS,
        },
        ...listRewardsContract.map((address) => ({
          label: 'Rewards contract address',
          value: address,
        })),
      ];
    }
    if (token === 'cvxCrv') {
      return [
        {
          label: 'LP token Address',
          value: CVX_CRV_CONTRACT_ADDRESS,
        },
        {
          label: 'Deposit contract address',
          value: CVX_CRV_VAULT_CONTRACT_ADDRESS,
        },
        ...listRewardsContract.map((address) => ({
          label: 'Rewards contract address',
          value: address,
        })),
      ];
    }
    return [
      {
        label: 'LP token Address',
        value: XBE_CONTRACT_ADDRESS,
      },
      {
        label: 'Deposit contract address',
        value: VOTING_STAKING_REWARDS_CONTRACT_ADDRESS,
      },
      {
        label: 'Rewards contract address',
        value: XBE_CONTRACT_ADDRESS,
      },
    ];
  }, [listRewardsContract, token]);
  return (
    <div className={className}>
      <InfoList list={list} className={styles.list} />
    </div>
  );
};

export default Info;

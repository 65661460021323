import React, { FC } from 'react';
import cx from 'classnames';
import { SvgIcon, Text } from '@workstream/shared';
import {
  IconReferralExtendedDeposits,
  IconReferralExtendedDepositsTier1,
  IconReferralExtendedDepositsTier2,
  IconReferralExtendedDepositsTier3,
  IconReferralExtendedMembers,
  IconReferralExtendedMembersTier1,
  IconReferralExtendedMembersTier2,
  IconReferralExtendedMembersTier3,
  IconReferralExtendedPendingRewards,
  IconReferralExtendedRewards,
} from 'assets/img';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  type: 'rewards' | 'members' | 'members1' | 'members2' | 'members3' | 'pendingRewards' |
  'deposits' | 'deposits1' | 'deposits2' | 'deposits3',
  value: string,
  label: string,
  disabled?: boolean,
};

const ICONS: Record<Props['type'], string> = {
  rewards: IconReferralExtendedRewards,
  members: IconReferralExtendedMembers,
  members1: IconReferralExtendedMembersTier1,
  members2: IconReferralExtendedMembersTier2,
  members3: IconReferralExtendedMembersTier3,
  pendingRewards: IconReferralExtendedPendingRewards,
  deposits: IconReferralExtendedDeposits,
  deposits1: IconReferralExtendedDepositsTier1,
  deposits2: IconReferralExtendedDepositsTier2,
  deposits3: IconReferralExtendedDepositsTier3,
};

const ReferralExtendedInfoItem: FC<Props> = ({
  className,
  type,
  value,
  label,
  disabled,
}) => (
  <div className={cx(
    styles.container,
    disabled && styles.disabled,
    className,
  )}
  >
    <SvgIcon icon={ICONS[type]} className={styles.icon} />
    <Text
      className={styles.value}
      color="extra"
    >
      {value}
    </Text>
    <Text
      className={styles.label}
      color="secondary"
    >
      {label}
    </Text>
  </div>
);

export default ReferralExtendedInfoItem;

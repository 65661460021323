import React, { FC } from 'react';
import cx from 'classnames';
import { Text } from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  value: string,
  label: string,
};

const ClaimBlockItem: FC<Props> = ({
  className,
  value,
  label,
}) => (
  <div className={cx(styles.container, className)}>
    <Text
      color="extra"
      size="large"
      bold
    >
      {value}
    </Text>
    <Text
      color="secondary"
      size="tinier"
      className={styles.label}
    >
      {label}
    </Text>
  </div>
);

export default ClaimBlockItem;

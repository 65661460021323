import createReducer from 'store/createReducer';
import { LockupInfoState } from 'store/lockupInfo/types';
import { LOCKUP_INFO_HANDLERS } from 'store/lockupInfo/handlers';
import { LockupInfoActionTypes } from 'store/lockupInfo/actionTypes';
import { RequestStatus } from '@workstream/shared';

const initialState: LockupInfoState = {
  dailyApy: '0',
  apyPercentage: '0',
  currentCirculatingSupply: '0',
  totalLockedXBE: '0',
  percentageTotalLockedXBE: '0',
  maxSupply: '0',
  ui: {
    [LockupInfoActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, LOCKUP_INFO_HANDLERS);

import { HandlerFn } from 'store/types';
import { CvxStakingActionTypes } from 'store/cvxStaking/actionTypes';
import { CvxStakingState } from 'store/cvxStaking/types';
import { cvxStakingSetStateAction, cvxStakingSetStatusAction } from 'store/cvxStaking/actions';

type CvxStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<CvxStakingState, ReturnType<F>>;

const setState: CvxStakingActionFn<typeof cvxStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: CvxStakingActionFn<typeof cvxStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const CVX_STAKING_HANDLERS = {
  [CvxStakingActionTypes.SET_STATE]: setState,
  [CvxStakingActionTypes.SET_STATUS]: setStatus,
};

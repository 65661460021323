import {
  cvxCrvStakingDepositAction,
  cvxCrvStakingGetBlockInfoAction,
  cvxCrvStakingSetStateAction,
} from 'store/cvxCrvStaking/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { CvxCrvStakingActionTypes } from 'store/cvxCrvStaking/actionTypes';
import {
  getContract, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { cvxCrvVaultAbi } from 'assets/abi';
import { CVX_CRV_VAULT_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';
import { meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';

function* cvxCrvStakingDepositSaga(
  { payload }: ReturnType<typeof cvxCrvStakingDepositAction>,
) {
  try {
    yield put(cvxCrvStakingSetStateAction({ stakingStatus: WriteContractStatus.WRITING }));
    const address: string = yield selectAddress();
    const contract = getContract(cvxCrvVaultAbi, CVX_CRV_VAULT_CONTRACT_ADDRESS);

    yield contract
      .methods
      .deposit(web3.utils.toWei(payload))
      .send({
        from: address,
      });

    yield put(cvxCrvStakingGetBlockInfoAction());
    yield put(meGetBalancesAction(BalancesToken.CVX_CRV));
    setNotification({ type: 'success', message: 'Deposit success!' });
    yield put(cvxCrvStakingSetStateAction({ stakingStatus: WriteContractStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(cvxCrvStakingSetStateAction({ stakingStatus: WriteContractStatus.ERROR }));
  }
}

export function* depositSaga() {
  yield takeLeading(
    CvxCrvStakingActionTypes.DEPOSIT,
    cvxCrvStakingDepositSaga,
  );
}

import {
  xbeLockupDepositAction,
  xbeLockupGetBlockInfoAction,
  xbeLockupSetStateAction,
} from 'store/xbeLockup/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { XbeLockupActionTypes } from 'store/xbeLockup/actionTypes';
import {
  getContract, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { veXbeAbi } from 'assets/abi';
import { VE_XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import BigNumber from 'bignumber.js';
import { selectAddress } from 'store/utils';
import {
  boostGetBlockDepositInfoAction,
  boostGetBlockLockupInfoAction,
  boostGetStartingVotingPowerAction,
} from 'store/boost/actions';
import { meGetIsLockedXbeAction, meGetBoostLevelAction } from 'store/me/actions';

function* xbeLockupDepositSaga(
  { payload: { value, lockTime } }: ReturnType<typeof xbeLockupDepositAction>,
) {
  try {
    yield put(xbeLockupSetStateAction({ submitStatus: WriteContractStatus.WRITING }));
    const address: string = yield selectAddress();
    const contract = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);

    const time = new BigNumber(lockTime).div(1000).toNumber().toFixed(0);

    yield contract
      .methods
      .createLock(web3.utils.toWei(value), Number(time))
      .send({
        from: address,
      });

    yield put(xbeLockupGetBlockInfoAction());
    yield put(boostGetBlockDepositInfoAction());
    yield put(boostGetBlockLockupInfoAction());
    yield put(boostGetStartingVotingPowerAction());
    yield put(meGetIsLockedXbeAction());
    yield put(meGetBoostLevelAction());
    setNotification({ type: 'success', message: 'Deposit success!' });
    yield put(xbeLockupSetStateAction({ submitStatus: WriteContractStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(xbeLockupSetStateAction({ submitStatus: WriteContractStatus.ERROR }));
  }
}

export function* depositSaga() {
  yield takeLeading(
    XbeLockupActionTypes.DEPOSIT,
    xbeLockupDepositSaga,
  );
}

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
const createHeading = (type) => (_a) => {
    var { children, underline = false, className = '', align = 'left', color = 'primary', uppercase = false } = _a, rest = __rest(_a, ["children", "underline", "className", "align", "color", "uppercase"]);
    const hProps = Object.assign({ className: cx(styles[type], styles[color], className, styles[align], {
            [styles.underline]: underline,
            [styles.uppercase]: uppercase,
        }), children }, rest);
    return React.createElement(type, hProps, children);
};
export const H1 = createHeading('h1');
export const H2 = createHeading('h2');
export const H3 = createHeading('h3');
export const H4 = createHeading('h4');
export const H5 = createHeading('h5');

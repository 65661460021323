import { RequestStatus } from '@workstream/shared';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import { ProvideLiquidityState } from 'store/provideLiquidity/types';

export const provideLiquiditySetStateAction = (payload: Partial<ProvideLiquidityState>) => ({
  type: ProvideLiquidityActionTypes.SET_STATE,
  payload,
});

export const provideLiquiditySetStatusAction = (
  payload: { type: ProvideLiquidityActionTypes, status: RequestStatus },
) => ({
  type: ProvideLiquidityActionTypes.SET_STATUS,
  payload,
});

export const provideLiquidityApproveTokenAction = (payload: string) => ({
  type: ProvideLiquidityActionTypes.APPROVE_TOKEN,
  payload,
});

export const provideLiquidityAddLiquidityAction = (
  payload: { amountEth: string, amountToken: string },
) => ({
  type: ProvideLiquidityActionTypes.ADD_LIQUIDITY,
  payload,
});

export const provideLiquidityAddLiquidityOnlyEthAction = (payload: string) => ({
  type: ProvideLiquidityActionTypes.ADD_LIQUIDITY_ONLY_ETH,
  payload,
});

export const provideLiquidityGetTokenAllowanceAction = () => ({
  type: ProvideLiquidityActionTypes.GET_ALLOWANCE_TOKEN,
});

export const provideLiquidityGetPriceAction = () => ({
  type: ProvideLiquidityActionTypes.GET_PRICE,
});

export const provideLiquidityGetTotalLiquidityAction = () => ({
  type: ProvideLiquidityActionTypes.GET_TOTAL_LIQUIDITY,
});

export const provideLiquidityUnstakeAction = (payload: string) => ({
  type: ProvideLiquidityActionTypes.UNSTAKE,
  payload,
});

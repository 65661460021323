import { HandlerFn } from 'store/types';
import { StatisticsActionTypes } from 'store/statistics/actionTypes';
import { StatisticsState } from 'store/statistics/types';
import {
  statisticsSetStateAction, statisticsSetStatusAction,
} from 'store/statistics/actions';

type StatisticsActionFn<F extends (...args: any) => any>
  = HandlerFn<StatisticsState, ReturnType<F>>;

const setState: StatisticsActionFn<typeof statisticsSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: StatisticsActionFn<typeof statisticsSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const STATISTICS_HANDLERS = {
  [StatisticsActionTypes.SET_STATE]: setState,
  [StatisticsActionTypes.SET_STATUS]: setStatus,
};

import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { ReferralExtendedInfoItem } from 'components';
import { DefaultBlock } from '@workstream/shared';
import { useDispatch } from 'react-redux';
import { referralsInfoGetClaimableRewardsAction, referralsInfoGetStatisticsAction } from 'store/referrals/info/actions';
import { referralsInfoSelector, referralsMeSelector, useShallowSelector } from 'store/selectors';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const ReferralExtendedInfo: FC<Props> = ({
  className,
}) => {
  const dispatch = useDispatch();
  const referralCode = useShallowSelector(referralsMeSelector.getProp('referralCode'));
  const {
    members, claimableRewards, networkDeposit,
  } = useShallowSelector(referralsInfoSelector.getState);

  useEffect(() => {
    if (referralCode) {
      dispatch(referralsInfoGetStatisticsAction());
      dispatch(referralsInfoGetClaimableRewardsAction());
    }
  }, [dispatch, referralCode]);

  return (
    <DefaultBlock className={cx(styles.container, className)} tag="div">
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="rewards"
        value={`$${claimableRewards}`}
        label="Claimable Rewards"
      />
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="members"
        value={members.totalAmount}
        label="My Network Members"
      />
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="members1"
        value={members.tier1}
        label="Tier 1 Members"
      />
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="members2"
        value={members.tier2}
        label="Tier 2 Members"
      />
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="members3"
        value={members.tier3}
        label="Tier 3 Members"
      />
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="pendingRewards"
        value="$0.00"
        label="Pending Rewards"
        disabled
      />
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="deposits"
        value={`$${networkDeposit.totalAmount}`}
        label="My Network Deposits"
      />
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="deposits1"
        value={`$${networkDeposit.tier1}`}
        label="Tier 1 Deposits"
      />
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="deposits2"
        value={`$${networkDeposit.tier2}`}
        label="Tier 2 Deposits"
      />
      <ReferralExtendedInfoItem
        className={styles.referralExtendedInfoItem}
        type="deposits3"
        value={`$${networkDeposit.tier3}`}
        label="Tier 3 Deposits"
      />
    </DefaultBlock>
  );
};

export default ReferralExtendedInfo;

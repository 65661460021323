import { SetStatusPayload } from 'store/types';
import { LockupInfoActionTypes } from 'store/lockupInfo/actionTypes';
import { LockupInfoState } from 'store/lockupInfo/types';

export const lockupInfoSetStateAction = (payload: Partial<LockupInfoState>) => ({
  type: LockupInfoActionTypes.SET_STATE,
  payload,
});

export const lockupInfoSetStatusAction = (
  payload: SetStatusPayload<LockupInfoActionTypes>,
) => ({
  type: LockupInfoActionTypes.SET_STATUS,
  payload,
});

export const lockupInfoGetBlockInfoAction = () => ({
  type: LockupInfoActionTypes.GET_BLOCK_INFO,
});

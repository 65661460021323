import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { setNotification } from "../../utils";
const Copyable = ({ valueToCopy, onCopy = () => { }, children, }) => (React.createElement(CopyToClipboard, { text: valueToCopy, onCopy: () => {
        onCopy();
        setNotification({
            type: 'success',
            message: 'Value copied!',
        });
    } }, children));
export default Copyable;

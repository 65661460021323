import { put, takeEvery } from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import {
  meGetBoostLevelAction, meSetStateAction, meSetStatusAction,
} from 'store/me/actions';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import BigNumber from 'bignumber.js';
import { votingStakingRewardsAbi } from 'assets/abi';
import { VOTING_STAKING_REWARDS_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { minBoost, maxBoost } from 'appConstants';
import { selectAddress } from 'store/utils';

function* meGetBoostLevelSaga(
  { type }: ReturnType<typeof meGetBoostLevelAction>,
) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    const contract = getContract(votingStakingRewardsAbi, VOTING_STAKING_REWARDS_CONTRACT_ADDRESS);
    const boostLevel: string = yield contract
      .methods
      .calculateBoostLevel(address)
      .call();

    const boostLevelNumber = web3.utils.fromWei(boostLevel);

    const boostLevelPercent = (new BigNumber(boostLevelNumber)
      .minus(minBoost))
      .dividedBy(new BigNumber(maxBoost).minus(minBoost))
      .multipliedBy(100)
      .toFixed(0);

    yield put(meSetStateAction({
      boostLevel: boostLevelNumber,
      boostLevelPercent,
    }));
    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBoostLevelSaga() {
  yield takeEvery(
    MeActionTypes.GET_BOOST_LEVEL,
    meGetBoostLevelSaga,
  );
}

import { HandlerFn } from 'store/types';
import { XbeLockupActionTypes } from 'store/xbeLockup/actionTypes';
import { XbeLockupState } from 'store/xbeLockup/types';
import { xbeLockupSetStateAction, xbeLockupSetStatusAction } from 'store/xbeLockup/actions';

type XbeLockupActionFn<F extends (...args: any) => any> = HandlerFn<XbeLockupState, ReturnType<F>>;

const setState: XbeLockupActionFn<typeof xbeLockupSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: XbeLockupActionFn<typeof xbeLockupSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const XBE_LOCKUP_HANDLERS = {
  [XbeLockupActionTypes.SET_STATE]: setState,
  [XbeLockupActionTypes.SET_STATUS]: setStatus,
};

import { fork } from 'redux-saga/effects';
import { getBlockLockupInfoSaga } from './getBlockLockupInfo';
import { getBlockDepositInfoSaga } from './getBlockDepositInfo';
import { approveSaga } from './approve';
import { depositSaga } from './deposit';
import { getStartingVotingPowerSaga } from './getStartingVotingPower';
import { submitLockTimeSaga } from './submitLockTime';

export default [
  fork(getBlockLockupInfoSaga),
  fork(getBlockDepositInfoSaga),
  fork(approveSaga),
  fork(depositSaga),
  fork(getStartingVotingPowerSaga),
  fork(submitLockTimeSaga),
];

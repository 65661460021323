import { RequestStatus } from '@workstream/shared';
import { EurtStakingActionTypes } from 'store/eurtStaking/actionTypes';
import { EurtStakingState } from 'store/eurtStaking/types';

export const eurtStakingSetStateAction = (payload: Partial<EurtStakingState>) => ({
  type: EurtStakingActionTypes.SET_STATE,
  payload,
});

export const eurtStakingSetStatusAction = (
  payload: { type: EurtStakingActionTypes, status: RequestStatus },
) => ({
  type: EurtStakingActionTypes.SET_STATUS,
  payload,
});

export const eurtStakingGetBlockInfoAction = () => ({
  type: EurtStakingActionTypes.GET_BLOCK_INFO,
});

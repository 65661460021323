import { ReferralsMeActionTypes } from 'store/referrals/me/actionTypes';
import { ReferralsMeState } from 'store/referrals/me/types';
import { SetStatusPayload } from 'store/types';

export const referralsMeSetStateAction = (payload: Partial<ReferralsMeState>) => ({
  type: ReferralsMeActionTypes.SET_STATE,
  payload,
});

export const referralsMeSetStatusAction = (payload: SetStatusPayload<ReferralsMeActionTypes>) => ({
  type: ReferralsMeActionTypes.SET_STATUS,
  payload,
});

export const referralsMeRegisterWalletAction = (payload?: string) => ({
  type: ReferralsMeActionTypes.REGISTER_WALLET,
  payload,
});

export const referralsMeGetReferralCodeAction = () => ({
  type: ReferralsMeActionTypes.GET_REFERRAL_CODE,
});

export const referralsMeGetDateRegisteredAction = () => ({
  type: ReferralsMeActionTypes.GET_DATE_REGISTERED,
});

export const referralsMeIncReferralHitAction = (payload: string) => ({
  type: ReferralsMeActionTypes.INC_REFERRAL_HIT,
  payload,
});

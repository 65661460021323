export enum ProvideLiquidityActionTypes {
  SET_STATE = 'PROVIDE_LIQUIDITY.SET_STATE',

  SET_STATUS = 'PROVIDE_LIQUIDITY.SET_STATUS',

  APPROVE_TOKEN = 'PROVIDE_LIQUIDITY.APPROVE_TOKEN',

  ADD_LIQUIDITY = 'PROVIDE_LIQUIDITY.ADD_LIQUIDITY',

  ADD_LIQUIDITY_ONLY_ETH = 'PROVIDE_LIQUIDITY.ADD_LIQUIDITY_ONLY_ETH',

  GET_ALLOWANCE_TOKEN = 'PROVIDE_LIQUIDITY.GET_ALLOWANCE_TOKEN',

  GET_PRICE = 'PROVIDE_LIQUIDITY.GET_PRICE',

  GET_TOTAL_LIQUIDITY = 'PROVIDE_LIQUIDITY.GET_TOTAL_LIQUIDITY',

  UNSTAKE = 'PROVIDE_LIQUIDITY.UNSTAKE',
}

import { RequestStatus } from '@workstream/shared';
import { XbeLockupActionTypes } from 'store/xbeLockup/actionTypes';
import { XbeLockupState } from 'store/xbeLockup/types';

export const xbeLockupSetStateAction = (payload: Partial<XbeLockupState>) => ({
  type: XbeLockupActionTypes.SET_STATE,
  payload,
});

export const xbeLockupSetStatusAction = (
  payload: { type: XbeLockupActionTypes, status: RequestStatus },
) => ({
  type: XbeLockupActionTypes.SET_STATUS,
  payload,
});

export const xbeLockupGetBlockInfoAction = () => ({
  type: XbeLockupActionTypes.GET_BLOCK_INFO,
});

export const xbeLockupDepositAction = (payload: { value: string, lockTime: string }) => ({
  type: XbeLockupActionTypes.DEPOSIT,
  payload,
});

export const xbeLockupUnlockAction = () => ({
  type: XbeLockupActionTypes.UNLOCK,
});

export default {
  stake: {
    root: '/stake',
    title: 'Stake',
  },
  claim: {
    root: '/claim',
    title: 'Claim',
  },
  lockup: {
    root: '/lockup',
    title: 'XBE & veXBE',
  },
  referrals: {
    root: '/referrals',
    title: 'Referrals',
  },
  stakeCurve: {
    root: '/stake/Curve',
    title: 'STAKE CURVE LP TOKENS',
  },
  convertCrv: {
    root: '/stake/convertCRV',
    title: 'CONVERT CRV',
  },
  stakeOther: {
    root: '/stake/rewards',
    title: 'Stake CVX, cvxCRV & XBE',
  },
};

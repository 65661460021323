import { CurveLpToken } from 'store/stakeCurveLp/constants';
import {
  IconCurveLpEurt, IconCurveLpIronbank, IconCurveLpStEth, IconCurveLpFrax,
} from 'assets/img';

export const STAKE_ICON_INPUT: Record<CurveLpToken, string> = {
  [CurveLpToken.FRAX]: IconCurveLpFrax,
  [CurveLpToken.IRON_BANK]: IconCurveLpIronbank,
  [CurveLpToken.ST_ETH]: IconCurveLpStEth,
  [CurveLpToken.EURT]: IconCurveLpEurt,
};

export const UNSTAKE_ICON_INPUT: Record<CurveLpToken, string> = {
  [CurveLpToken.FRAX]: IconCurveLpFrax,
  [CurveLpToken.IRON_BANK]: IconCurveLpIronbank,
  [CurveLpToken.ST_ETH]: IconCurveLpStEth,
  [CurveLpToken.EURT]: IconCurveLpEurt,
};

export const STAKE_TOKEN_NAME: Record<CurveLpToken, string> = {
  [CurveLpToken.FRAX]: 'frax',
  [CurveLpToken.IRON_BANK]: 'ironBank',
  [CurveLpToken.ST_ETH]: 'stEth',
  [CurveLpToken.EURT]: 'eurt',
};

export const UNSTAKE_TOKEN_NAME: Record<CurveLpToken, string> = {
  [CurveLpToken.FRAX]: 'frax',
  [CurveLpToken.IRON_BANK]: 'ironBank',
  [CurveLpToken.ST_ETH]: 'stEth',
  [CurveLpToken.EURT]: 'eurt',
};

export const STAKE_TOKEN_DESCRIPTION: Record<CurveLpToken, string> = {
  [CurveLpToken.FRAX]: 'Deposit liquidity into the <a href="https://curve.fi/frax/deposit" target="_blank" rel="noreferrer noopener">Curve FRAX pool</a> (without staking in the Curve gauge), and then stake your fraxCrv tokens here to earn XBE, CVX and CRV.',
  [CurveLpToken.IRON_BANK]: 'Deposit liquidity into the <a href="https://curve.fi/ib/deposit" target="_blank" rel="noreferrer noopener">Curve Ironbank pool</a> (without staking in the Curve gauge), and then stake your curve LP tokens tokens here to earn XBE, CVX and CRV.',
  [CurveLpToken.ST_ETH]: 'Deposit liquidity into the <a href="https://curve.fi/steth/deposit" target="_blank" rel="noreferrer noopener">Curve stETH pool</a> (without staking in the Curve gauge), and then stake your usdnCrv tokens here to earn XBE, CVX and CRV.',
  [CurveLpToken.EURT]: 'Deposit liquidity into the <a href="https://curve.fi/eurt/deposit" target="_blank" rel="noreferrer noopener">Curve EURT pool</a> (without staking in the Curve gauge), and then stake your usdnCrv tokens here to earn XBE, CVX and CRV.',
};

const totalOf21FeesLine = 'A total of 21% fees are deducted from rewards earned by your liquidity boosted by XBE and Convex. For a full breakdown on fees please visit  <a href="https://docs.xbe.finance/products/xb-hive/xbe-hive-fee-breakdown" target="_blank" rel="noreferrer noopener">XBE Fees</>';

export const STAKE_TOKEN_NOTE: Record<CurveLpToken, string> = {
  [CurveLpToken.FRAX]: totalOf21FeesLine,
  [CurveLpToken.IRON_BANK]: totalOf21FeesLine,
  [CurveLpToken.ST_ETH]: totalOf21FeesLine,
  [CurveLpToken.EURT]: totalOf21FeesLine,
};

export const UNSTAKE_TOKEN_DESCRIPTION: Record<CurveLpToken, string> = {
  [CurveLpToken.FRAX]: 'Please NOTE: This section will only unstake your curve.fi LP tokens from XBE.finance. Please go directly to the curve.fi pools to withdraw your liquidity should you so desire.',
  [CurveLpToken.IRON_BANK]: 'Please NOTE: This section will only unstake your curve.fi LP tokens from XBE.finance. Please go directly to the curve.fi pools to withdraw your liquidity should you so desire.',
  [CurveLpToken.ST_ETH]: 'Please NOTE: This section will only unstake your curve.fi LP tokens from XBE.finance. Please go directly to the curve.fi pools to withdraw your liquidity should you so desire.',
  [CurveLpToken.EURT]: 'Please NOTE: This section will only unstake your curve.fi LP tokens from XBE.finance. Please go directly to the curve.fi pools to withdraw your liquidity should you so desire.',
};

import { Web3ActionTypes } from './actionTypes';
import { Web3State } from './types';

export const web3SetStateAction = (payload: Partial<Web3State>) => ({
  type: Web3ActionTypes.SET_STATE,
  payload,
});

export const web3LogoutAction = () => ({
  type: Web3ActionTypes.LOGOUT,
});

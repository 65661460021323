import { RequestStatus, WriteContractStatus } from '@workstream/shared';
import createReducer from 'store/createReducer';
import { StakeCurveLpState } from './types';
import { STAKE_CURVE_LP_HANDLERS } from './handlers';
import { CurveLpToken } from './constants';

const initialState: StakeCurveLpState = {
  allowances: {
    [CurveLpToken.FRAX]: '0',
    [CurveLpToken.IRON_BANK]: '0',
    [CurveLpToken.ST_ETH]: '0',
    [CurveLpToken.EURT]: '0',
  },
  balances: {
    [CurveLpToken.FRAX]: '0',
    [CurveLpToken.IRON_BANK]: '0',
    [CurveLpToken.ST_ETH]: '0',
    [CurveLpToken.EURT]: '0',
  },
  balancesVaults: {
    [CurveLpToken.FRAX]: '0',
    [CurveLpToken.IRON_BANK]: '0',
    [CurveLpToken.ST_ETH]: '0',
    [CurveLpToken.EURT]: '0',
  },
  balancesVaultsOld: {
    [CurveLpToken.FRAX]: '0',
  },
  uiWithdrawStatus: {
    [CurveLpToken.FRAX]: RequestStatus.INIT,
    [CurveLpToken.IRON_BANK]: RequestStatus.INIT,
    [CurveLpToken.ST_ETH]: RequestStatus.INIT,
    [CurveLpToken.EURT]: RequestStatus.INIT,
  },
  listRewardsContractsAddresses: {
    [CurveLpToken.FRAX]: [],
    [CurveLpToken.IRON_BANK]: [],
    [CurveLpToken.ST_ETH]: [],
    [CurveLpToken.EURT]: [],
  },
  lpTokensAddresses: {
    [CurveLpToken.FRAX]: '',
    [CurveLpToken.IRON_BANK]: '',
    [CurveLpToken.ST_ETH]: '',
    [CurveLpToken.EURT]: '',
  },
  uiSubmitStatus: {
    [CurveLpToken.FRAX]: WriteContractStatus.INIT,
    [CurveLpToken.IRON_BANK]: WriteContractStatus.INIT,
    [CurveLpToken.ST_ETH]: WriteContractStatus.INIT,
    [CurveLpToken.EURT]: WriteContractStatus.INIT,
  },
  ui: {},
};

export default createReducer(initialState, STAKE_CURVE_LP_HANDLERS);

import React, { useCallback, useEffect, useState, } from 'react';
import { Text } from "./..";
import cx from 'classnames';
import styles from './styles.module.scss';
const RadioGroup = ({ className, classNameLabel, classNameText, nameGroup, options, callback, theme = 'lightBlue', }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const result = [];
        options.forEach((item, index) => {
            result.push({
                name: item,
                isChecked: index === 0,
            });
        });
        setData(result);
    }, []);
    const handler = useCallback((event) => {
        const tag = event.target.getAttribute('data-tag');
        const result = data.map(({ name }) => ({
            name,
            isChecked: name === tag,
        }));
        callback(tag);
        setData(result);
    }, [data]);
    return (React.createElement("div", { className: cx(className, styles.radioGroup, styles[theme]) }, data.map((item) => (React.createElement("label", { className: cx(styles.label, classNameLabel), htmlFor: `${nameGroup}${item.name}`, key: item.name },
        React.createElement("input", { onChange: handler, className: styles.input, "data-tag": item.name, type: "radio", id: `${nameGroup}${item.name}`, name: nameGroup, checked: item.isChecked }),
        React.createElement(Text, { className: cx(styles.text, classNameText), tag: "span", size: "big" }, item.name))))));
};
export default RadioGroup;

import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClaimBlock } from 'components';
import {
  BlockWrapper,
  CoinCrv,
  CoinCvx,
  CoinXbe,
  handleMetamaskConnect,
  IconReward,
  MetamaskStatus,
  RequestStatus,
  Text,
} from '@workstream/shared';
import {
  ClaimAll, ClaimRow, StakedXBE, SushiswapClaim,
} from 'containers';
import {
  claimClaimAction,
  claimClaimCvxAction,
  claimClaimCvxCrvAction,
  claimClaimReferralAction,
  claimGetStakeAction,
} from 'store/claim/actions';
import {
  xbeClaimClaimAction, xbeClaimGetBlockInfoAction,
} from 'store/xbeClaim/actions';
import { referralsInfoGetStatisticsAction } from 'store/referrals/info/actions';
import { ClaimTokens } from 'store/claim/constants';
import { ClaimActionTypes } from 'store/claim/actionTypes';
import { XbeClaimActionTypes } from 'store/xbeClaim/actionTypes';
import {
  claimSelector, meSelector,
  referralsInfoSelector,
  useShallowSelector,
  web3Selector,
  xbeClaimSelector,
} from 'store/selectors';
import { web3SetStateAction } from 'store/web3/actions';
import { IconCurveLpFrax } from 'assets/img';
import BigNumber from 'bignumber.js';
import styles from './styles.module.scss';

const Claim = () => {
  const dispatch = useDispatch();
  const { cvxcrv: cvxcrvApy } = useShallowSelector(meSelector.getProp('curveApys'));

  const metamaskStatus = useShallowSelector(web3Selector.getProp('metamaskStatus'));

  const {
    CVX: cvx, CVX_CRV: cvxCrv, ironbank, eurt, frax, stEth,
  } = useShallowSelector(claimSelector.getState);
  const {
    CVX: claimCvxStatus, CVX_CRV: claimCvxCrvStatus,
    frax: claimFraxStatus,
  } =
    useShallowSelector(claimSelector.getProp('uiClaim'));
  const { claimableRewards } = useShallowSelector(referralsInfoSelector.getState);
  const claimReferralStatus = useSelector(
    claimSelector.getStatus(ClaimActionTypes.CLAIM_REFERRAL),
  );
  const xbeClaimStatus = useShallowSelector(xbeClaimSelector.getStatus(XbeClaimActionTypes.CLAIM));
  const {
    deposits: depositsXbe,
    earned: earnedXbe,
    apr: aprXbe,
  } = useShallowSelector(xbeClaimSelector.getState);

  const metamaskAddress = useShallowSelector(web3Selector.getProp('address'));

  useEffect(() => {
    dispatch(claimGetStakeAction(ClaimTokens.CVX));
    dispatch(claimGetStakeAction(ClaimTokens.CVX_CRV));

    dispatch(claimGetStakeAction(ClaimTokens.IRON_BANK));
    dispatch(claimGetStakeAction(ClaimTokens.ST_ETH));
    dispatch(claimGetStakeAction(ClaimTokens.EURT));
    dispatch(claimGetStakeAction(ClaimTokens.FRAX));

    dispatch(referralsInfoGetStatisticsAction());
    dispatch(xbeClaimGetBlockInfoAction());
  }, [dispatch, metamaskAddress]);

  const onFraxClaim = useCallback(() => {
    dispatch(claimClaimAction({ token: ClaimTokens.FRAX, claimUnderlying: true }));
  }, [dispatch]);

  const onCvxCrvClaim = useCallback(() => {
    dispatch(claimClaimCvxCrvAction());
  }, [dispatch]);

  const onCvxClaim = useCallback(() => {
    dispatch(claimClaimCvxAction());
  }, [dispatch]);

  const handleReferralClaim = useCallback(() => {
    dispatch(claimClaimReferralAction());
  }, [dispatch]);

  const handleClaimXbeClick = useCallback(() => {
    dispatch(xbeClaimClaimAction());
  }, [dispatch]);

  const onConnectMetamask = useCallback(() => handleMetamaskConnect((address) => {
    dispatch(web3SetStateAction({
      address,
      metamaskStatus: address ? MetamaskStatus.ADDRESS_SELECTED : MetamaskStatus.AVAILABLE,
    }));
  }), [dispatch]);

  const isMetamaskConnect = useMemo(
    () => metamaskStatus === MetamaskStatus.ADDRESS_SELECTED,
    [metamaskStatus],
  );

  const buttonClaimCvxCrvText = useMemo(() => {
    if (!isMetamaskConnect) {
      return 'Connect Metamask';
    }

    return claimCvxCrvStatus === RequestStatus.REQUEST ? 'Claim...' : 'Claim';
  }, [isMetamaskConnect, claimCvxCrvStatus]);

  const buttonClaimCvxText = useMemo(() => {
    if (!isMetamaskConnect) {
      return 'Connect Metamask';
    }

    return claimCvxStatus === RequestStatus.REQUEST ? 'Claim...' : 'Claim';
  }, [isMetamaskConnect, claimCvxStatus]);

  const crv = useMemo(() => {
    const earned = new BigNumber(ironbank.earned)
      .plus(eurt.earned)
      .plus(frax.earned)
      .plus(stEth.earned)
      .toFixed(2);
    const apr = new BigNumber(frax.apr || '0')
      .toFixed(2);
    const deposits = new BigNumber(ironbank.deposits || '0')
      .plus(eurt.deposits || '0')
      .plus(frax.deposits || '0')
      .plus(stEth.deposits || '0')
      .toFixed(2);
    return {
      earned,
      apr,
      deposits,
    };
  }, [eurt, ironbank, frax, stEth]);

  const data: ClaimRow[] = useMemo(() => [
    {
      pool: {
        name: 'CRV',
        icon: CoinCrv,
      },
      earned: `$${Number(crv.earned).toFixed(2)}`,
      apr: {
        value: `${Number(crv.apr).toFixed(2)}%`,
      },
      deposit: `$${Number(crv.deposits).toFixed(2)}`,
      childrenRow: [
        {
          pool: {
            name: 'FRAX',
            icon: IconCurveLpFrax,
          },
          onClaim: isMetamaskConnect ? onFraxClaim : onConnectMetamask,
          buttonClaimDisabled: claimFraxStatus === RequestStatus.REQUEST,
        },
        {
          pool: {
            name: 'cvxCRV',
            icon: CoinCvx,
          },
          earned: `$${Number(cvxCrv.earned).toFixed(2)}`,
          apr: {
            value: `${Number(cvxCrv.apr).toFixed(2)}%`,
            proj: cvxcrvApy.proj,
            boost: cvxcrvApy.crvBoost,
          },
          deposit: `$${Number(cvxCrv.deposits).toFixed(2)}`,
          onClaim: isMetamaskConnect ? onCvxCrvClaim : onConnectMetamask,
          buttonClaimDisabled: claimCvxCrvStatus === RequestStatus.REQUEST,
        },
      ],
    },
    {
      pool: {
        name: 'CVX',
        icon: CoinCvx,
      },
      earned: `$${Number(cvx.earned).toFixed(2)}`,
      apr: {
        value: `${Number(cvx.apr).toFixed(2)}%`,
      },
      deposit: `$${Number(cvx.deposits).toFixed(2)}`,
      onClaim: isMetamaskConnect ? onCvxClaim : onConnectMetamask,
      // buttonClaimDisabled: claimCvxStatus === RequestStatus.REQUEST,
      buttonClaimDisabled: true,
    },
    {
      pool: {
        name: 'XBE',
        icon: CoinXbe,
      },
      earned: `$${Number(earnedXbe).toFixed(2)}`,
      apr: {
        value: `${Number(aprXbe).toFixed(2)}%`,
      },
      deposit: `$${Number(depositsXbe).toFixed(2)}`,
      onClaim: handleClaimXbeClick,
      buttonClaimDisabled: xbeClaimStatus === RequestStatus.REQUEST,
    },
  ], [
    aprXbe, claimCvxCrvStatus, cvx.apr, cvx.deposits, cvx.earned, cvxCrv.apr,
    cvxCrv.deposits, cvxCrv.earned, depositsXbe, earnedXbe, handleClaimXbeClick,
    isMetamaskConnect, onConnectMetamask, onCvxClaim, onCvxCrvClaim,
    xbeClaimStatus, onFraxClaim, claimFraxStatus, cvxcrvApy, crv,
  ]);

  const claimAllEarned = useMemo(() => new BigNumber(crv.earned)
    .plus(cvx.earned)
    .plus(cvxCrv.earned)
    .plus(earnedXbe)
    .toFixed(2), [crv, cvx, cvxCrv, earnedXbe]);

  const averageApr = useMemo(() => new BigNumber(crv.apr ? crv.apr : '0')
    .plus(cvx.apr ? cvx.apr : '0')
    .plus(cvxCrv.apr ? cvxCrv.apr : '0')
    .plus(aprXbe)
    .div(4)
    .toFixed(2), [crv, cvx, cvxCrv, aprXbe]);

  return (
    <BlockWrapper
      className={`container ${styles.container}`}
      tag="div"
    >
      <Text
        className={styles.text}
        color="extra"
        size="large"
        bold
      >
        Claim earnings from strategies
      </Text>

      <ClaimAll
        className={styles.claimBlock}
        data={data}
        earned={claimAllEarned}
        averageApr={averageApr}
      />
      <StakedXBE
        className={styles.claimBlock}
        isShowStakedSushi={false}
      />
      <ClaimBlock
        className={styles.claimBlock}
        icon={IconReward}
        title="Referral commissions"
        earned={`$${Number(claimableRewards).toFixed(2)}`}
        onClaim={isMetamaskConnect ? handleReferralClaim : onConnectMetamask}
        buttonClaimDisabled={claimReferralStatus === RequestStatus.REQUEST}
        buttonClaimText={isMetamaskConnect ? 'Claim' : 'Connect Metamask'}
      />
      <ClaimBlock
        className={styles.claimBlock}
        icon={CoinCvx}
        title="Staked cvxCRV"
        earned={`$${Number(cvxCrv.earned).toFixed(2)}`}
        averageAPR={`${Number(cvxCrv.apr).toFixed(2)}%`}
        onClaim={isMetamaskConnect ? onCvxCrvClaim : onConnectMetamask}
        buttonClaimDisabled={claimCvxCrvStatus === RequestStatus.REQUEST}
        buttonClaimText={buttonClaimCvxCrvText}
      />
      <ClaimBlock
        className={styles.claimBlock}
        icon={CoinCvx}
        title="Staked CVX"
        earned={`$${Number(cvx.earned).toFixed(2)}`}
        // averageAPR={`${Number(cvx.apr).toFixed(2)}%`}
        onClaim={isMetamaskConnect ? onCvxClaim : onConnectMetamask}
        // buttonClaimDisabled={claimCvxStatus === RequestStatus.REQUEST}
        buttonClaimDisabled
        buttonClaimText={buttonClaimCvxText}
      />
      <SushiswapClaim className={styles.claimBlock} />
    </BlockWrapper>
  );
};

export default Claim;

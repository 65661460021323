import React, {
  ChangeEvent, FC, useEffect, useMemo, useState, useCallback,
} from 'react';
import {
  Button,
  Dropdown,
  formattersDate,
  MetamaskStatus,
  Preloader,
  RequestStatus,
  Text,
  Tooltip,
  web3,
  WriteContractStatus,
} from '@workstream/shared';
import { BlockWithListProps } from 'components/BlockWithList';
import { BlockWithList, InputWithMaxButton } from 'components';
import cx from 'classnames';
import { Option } from 'react-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  boostDepositAction, boostGetBlockDepositInfoAction, boostSubmitLockTimeAction,
} from 'store/boost/actions';
import { BoostActionTypes } from 'store/boost/actionTypes';
import {
  boostSelector, meSelector, web3Selector, useShallowSelector,
} from 'store/selectors';
import { generateArrMonthsWithDates } from 'utils';
import { RequirementWalletProvider } from 'containers';
import BigNumber from 'bignumber.js';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const DepositForm: FC<Props> = ({
  className,
}) => {
  const { votingStakingRewards: votingStakingRewardsBalance } = useShallowSelector(meSelector.getProp('balances'));
  const metamaskStatus = useSelector(web3Selector.getProp('metamaskStatus'));
  const isLockedXBE = useSelector(meSelector.getProp('isLockedXBE'));
  const {
    submitStatus, myXBELocked, meLockedDateEnd,
  } = useSelector(boostSelector.getState);
  const getBlockDepositInfoStatus =
    useSelector(boostSelector.getStatus(BoostActionTypes.GET_BLOCK_DEPOSIT_INFO));
  const submitLockTimStatus =
    useSelector(boostSelector.getStatus(BoostActionTypes.SUBMIT_LOCK_TIME));
  const dispatch = useDispatch();

  const fixedMyXbeLocked = web3.utils.fromWei(myXBELocked);

  const list: BlockWithListProps['list'] = useMemo(() => {
    const labelsList = [
      {
        label: 'XBE Balance',
        value: new BigNumber(votingStakingRewardsBalance).minus(fixedMyXbeLocked).toFixed(2),
      },
      {
        label: 'My XBE locked',
        value: Number(fixedMyXbeLocked).toFixed(2),
      },
    ];

    if (Number(meLockedDateEnd) !== 0) {
      labelsList.push({
        label: 'Actual unlock date',
        value: formattersDate.format(Number(meLockedDateEnd), 'dd / MM / yy'),
      });
    }

    return labelsList;
  }, [fixedMyXbeLocked, votingStakingRewardsBalance, meLockedDateEnd]);

  useEffect(() => {
    if (metamaskStatus === MetamaskStatus.ADDRESS_SELECTED) {
      dispatch(boostGetBlockDepositInfoAction());
    }
  }, [dispatch, metamaskStatus]);

  const options = useMemo(
    () => generateArrMonthsWithDates(
      new Date(),
      new Date(Number(meLockedDateEnd)),
    ),
    [meLockedDateEnd],
  );

  const [month, setMonth] = useState<Option | undefined>(options[0]);

  useEffect(() => {
    if (getBlockDepositInfoStatus === RequestStatus.SUCCESS) {
      setMonth(options[options.length - 1]);
    }
  }, [getBlockDepositInfoStatus, options]);

  const [value, setValue] = useState('');

  const handlerInput = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const onSubmitLockTimeClick = useCallback(() => {
    if (month !== undefined) dispatch(boostSubmitLockTimeAction(month.value));
  }, [dispatch, month]);

  const onDepositClick = useCallback(() => {
    dispatch(boostDepositAction(value));
  }, [dispatch, value]);

  const isDepositDisabled = useMemo<boolean>(() => [
    !value ||
    new BigNumber(votingStakingRewardsBalance).minus(fixedMyXbeLocked).isLessThan(value),
    !isLockedXBE ||
    submitLockTimStatus === RequestStatus.REQUEST,
    submitStatus === WriteContractStatus.APPROVING ||
    submitStatus === WriteContractStatus.WRITING,
  ].includes(true),
  // eslint-disable-next-line max-len
  [value, votingStakingRewardsBalance, fixedMyXbeLocked, isLockedXBE, submitLockTimStatus, submitStatus]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isLoading = useMemo(() => {
    if (metamaskStatus !== MetamaskStatus.ADDRESS_SELECTED) {
      return false;
    }

    if(isFirstLoad) {
      if (getBlockDepositInfoStatus === RequestStatus.SUCCESS) {
        setIsFirstLoad(false);
        return false;
      }
      return true;
    }

    return false;
  }, [getBlockDepositInfoStatus, isFirstLoad, metamaskStatus]);

  const onMaxClick = useCallback(() => {
    setValue(new BigNumber(votingStakingRewardsBalance).minus(fixedMyXbeLocked).toString());
  }, [fixedMyXbeLocked, votingStakingRewardsBalance]);

  return (
    <Preloader
      isLoading={isLoading}
      className={className}
    >
      <RequirementWalletProvider
        className={className}
      >
        <BlockWithList
          theme="whiteShadow"
          list={list}
          className={cx(
            styles.wrapper,
            className,
            metamaskStatus !== MetamaskStatus.ADDRESS_SELECTED && styles.containerMetamaskNoConnect,
          )}
        >
          <div className={styles.startingWrapper}>
            <Text className={styles.startingLabel} size="small" color="secondary">Unlock Date</Text>
            <Text className={styles.startingDate} size="small">
              {
                  month ?
                    formattersDate.format(Number(month.value), 'dd / MM / yy') :
                    'Campaign end'
                }
            </Text>

            <Tooltip
              content={(
                <Text className={styles.tooltipText}>
                  You are currently at Max lockup period
                </Text>
                )}
              isTextContent
              isOpenHover
              isOutdoorState={options.length !== 0}
            >
              <Dropdown
                options={options}
                value={month}
                onChange={setMonth}
                className={styles.startingDropdown}
                disabled={options.length === 0}
              />
            </Tooltip>
          </div>

          <Text
            color="secondary"
            size="small"
            className={styles.text}
          >
            {/* eslint-disable-next-line max-len */}
            The boost levels shown are for LOCKUP only. Please note that staking while having XBE locked up will reduce your boost
          </Text>

          <Tooltip
            content={<Text className={styles.tooltipText}>XBE is not locked</Text>}
            isTextContent
            isOpenHover
            isOutdoorState={isLockedXBE}
          >
            <Button
              isOutline
              size="mini"
              className={styles.button}
              type="button"
              disabled={[
                !isLockedXBE,
                options.length === 0,
                submitLockTimStatus === RequestStatus.REQUEST,
                submitStatus === WriteContractStatus.APPROVING,
                submitStatus === WriteContractStatus.WRITING,
              ].includes(true)}
              onClick={onSubmitLockTimeClick}
            >
              Add to Boost
            </Button>
          </Tooltip>

          <div className={styles.hr} />

          <div className={styles.inputButtonApprove}>
            <InputWithMaxButton
              value={value}
              onChange={handlerInput}
              className={styles.input}
              onClick={onMaxClick}
              placeholder="0.00 XBE"
            />
          </div>

          <Tooltip
            content={<Text className={styles.tooltipText}>XBE is not locked</Text>}
            isTextContent
            isOpenHover
            isOutdoorState={isLockedXBE}
          >
            <Button
              isOutline
              size="mini"
              onClick={onDepositClick}
              className={styles.button}
              type="submit"
              disabled={isDepositDisabled}
            >
              { submitStatus === WriteContractStatus.WRITING ? 'Processing...' : 'Add to lockup'}
            </Button>
          </Tooltip>
        </BlockWithList>
      </RequirementWalletProvider>
    </Preloader>
  );
};
export default DepositForm;

import { fork } from 'redux-saga/effects';
import { getBalanceSaga } from './getBalance';
import { approveSaga } from './approve';
import { getAllowanceSaga } from './getAllowance';
import { depositSaga } from './deposit';
import { getBalanceVaultSaga } from './getBalanceVault';
import { withdrawSaga } from './withdraw';
import { getRewardsContractsAddressesSaga } from './getRewardsContractsAddresses';
import { getLpTokenAddressSaga } from './getLpTokenAddress';

export default [
  fork(getBalanceSaga),
  fork(approveSaga),
  fork(getAllowanceSaga),
  fork(depositSaga),
  fork(getBalanceVaultSaga),
  fork(withdrawSaga),
  fork(getRewardsContractsAddressesSaga),
  fork(getLpTokenAddressSaga),
];

import { put, takeEvery } from 'redux-saga/effects';
import { RequestStatus, setNotification } from '@workstream/shared';
import { claimClaimAction, claimClaimSushiAction, claimSetStatusAction } from 'store/claim/actions';
import { ClaimActionTypes } from 'store/claim/actionTypes';
import { ClaimTokens } from 'store/claim/constants';
import { sushiSwapLPStakingGetBlockInfoAction } from 'store/sushiSwapLPStaking/actions';
import { meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import { xbeClaimGetBlockInfoAction } from 'store/xbeClaim/actions';

function* claimClaimSushiSaga(
  { type }: ReturnType<typeof claimClaimSushiAction>,
) {
  try {
    yield put(claimSetStatusAction({ type, status: RequestStatus.REQUEST }));
    yield put(claimClaimAction({ token: ClaimTokens.SUSHI, claimUnderlying: false }));
    yield put(sushiSwapLPStakingGetBlockInfoAction());
    yield put(xbeClaimGetBlockInfoAction());
    yield put(meGetBalancesAction(BalancesToken.SUSHI_VAULT));
    yield put(claimSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(claimSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* claimSushiSaga() {
  yield takeEvery(
    ClaimActionTypes.CLAIM_SUSHI,
    claimClaimSushiSaga,
  );
}

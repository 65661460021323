import {
  put,
  takeEvery,
} from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import {
  RequestStatus,
  setNotification,
} from '@workstream/shared';
import {
  meSetStateAction,
  meGetIsSignedAction,
  meSetStatusAction,
} from 'store/me/actions';
import { selectAddress } from 'store/utils';
import firebase from 'utils/firebase';
import {
  getDatabase,
  ref,
  child,
  get,
} from 'firebase/database';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const db = getDatabase(firebase);

function* meGetIsSignedSaga({ type }: ReturnType<typeof meGetIsSignedAction>) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    let isSignedValue = true;
    const dbRef = ref(getDatabase());

    // eslint-disable-next-line no-console
    console.log('here');
    // check firebase if user has signed
    yield get(child(dbRef, `users/${address}`))
      .then((snapshot) => {
        if (!snapshot.exists()) {
          isSignedValue = false;
          // eslint-disable-next-line no-console
          console.log('user not found');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });

    yield put(
      meSetStateAction({
        isSigned: isSignedValue,
      }),
    );
    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (err) {
    setNotification({ type: 'error', message: err.message });
    // eslint-disable-next-line no-console
    console.log('error', err.message);
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getIsSignedSaga() {
  yield takeEvery(MeActionTypes.GET_IS_SIGNED_TCS, meGetIsSignedSaga);
}

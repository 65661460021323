import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { EurtStakingActionTypes } from 'store/eurtStaking/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  eurtStakingSetStateAction,
  eurtStakingSetStatusAction,
  eurtStakingGetBlockInfoAction,
} from 'store/eurtStaking/actions';
import {
  RequestStatus, setNotification,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';

function* eurtStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof eurtStakingGetBlockInfoAction>,
) {
  try {
    yield put(eurtStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const address: string = yield selectAddress();

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(
      `/pools?address=${address}&pool=eurt-vault`,
    );

    yield put(eurtStakingSetStateAction({
      earned: new BigNumber(data.earned).toFixed(2),
      deposits: new BigNumber(data.deposit).toFixed(2),
      tvl: new BigNumber(data.tvl).toFixed(2),
      apr: new BigNumber(data.apr).toFixed(2),
    }));
    yield put(eurtStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(eurtStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    EurtStakingActionTypes.GET_BLOCK_INFO,
    eurtStakingGetBlockInfoSaga,
  );
}

import { HandlerFn } from 'store/types';
import { LockupInfoActionTypes } from 'store/lockupInfo/actionTypes';
import { LockupInfoState } from 'store/lockupInfo/types';
import { lockupInfoSetStateAction, lockupInfoSetStatusAction } from 'store/lockupInfo/actions';

type LockupInfoActionFn<F extends (...args: any) => any> =
  HandlerFn<LockupInfoState, ReturnType<F>>;

const setState: LockupInfoActionFn<typeof lockupInfoSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: LockupInfoActionFn<typeof lockupInfoSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const LOCKUP_INFO_HANDLERS = {
  [LockupInfoActionTypes.SET_STATE]: setState,
  [LockupInfoActionTypes.SET_STATUS]: setStatus,
};

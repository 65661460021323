import { put, takeEvery } from 'redux-saga/effects';
import { StakeCurveLpActionTypes } from 'store/stakeCurveLp/actionTypes';
import {
  getContract, setNotification, web3,
} from '@workstream/shared';
import {
  stakeCurveLpGetBalanceVaultAction,
  stakeCurveLpSetBalanceVaultAction,
  stakeCurveLpSetBalanceVaultOldAction,
} from 'store/stakeCurveLp/actions';
import { selectAddress } from 'store/utils';
import {
  stakeCurveLpVaultTokensAbi, stakeCurveLpVaultTokensAddress, stakeCurveLpVaultOldTokensAddress,
} from 'store/stakeCurveLp/constants';

function* stakeCurveLpGetBalanceVaultSaga(
  { payload: { token, isOld } }: ReturnType<typeof stakeCurveLpGetBalanceVaultAction>,
) {
  try {
    const address: string = yield selectAddress();
    const contract = getContract(
      stakeCurveLpVaultTokensAbi[token],
      isOld ? stakeCurveLpVaultOldTokensAddress[token] : stakeCurveLpVaultTokensAddress[token],
    );

    const balance: string = yield contract
      .methods
      .balanceOf(address)
      .call();

    const funSave = isOld
      ? stakeCurveLpSetBalanceVaultOldAction
      : stakeCurveLpSetBalanceVaultAction;

    yield put(funSave({
      token,
      balance: web3.utils.fromWei(balance),
    }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
  }
}

export function* getBalanceVaultSaga() {
  yield takeEvery(
    StakeCurveLpActionTypes.GET_BALANCE_VAULT,
    stakeCurveLpGetBalanceVaultSaga,
  );
}

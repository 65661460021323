import { fork } from 'redux-saga/effects';
import { registerWalletSaga } from './registerWallet';
import { getReferralCodeSaga } from './getReferralCode';
import { getDateRegisteredSaga } from './getDateRegister';
import { incReferralHitSaga } from './incReferralHit';

export default [
  fork(registerWalletSaga),
  fork(getReferralCodeSaga),
  fork(getDateRegisteredSaga),
  fork(incReferralHitSaga),
];

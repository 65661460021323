import { put, takeEvery } from 'redux-saga/effects';
import { getContract, RequestStatus, setNotification } from '@workstream/shared';
import { claimClaimAction, claimGetStakeAction, claimSetClaimStatusAction } from 'store/claim/actions';
import { ClaimActionTypes } from 'store/claim/actionTypes';
import { claimTokensAbi, claimTokensAddress } from 'store/claim/constants';
import { selectAddress } from 'store/utils';

function* claimClaimSaga(
  { payload: { token, claimUnderlying } }: ReturnType<typeof claimClaimAction>,
) {
  try {
    yield put(claimSetClaimStatusAction({ [token]: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contract = getContract(claimTokensAbi[token], claimTokensAddress[token]);

    yield contract
      .methods
      .getReward(claimUnderlying)
      .send({
        from: address,
      });

    yield put(claimGetStakeAction(token));
    yield put(claimSetClaimStatusAction({ [token]: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(claimSetClaimStatusAction({ [token]: RequestStatus.ERROR }));
  }
}

export function* claimSaga() {
  yield takeEvery(
    ClaimActionTypes.CLAIM,
    claimClaimSaga,
  );
}

import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import cx from 'classnames';
import { ClaimBlock } from 'components';
import { RequirementWalletProvider, SushiswapClaim } from 'containers';
import { Props as ClaimBlockProps } from 'components/ClaimBlock';
import {
  Button, CoinXbe, DefaultBlock, RequestStatus, Text, web3,
} from '@workstream/shared';
import { useDispatch } from 'react-redux';
import {
  xbeClaimClaimAction,
  xbeClaimGetBlockInfoAction,
  xbeClaimUnstakeAction,
} from 'store/xbeClaim/actions';
import { meSelector, useShallowSelector, xbeClaimSelector } from 'store/selectors';
import { XbeClaimActionTypes } from 'store/xbeClaim/actionTypes';
import BigNumber from 'bignumber.js';
import { PLEASE_NOTE, WITHDRAW_NOTE } from './constants';
import { ModalUnstakePenalty } from './ModalUnstakePenalty';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  withContainer?: boolean,
  isShowStakedSushi?: boolean,
};

const fromStringToFixed = (value: string) => Number(web3.utils.fromWei(value)).toFixed(4);

const StakedXBE: FC<Props> = ({
  className,
  withContainer,
  isShowStakedSushi = true,
}) => {
  const { votingStakingRewards: votingStakingRewardsBalance } = useShallowSelector(meSelector.getProp('balances'));
  const xbeClaimStatus = useShallowSelector(xbeClaimSelector.getStatus(XbeClaimActionTypes.CLAIM));
  const xbeUnstakeStatus =
    useShallowSelector(xbeClaimSelector.getStatus(XbeClaimActionTypes.UNSTAKE));
  const {
    amount,
    unlockTime,
    xbeAvailableToUnstake,
    toClaimWithoutPenalty,
    earned,
    apr,
    aprStart,
    aprCur,
  } = useShallowSelector(xbeClaimSelector.getState);

  const values = useMemo<ClaimBlockProps['valuesList']>(() => {
    const arr: ClaimBlockProps['valuesList'] = [
      {
        value: '',
        label: '',
        id: '0',
      },
      {
        value: fromStringToFixed(amount),
        label: 'XBE in unbonding process',
      },
      {
        value: fromStringToFixed(xbeAvailableToUnstake),
        label: 'XBE Available to unstake without penalty',
      },
    ];

    if (toClaimWithoutPenalty) {
      arr.unshift({
        value: toClaimWithoutPenalty,
        label: 'to claim without penalty',
      });
    } else {
      arr.unshift({
        value: '',
        label: '',
        id: '1',
      });
    }

    return arr;
  }, [toClaimWithoutPenalty, amount, xbeAvailableToUnstake]);

  const [isModalUnstakeOpen, setIsModalUnstakeOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(xbeClaimGetBlockInfoAction());
  }, [dispatch]);

  const onClaimClick = () => {
    dispatch(xbeClaimClaimAction());
  };

  const onUnstakeClick = () => {
    dispatch(xbeClaimUnstakeAction());
  };

  const onProceedClick = () => {
    onUnstakeClick();
    setIsModalUnstakeOpen(false);
  };

  const isUnstakeDisabled = useMemo(() => [
    xbeUnstakeStatus === RequestStatus.REQUEST,
    xbeAvailableToUnstake === '0',
    new BigNumber(unlockTime).isGreaterThan(Date.now()),
  ].includes(true), [unlockTime, xbeUnstakeStatus, xbeAvailableToUnstake]);

  const isUnstakePenaltyDisabled = useMemo(() => [
    xbeUnstakeStatus === RequestStatus.REQUEST,
    amount === '0',
    new BigNumber(unlockTime).isLessThan(Date.now()),
  ].includes(true), [unlockTime, xbeUnstakeStatus, amount]);

  return (
    <RequirementWalletProvider>
      <ModalUnstakePenalty
        isOpen={isModalUnstakeOpen}
        onClose={() => setIsModalUnstakeOpen(false)}
        onProceedClick={onProceedClick}
      />
      <ClaimBlock
        icon={CoinXbe}
        className={cx(styles.container, className)}
        title="XBE rewards"
        earned={`$${earned}`}
        onClaim={onClaimClick}
        averageAPR={votingStakingRewardsBalance === '0' ? `${aprStart}-${apr}%` : `${aprCur}%`}
        valuesList={values}
        withContainer={withContainer}
        buttonClaimDisabled={xbeClaimStatus === RequestStatus.REQUEST}
        buttonClaimText={xbeClaimStatus === RequestStatus.REQUEST ? 'Processing...' : 'Claim Staking Rewards'}
      >
        { isShowStakedSushi && (
          <>
            <Text
              className={styles.title}
              color="extra"
              bold
            >
              STAKED SUSHI LP
            </Text>
            <SushiswapClaim
              className={styles.sushiswapClaim}
              buttonClaimClass={styles.buttonSushiswapClaimClass}
              claimButtonText="Staked Sushi LP"
            />
          </>
        )}
        <Text
          className={styles.title}
          color="extra"
          bold
        >
          EARLY WITHDRAWAL PENALTY
        </Text>
        <div className={styles.wrapper}>
          <div className={styles.leftWrapper}>
            <DefaultBlock
              className={styles.noteWrapper}
              withContainer={false}
              theme="grey"
              tag="div"
            >
              <Text
                className={styles.note}
                size="tiny"
                color="secondary"
              >
                {PLEASE_NOTE}
              </Text>
            </DefaultBlock>
            <DefaultBlock
              className={cx(styles.noteWrapper, styles.noteWithdraw)}
              withContainer={false}
              theme="grey"
              tag="div"
            >
              <Text
                className={styles.note}
                size="tiny"
                color="secondary"
              >
                {WITHDRAW_NOTE}
              </Text>
            </DefaultBlock>
          </div>
          <div className={styles.rightWrapper}>
            <Button
              className={cx(styles.button, styles.buttonClaim)}
              size="mini"
              isOutline
              disabled={isUnstakeDisabled}
              onClick={onUnstakeClick}
            >
              Unstake
            </Button>
            <Button
              className={cx(styles.button, styles.buttonClaim50)}
              size="mini"
              isOutline
              disabled={isUnstakePenaltyDisabled}
              onClick={() => setIsModalUnstakeOpen(true)}
            >
              Unstake at 50% penalty
            </Button>
          </div>
        </div>
      </ClaimBlock>
    </RequirementWalletProvider>
  );
};

export default StakedXBE;

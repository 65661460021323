export enum ReferralsMeActionTypes {
  SET_STATE = 'REFERRALS_ME.SET_STATE',

  SET_STATUS = 'REFERRALS_ME.SET_STATUS',

  REGISTER_WALLET = 'REFERRALS_ME.REGISTER_WALLET',

  GET_REFERRAL_CODE = 'REFERRALS_ME.GET_REFERRAL_CODE',

  GET_DATE_REGISTERED = 'REFERRALS_ME.GET_DATE_REGISTERED',

  INC_REFERRAL_HIT = 'REFERRALS_ME.INC_REFERRAL_HIT',
}

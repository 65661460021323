import { createElement, } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
const Text = ({ tag = 'p', children, className, style = {}, size = 'normal', color = 'primary', align = 'left', underline = false, bold = false, uppercase = false, dangerouslySetInnerHTML, }) => (createElement(tag, {
    style,
    className: cx(styles.text, styles[size], styles[color], styles[align], {
        [styles.bold]: bold,
        [styles.underline]: underline,
        [styles.uppercase]: uppercase,
    }, className),
    dangerouslySetInnerHTML,
}, dangerouslySetInnerHTML ? null : children));
export default Text;

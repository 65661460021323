import { put, select, takeEvery } from 'redux-saga/effects';
import {
  getContract, MAX_UINT_256, setNotification, web3, WriteContractStatus,
} from '@workstream/shared';
import { SushiSwapLPStakingActionTypes } from 'store/sushiSwapLPStaking/actionTypes';
import {
  sushiSwapLPStakingApproveAction,
  sushiSwapLPStakingGetAllowanceAction,
  sushiSwapLPStakingSetStateAction,
} from 'store/sushiSwapLPStaking/actions';
import { pairEthXbeAbi } from 'assets/abi';
import {
  SUSHI_VAULT_CONTRACT_ADDRESS,
  ETH_XBE_PAIR_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { web3Selector } from 'store/selectors';

function* sushiSwapLPStakingApproveSaga(
  { payload: value }: ReturnType<typeof sushiSwapLPStakingApproveAction>,
) {
  try {
    yield put(sushiSwapLPStakingSetStateAction({ submitStatus: WriteContractStatus.APPROVING }));
    const address: string = yield select(web3Selector.getProp('address'));
    const approveCount = Number(value) > 0 ? MAX_UINT_256.toFixed() : web3.utils.toWei('0');
    const contract = getContract(pairEthXbeAbi, ETH_XBE_PAIR_CONTRACT_ADDRESS);

    yield contract
      .methods
      .approve(SUSHI_VAULT_CONTRACT_ADDRESS, approveCount)
      .send({
        from: address,
      });

    yield put(sushiSwapLPStakingGetAllowanceAction());
    yield put(sushiSwapLPStakingSetStateAction({ submitStatus: WriteContractStatus.APPROVED }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(sushiSwapLPStakingSetStateAction({
      submitStatus: WriteContractStatus.APPROVED_ERROR,
    }));
  }
}

export function* approveSaga() {
  yield takeEvery(
    SushiSwapLPStakingActionTypes.APPROVE,
    sushiSwapLPStakingApproveSaga,
  );
}

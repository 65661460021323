import React, { FC, PropsWithChildren } from 'react';
import { IconArrowDropBlack, SvgIcon, Text } from '@workstream/shared';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string
  icon?: string,
  title: string,
};

const Collapse: FC<PropsWithChildren<Props>> = ({
  className,
  icon,
  title,
  children,
}) => (
  <details className={cx(styles.collapse, className)}>
    <summary className={styles.summary}>
      {icon && <SvgIcon icon={icon} className={styles.icon} />}
      <Text
        className={styles.summaryTitle}
        size="big"
        color="primary"
        bold
      >
        {title}
      </Text>
      <SvgIcon
        className={styles.dropArrow}
        icon={IconArrowDropBlack}
      />
    </summary>
    {children}
  </details>
);

export default Collapse;

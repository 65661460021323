import React, {
  ChangeEvent, FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { CurveLpToken, stakeCurveLpVaultTokensAddress } from 'store/stakeCurveLp/constants';
import { RewardsContractsAddresses } from 'store/stakeCurveLp/types';
import { stakeCurveLpSelector, useShallowSelector } from 'store/selectors';
import { useDispatch } from 'react-redux';
import {
  stakeCurveLpApproveAction,
  stakeCurveLpDepositAction,
  stakeCurveLpGetAllowanceAction,
  stakeCurveLpGetBalanceAction,
  stakeCurveLpGetBalanceVaultAction,
  stakeCurveLpGetLpTokenAddressAction,
  stakeCurveLpGetRewardsContractsAddressesAction,
  stakeCurveLpWithdrawAction,
} from 'store/stakeCurveLp/actions';
import { RequestStatus, WriteContractStatus } from '@workstream/shared';
import BigNumber from 'bignumber.js';
import { Props as InfoListProps } from 'components/InfoList';
import {
  STAKE_ICON_INPUT,
  STAKE_TOKEN_DESCRIPTION,
  STAKE_TOKEN_NAME,
  STAKE_TOKEN_NOTE,
  UNSTAKE_ICON_INPUT,
  UNSTAKE_TOKEN_DESCRIPTION,
  UNSTAKE_TOKEN_NAME,
} from './constants';
import StakeWrapper from '../StakeWrapper';

type Props = {
  token: CurveLpToken,
};

export const CurveStakeWrapper: FC<Props> = ({ token }) => {
  const balance = useShallowSelector(stakeCurveLpSelector.getBalance(token));
  const balanceVault = useShallowSelector(stakeCurveLpSelector.getBalanceVault(token));
  const allowance = useShallowSelector(stakeCurveLpSelector.getAllowance(token));
  const listRewardsContractsAddresses =
    useShallowSelector(stakeCurveLpSelector.getContractsRewardsList(token));
  const lpTokenAddress =
    useShallowSelector(stakeCurveLpSelector.getLpTokenAddress(token));
  const submitStatus = useShallowSelector(stakeCurveLpSelector.getSubmitStatus(token));
  const withdrawStatus = useShallowSelector(stakeCurveLpSelector.getWithdrawStatus(token));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(stakeCurveLpGetAllowanceAction(token));
    dispatch(stakeCurveLpGetBalanceAction(token));
    dispatch(stakeCurveLpGetBalanceVaultAction(token));
    dispatch(stakeCurveLpGetRewardsContractsAddressesAction(token));
    dispatch(stakeCurveLpGetLpTokenAddressAction(token));
  }, [dispatch, token]);

  // Stake
  const [stakeInputValue, setStakeInputValue] = useState('');

  const stakeOnMaxClick = useCallback(() => setStakeInputValue(balance), [balance]);

  const handleStakeInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setStakeInputValue(event.target.value);
  }, []);

  const stakeApproveButtonDisabled = useMemo(() => [
    stakeInputValue === '',
    new BigNumber(allowance).isGreaterThan(stakeInputValue) && stakeInputValue !== '0',
    submitStatus === WriteContractStatus.APPROVING,
    submitStatus === WriteContractStatus.WRITING,
    // Disabled for IRON_BANK EURT ST_ETH
    token !== CurveLpToken.FRAX,
  ].includes(true), [
    stakeInputValue, allowance, submitStatus,
    token,
  ]);

  const stakeDepositButtonDisabled = useMemo(() => {
    const valueBN = new BigNumber(stakeInputValue);

    return [
      stakeInputValue === '' || stakeInputValue === '0',
      valueBN.isGreaterThan(allowance),
      valueBN.isGreaterThan(balance),
      submitStatus === WriteContractStatus.APPROVING,
      submitStatus === WriteContractStatus.WRITING,
      // Disabled for IRON_BANK EURT ST_ETH FRAX
      token !== CurveLpToken.FRAX,
    ].includes(true);
  }, [
    stakeInputValue, allowance, balance, submitStatus,
    token,
  ]);

  const stakeApproveButtonOnClick = useCallback(() => {
    dispatch(stakeCurveLpApproveAction({ token, value: stakeInputValue }));
  }, [dispatch, token, stakeInputValue]);

  const stakeDepositButtonOnClick = useCallback(() => {
    dispatch(stakeCurveLpDepositAction({ token, value: stakeInputValue }));
  }, [dispatch, token, stakeInputValue]);

  // Unstake
  const [unstakeInputValue, setUnstakeInputValue] = useState('');

  const unstakeInputOnMaxClick = useCallback(
    () => setUnstakeInputValue(balanceVault),
    [balanceVault],
  );

  const unstakeInputOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUnstakeInputValue(event.target.value);
  }, []);

  const unstakeButtonOnClick = useCallback(() => {
    dispatch(stakeCurveLpWithdrawAction({ token, value: unstakeInputValue }));
  }, [dispatch, token, unstakeInputValue]);

  const unstakeButtonDisabled = useMemo(() => [
    unstakeInputValue === '' || unstakeInputValue === '0',
    new BigNumber(unstakeInputValue).isGreaterThan(balanceVault),
    withdrawStatus === RequestStatus.REQUEST,
  ].includes(true), [unstakeInputValue, withdrawStatus, balanceVault]);

  // Info
  const infoList = useMemo<InfoListProps['list']>(() => {
    const list: InfoListProps['list'] = [
      {
        label: 'LP token Address',
        value: lpTokenAddress,
      },
      {
        label: 'Deposit contract address',
        value: stakeCurveLpVaultTokensAddress[token],
      },
    ];

    listRewardsContractsAddresses.forEach((item: RewardsContractsAddresses) => {
      list.push({ label: `Rewards contract address (${item.symbol})`, value: item.address });
    });
    return list;
  }, [lpTokenAddress, token, listRewardsContractsAddresses]);
  return (
    <StakeWrapper
      iconInput={STAKE_ICON_INPUT[token]}
      token={STAKE_TOKEN_NAME[token]}
      description={STAKE_TOKEN_DESCRIPTION[token]}
      note={STAKE_TOKEN_NOTE[token]}
      stakeAvailable={balance}
      stakeOnMaxClick={stakeOnMaxClick}
      stakeOnChange={handleStakeInput}
      stakeValue={stakeInputValue}
      stakeApproveButtonOnClick={stakeApproveButtonOnClick}
      stakeApproveButtonDisabled={stakeApproveButtonDisabled}
      stakeApproveButtonText={submitStatus === WriteContractStatus.APPROVING ? 'Approving...' : undefined}
      stakeDepositButtonOnClick={stakeDepositButtonOnClick}
      stakeDepositButtonDisabled={stakeDepositButtonDisabled}
      stakeDepositButtonText={submitStatus === WriteContractStatus.WRITING ? 'Deposit...' : undefined}
      unstakeDescription={UNSTAKE_TOKEN_DESCRIPTION[token]}
      unstakeIconInput={UNSTAKE_ICON_INPUT[token]}
      unstakeToken={UNSTAKE_TOKEN_NAME[token]}
      unstakeTokenAvailable={balanceVault}
      unstakeInputOnMaxClick={unstakeInputOnMaxClick}
      unstakeInputValue={unstakeInputValue}
      unstakeInputOnChange={unstakeInputOnChange}
      unstakeButtonOnClick={unstakeButtonOnClick}
      unstakeButtonDisabled={unstakeButtonDisabled}
      unstakeButtonText={withdrawStatus === RequestStatus.REQUEST ? 'Withdraw...' : undefined}
      infoList={infoList}
    />
  );
};
